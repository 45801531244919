<template>
	<div
		:class="{
			'sc-tab': true,
			[`sc-tab_${size}`]: true,
			'selected': selected,
			'disabled': disabled,
		}"
		@click="$emit('click', $event)"
	>
		<!-- @slot Use for any text -->
		<slot name="default"/>

		<ScBadge
			v-if="view === 'badge'"
			:notification="!visited && notification"
			color="grey"
			class="sc-tab__badge"
		>
			<!-- @slot Use for badge content -->
			<slot name="badge"/>
		</ScBadge>
		<div
			v-if="view === 'counter' && !visited"
			class="sc-tab__counter"
		>
			{{ count }}
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType, ref, watch } from 'vue';
	import ScBadge from '../badge/badge.vue';

	/** The ScTab component is used to that organizes the content (makes it more compact) */
	export default defineComponent({
		name: 'ScTab',
		components: { ScBadge },
		props: {
			/** Set size */
			size: {
				type: String as PropType<'28' | '36' | '44'>,
				default: '28',
				validator: (value: string): boolean => ['28', '36', '44'].includes(value),
			},
			/** Set selected state */
			selected: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set view */
			view: {
				type: String as PropType<'badge' | 'counter' | 'simple'>,
				default: 'simple',
				validator: (value: string): boolean => ['badge', 'counter', 'simple'].includes(value),
			},
			/** Set notification state */
			notification: {
				type: Boolean,
				default: false,
			},
			/** Set count */
			count: {
				type: Number,
				default: 0,
			},
		},
		setup(props) {
			const visited = ref(false);

			watch(() => props.selected, (value) => {
				if (!value) {
					visited.value = true;
				}
			});

			return {
				visited,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tab {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		white-space: nowrap;
		cursor: pointer;
		position: relative;
		color: @mulberry-purple;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
		transition: color 100ms;

		.sc-tab__badge {
			background: @mulberry-purple-20;
			color: @mulberry-purple;
		}

		.sc-tab__counter {
			display: flex;
			align-items: center;
			height: 16px;
			justify-content: center;
			background: @lightish-red-70;
			color: @white;
			border-radius: 8px;
			font-weight: 700;
			font-size: 10px;
			padding: 0 5px;
		}

		&_28 {
			gap: 6px;
			height: 28px;
		}

		&_36 {
			gap: 7px;
			height: 36px;
		}

		&_44 {
			gap: 8px;
			height: 44px;
		}

		&::after {
			content: "";
			width: 100%;
			height: 3px;
			bottom: -2px;
			left: 0;
			background: transparent;
			border-radius: 2px;
			position: absolute;
			transform: scaleX(0);
			transition: background 200ms, transform 200ms;
		}

		&:hover {
			color: @mulberry-purple-80;
		}

		&.selected {
			color: @mulberry-purple-90;

			&::after {
				background: @mulberry-purple-90;
				transform: scaleX(1);
			}

			.sc-tab__badge {
				background: @mulberry-purple-30;
			}

			&:hover {
				color: @mulberry-purple-70;

				&::after {
					background: @mulberry-purple-70;
				}
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;
			color: @mulberry-purple-35;

			&.selected::after {
				background: @mulberry-purple-40;
			}

			.sc-tab__badge {
				background: @mulberry-purple-20;
				color: @mulberry-purple-40;
			}
		}
	}
</style>
