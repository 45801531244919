<template>
	<ScPopup
		class="sc-popup-onboarding"
		@cancel="$emit('cancel')"
		:closable="closable"
	>
		<div class="sc-popup-onboarding__content">
			<div class="sc-popup-onboarding__content-text">
				<ScText
					v-if="subtitle"
					transform="uppercase"
					size="12"
					weight="semibold"
					color="mulberry-purple-40"
					class="sc-popup-onboarding__content-subtitle"
				>
					{{ subtitle }}
				</ScText>
				<ScText
					v-if="activeStep.title"
					size="24"
					weight="bold"
					color="mulberry-purple-90"
					class="sc-popup-onboarding__content-title"
				>
					{{ activeStep.title }}
				</ScText>
				<ScText v-if="activeStep.description">
					{{ activeStep.description }}
				</ScText>
			</div>
			<div class="sc-popup-onboarding__content-action">
				<div class="sc-popup-onboarding__content-action_buttons">
					<ScButton
						size="36"
						view="simple"
						:disabled="activeStepNumber === 1"
						@click="previousStep "
					>
						{{ translate('Back') }}
					</ScButton>
					<ScButton
						size="36"
						view="cta-black"
						@click="nextStep"
					>
						{{ isLastStep ? translate('GetStarted') : translate('Next') }}
					</ScButton>
				</div>
				<div class="sc-popup-onboarding__content-action_counter">
					<ScText color="mulberry-purple-40">
						{{ `${activeStepNumber} ${translate('Counter')} ${steps.length}` }}
					</ScText>
				</div>
			</div>
		</div>
		<div
			:class="{
					'sc-popup-onboarding__image': true,
					[`sc-popup-onboarding__image-${imageHeight}`]: true,
				}"
		>
			<img
				:srcset="
					`${activeStep.imageSource[0]} 1x,
					${activeStep.imageSource[1]} 2x,
					${activeStep.imageSource[2]} 3x`"
					:src="activeStep.imageSource[0]" alt=""
			>
		</div>
	</ScPopup>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType, ref } from 'vue';
	import { ScButton } from '../button';
	import ScText from '../text/text.vue';
	import { translate } from '../../i18n';
	import ScPopup from './popup.vue';

	interface PopupOnboardingSteps {
		id: number,
		title: string;
		description: string;
		imageSource: Array<string>;
	}

	/** The ScPopupOnboarding component is an interface element that is displayed on top of a web page in a browser **/
	export default defineComponent({
		name: 'ScPopupOnboarding ',
		components: { ScPopup, ScText, ScButton },
		emits: [
			/** Cancel event */
			'cancel',
			/** Confirm event */
			'confirm',
		],
		props: {
			/** Set closable */
			closable: {
				type: Boolean,
				default: true,
			},
			/** Set subtitle */
			subtitle: {
				type: String,
				default: null,
			},
			/** Steps */
			steps: {
				type: Array as PropType<Array<PopupOnboardingSteps>>,
				required: true,
				default: () => ([] as Array<PopupOnboardingSteps>),
			},
			/** Set image height */
			imageHeight: {
				type: String as PropType<'popup' | 'content'>,
				default: 'popup',
				validator: (value: string) => ['popup', 'content'].includes(value),
			},
		},
		setup(props, { emit }) {
			const activeStepNumber = ref(1);

			const activeStep = computed(() => props.steps[activeStepNumber.value - 1]);
			const isLastStep = computed(() => props.steps.length === activeStepNumber.value);

			const previousStep = () => {
				activeStepNumber.value--;
			};

			const nextStep = () => {
				if (isLastStep.value) {
					emit('confirm', true);
				} else {
					activeStepNumber.value++;
				}
			};

			return {
				activeStep,
				activeStepNumber,
				isLastStep,
				translate,
				previousStep,
				nextStep,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";

	.sc-popup-onboarding {
		min-width: 300px;
		max-width: 760px;
		flex-direction: row;
		padding: 0;
		overflow: hidden;

		.screen-lt-1024({
			width: 400px;
			flex-wrap: wrap;
			gap: 20px;
			flex-direction: column-reverse;
		});

		.screen-lt-480({
			width: 340px;
		});

		&__content {
			display: flex;
			flex-direction: column;
			padding: 60px 20px 60px 60px;
			justify-content: space-between;

			.screen-lt-1024({
				padding: 0 50px 60px 50px;
			});

			.screen-lt-480({
				padding: 0 20px 30px 20px;
			});

			&-text {
				display: flex;
				flex-direction: column;
			}

			&-subtitle {
				margin-bottom: 20px;
			}

			&-title {
				margin-bottom: 12px;
			}

			&-action {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 40px;

				.screen-lt-1024({
					flex-direction: row-reverse;
				});

				&_buttons {
					display: flex;
					gap: 8px;
				}
			}
		}

		&__image {
			flex: 1 0 auto;

			.screen-lt-1024({
				height: 265px;

			});

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: left top;
			}

			&-content {
				padding: 60px 0;

				.screen-lt-1024({
					padding: 65px 20px 0 0;
				});
			}

			&-popup {
				.screen-lt-1024({
					padding: 65px 20px 0 0;
				});
			}
		}
	}
</style>
