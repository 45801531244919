<template>
	<ScDropdown
		:disabled="disabled"
		:nested="true"
		class="sc-dropdown-nested"
		@open="$emit('open')"
		@close="$emit('close')"
		@cancel="cancel"
	>
		<template #activator="{ isOpened, toggleDropdown }">
			<ScDropdownLine
				:activated="isOpened"
				:clickabled="true"
				:disabled="disabled"
				class="sc-dropdown-nested__line"
				@click.stop="toggleDropdown()"
			>
				<div class="sc-dropdown-nested__text">
					<slot name="activator"/>
				</div>
				<ScIcon
					name="chevron-right"
					size="16"
					class="sc-dropdown-nested__chevron"
				/>
			</ScDropdownLine>
		</template>
		<template #content="{ isOpened, closeDropdown }">
			<slot
				name="default"
				v-bind="{ isOpened, closeDropdown }"
			/>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { defineComponent, inject } from 'vue';
	import { DropdownApi } from './dropdown-api';
	import ScIcon from '../icon/icon.vue';
	import ScDropdown from './dropdown.vue';
	import ScDropdownLine from './dropdown-line.vue';

	/** The ScDropdownNested component used as a nested ScDropdown */
	export default defineComponent({
		name: 'ScDropdownNested',
		components: {
			ScIcon,
			ScDropdown,
			ScDropdownLine,
		},
		props: {
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		setup(_, { emit }) {
			const dropdown = inject<DropdownApi>('dropdown');

			const cancel = () => {
				dropdown.closeDropdown();
				emit('close');
			};

			return {
				cancel,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-dropdown-nested {
		display: flex;
		flex: 1;

		&__line {
			flex: 1;
			justify-content: space-between;
			gap: 6px;
		}

		&__text {
			display: flex;
			flex: 1;
		}

		&__chevron {
			flex: none;
		}
	}
</style>
