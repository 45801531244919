<template>
	<div
		class="sc-sideline"
		:class="{
			active,
			passed,
			disabled,
			numeric: number != null,
			[`${direction}-direction`]: true
		}"
		@click="$emit('click', $event)"
	>
		<div class="sc-sideline__content">
			<span
				v-if="number != null && direction === 'left'"
				class="sc-sideline__number"
			>
				{{ number }}
			</span>
			<ScText
				class="sc-sideline__title"
				weight="semibold"
			>
				<!-- @slot Use for any text -->
				<slot name="default"/>
			</ScText>
		</div>
		<div
			class="sc-sideline__description"
			v-if="$slots.description != null"
		>
			<!-- @slot Use for any text or avatars -->
			<slot name="description"/>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import ScText from '../text/text.vue';

	/** The ScSideLine component used to visually display upcoming or completed steps */
	export default defineComponent({
		name: 'ScSideLine',
		components: { ScText },
		props: {
			/** Set the number of step */
			number: {
				type: [Number, String],
				default: null,
			},
			/** Set active state */
			active: {
				type: Boolean,
				default: false,
			},
			/** Set passed state */
			passed: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set direction */
			direction: {
				type: String as PropType<'left' | 'right'>,
				default: 'left',
				validator: (value: string) => ['left', 'right'].includes(value),
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-sideline {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		cursor: pointer;

		&__content {
			display: flex;
			align-items: center;
			gap: 8px;
			box-sizing: border-box;
			--text-color: @mulberry-purple-new;
		}

		&__number {
			position: relative;
			background: @mulberry-purple-30;
			border-radius: 50%;
			color: @mulberry-purple-new;
			display: flex;
			flex: none;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 600;
			height: 18px;
			width: 18px;

			&::before {
				content: "";
				display: none;
				position: absolute;
				width: 4.5px;
				height: 8.5px;
				box-sizing: border-box;
				border-right: 2px solid @white;
				border-bottom: 2px solid @white;
				transform: translate3d(0, -1px, 0) scale(1) rotate(45deg);
				transition: all 150ms ease;
			}
		}

		&__title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&__description {
			font-size: 12px;
			color: @mulberry-purple-new;
			padding-left: 26px;
			--icon-color: @mulberry-purple-new;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:hover {
			.sc-sideline__content {
				--text-color: @fuchsia-blue-60;
			}
		}

		&.left-direction {
			.sc-sideline__content {
				padding-right: 4px;
				border-right: 3px solid transparent;
			}

			&.active {
				.sc-sideline__content {
					border-right-color: @fuchsia-blue;
				}
			}
		}

		&.right-direction {
			.sc-sideline__content {
				padding-left: 20px;
				border-left: 3px solid transparent;
			}

			.sc-sideline__description {
				padding-left: 46px;
			}

			&.active {
				.sc-sideline__content {
					border-left-color: @fuchsia-blue;
				}
			}
		}

		&.numeric {
			cursor: default;
			pointer-events: none;

			.sc-sideline__content {
				--text-color: @mulberry-purple-40;
			}

			:hover {
				.sc-sideline__number {
					background: @fuchsia-blue-60;
					color: @white;
				}
			}
		}

		&.active {
			color: @fuchsia-blue;
			cursor: default;
			pointer-events: none;

			.sc-sideline__number {
				background: @fuchsia-blue;
				color: @fuchsia-blue-0;
			}

			.sc-sideline__content {
				--text-color: @fuchsia-blue;
			}
		}

		&.passed {
			cursor: pointer;
			pointer-events: auto;
			border-right: none;
			--text-color: @mulberry-purple-new;

			.sc-sideline__number {
				background: @mulberry-purple-new;

				&::before {
					display: block;
				}
			}

			.sc-sideline__content {
				--text-color: @mulberry-purple;
			}

			&:hover {
				.sc-sideline__number {
					background: @fuchsia-blue-60;
					color: @fuchsia-blue-60;
				}

				.sc-sideline__content {
					--text-color: @fuchsia-blue-60;
				}
			}
		}

		&.disabled {
			pointer-events: none;
			--text-color: @mulberry-purple-35;

			.sc-sideline__number {
				background: @mulberry-purple-35;
				color: @white;
			}

			.sc-sideline__description {
				color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-30;
				--text-color: @mulberry-purple-35;
			}

			&.passed {
				.sc-sideline__number {
					color: @mulberry-purple-35;
				}
			}
		}
	}
</style>
