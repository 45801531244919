
<ScText
	:weight="weight"
	:stylization="stylization"
	:color="color"
	size="16"
	tag="h3"
>
	<!-- @slot Use for any text -->
	<slot/>
</ScText>
