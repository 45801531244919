<template>
	<button
		class="sc-button-logo"
		type="button"
		@click="$emit('click', $event)"
	>
		<ScIcon name="sc"/>
	</button>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import { ScIcon } from '../icon';

	/** Button with smartcat logo */
	export default defineComponent({
		name: 'ScButtonLogo',
		components: { ScIcon },
		emits: [
			/** Click event */
			'click',
		],
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button-logo {
		height: 44px;
		width: 44px;
		margin: 0;
		padding: 0;
		outline: none;
		background: transparent;
		border: none;
		border-radius: 6px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		--icon-color: @mulberry-purple-90;

		&:hover {
			--icon-color: @mulberry-purple-70;
		}

		&:active {
			--icon-color: @mulberry-purple-100;
		}
	}
</style>
