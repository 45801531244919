
<ScDropdownLine
	:class="classes"
	:clickabled="true"
	:closing="true"
	:activated="activated"
	:disabled="disabled"
	@click="$emit('click', $event)"
>
	<ScText :color="color">
		<!-- @slot Used for any content -->
		<slot/>
	</ScText>
</ScDropdownLine>
