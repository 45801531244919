<template>
	<div :class="{
		'sc-accordion': true,
		[`sc-accordion_opened`]: isOpened,
		[`sc-accordion_${size}`]: true,
	}">
		<div
			class="sc-accordion__activator"
			@click="toggleAccordion"
		>
			<ScIcon
				class="sc-accordion__activator-icon"
				name="chevron-solid-right"
				size="16"
			/>
			<ScText
				class="sc-accordion__activator-text"
				:size="size"
				:tag="size === '16' ? 'h3' : 'span'"
				:weight="size === '16' ? 'bold' : 'semibold'"
			>
				{{ caption }}
			</ScText>
			<!-- @slot Use for any actions (button) -->
			<slot name="actions"/>
		</div>
		<div
			v-if="slots.static"
			class="sc-accordion__content"
		>
			<slot
				v-bind="{ isOpened, toggleAccordion }"
				name="static"
			/>
		</div>
		<ScTransitionSlide>
			<div
				v-if="isOpened"
				class="sc-accordion__content"
			>
				<!-- @slot Use for any content -->
				<slot
					v-bind="{ isOpened, toggleAccordion }"
					name="default"
				/>
			</div>
		</ScTransitionSlide>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType, ref, useSlots } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import ScTransitionSlide from '../transition/slide.vue';
	import ScText from '../text/text.vue';
	import ScH3 from '../text/h3.vue';

	/**	The ScAccordion component is used to display a list with dropdown content. **/
	export default defineComponent({
		name: 'ScAccordion',
		components: {
			ScH3,
			ScText,
			ScIcon,
			ScTransitionSlide,
		},
		emits: [
			'open',
			'close',
		],
		props: {
			/** Set initial open state */
			isOpened: {
				type: Boolean,
				default: false,
			},
			/** Set activator caption */
			caption: {
				type: String,
				required: true,
				default: null,
			},
			/** Set activator text size */
			size: {
				type: String as PropType<'12' | '16'>,
				default: '12',
			},
		},
		setup(props, { emit }) {
			const slots = useSlots();

			const isOpened = ref(props.isOpened);

			const toggleAccordion = () => {
				emit(isOpened.value ? 'close' : 'open');
				isOpened.value = !isOpened.value;
			};

			return {
				slots,
				isOpened,
				toggleAccordion,
			};
		},
	});
</script>
<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-accordion {
		display: block;

		&__activator {
			display: flex;
			align-items: center;
			cursor: pointer;
			width: 100%;
			min-height: 28px;

			&-icon {
				flex: none;
				transition: fill 200ms, transform 200ms;
			}

			&-text {
				flex: 1;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				min-width: 0;
			}
		}

		&_opened {
			.sc-accordion__activator-icon {
				transform: rotate(90deg);
			}

			&.sc-accordion_12 .sc-accordion__activator {
				--text-color: @mulberry-purple;
			}
		}

		&_12 .sc-accordion__activator {
			margin-bottom: 4px;
			padding-left: 9px;
			gap: 11px;
			--icon-color: @mulberry-purple-40;
			--text-color: @mulberry-purple-new;

			&:hover {
				.sc-accordion__activator-icon {
					--icon-color: @mulberry-purple-45;
				}
			}
		}

		&_16 {
			.sc-accordion__activator {
				margin-bottom: 12px;
				gap: 6px;
				--icon-color: @mulberry-purple-45;
				--text-color: @mulberry-purple-90;

				&:hover,
				&:active {
					.sc-accordion__activator-icon {
						--icon-color: @mulberry-purple-new;
					}

					.sc-accordion__activator-text {
						--text-color: @mulberry-purple-new;
					}
				}
			}
		}
	}
</style>
