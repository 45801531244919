<template>
	<div class="sc-label">
		<ScText
			:size="size"
			:color="size === '12' ? 'mulberry-purple-new' : 'mulberry-purple-90'"
			:weight="size === '12' ? 'regular' : 'bold'"
		>
			{{ caption }}<span v-if="required" class="sc-label__required">*</span>
		</ScText>
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import ScText from '../text/text.vue';

	export default defineComponent({
		name: 'ScLabel',
		components: {
			ScText,
		},
		props: {
			caption: {
				type: String,
				required: true,
				default: null,
			},
			required: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String as PropType<'12' | '14' | '16'>,
				default: '12',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-label {
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;

		&__required {
			color: @lightish-red;
		}
	}
</style>
