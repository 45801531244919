import { ObjectDirective } from 'vue';

export default {
	mounted: (el, binding) => {
		(binding.dir as any).onResize = () => {
			Object.assign(el.style, { top: null });
			const { top } = el.getBoundingClientRect();
			Object.assign(el.style, {
				position: 'sticky',
				top: (top + window.scrollY) + 'px',
			});
		};
		(binding.dir as any).onResize();
		window.addEventListener('resize', (binding.dir as any).onResize);
	},
	beforeUnmount(_el, binding) {
		window.removeEventListener('resize', (binding.dir as any).onResize);
	},
} as ObjectDirective<HTMLElement, undefined>;
