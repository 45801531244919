<template>
	<div
		ref="badge"
		:style="{ maxWidth: maxWidth + 'px' }"
		class="sc-input-emails-option"
	>
		<input
			ref="input"
			v-if="isEditMode"
			v-model="editValue"
			class="sc-input-emails-option__badge-change"
			@blur="setNewValue()"
			@keydown.enter.space="setNewValue()"
		/>
		<ScBadge
			v-else
			class="sc-input-emails-option__badge"
			:color="isValid ? 'grey' : 'red'"
			closing
			@remove="$emit('remove', $event)"
			@click="toEditMode()"
		>
			{{ modelValue }}
		</ScBadge>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, nextTick, PropType, ref,  } from 'vue';
	import emailRuleFactory from '../../composables/use-validation/rules/email';
	import ScBadge from '../badge/badge.vue';

	const emailValidator = emailRuleFactory();

	export default defineComponent({
		name: 'ScInputEmailsOption',
		components: {
			ScBadge,
		},
		emits: [
			/** remove event */
			'remove',
			/** focus event */
			'focus',
			/** update value event */
			'update:modelValue',
		],
		props: {
			/** Option */
			modelValue: {
				type: String,
				default: null,
			},
			/** Set max width */
			maxWidth: {
				type: Number,
				default: null,
			},
			/** Invalid values */
			invalidValues: {
				type: Array as PropType<Array<string>>,
				default: null,
			},
		},

		setup(props, { emit }) {
			const badge = ref<HTMLDivElement>();
			const input = ref<HTMLInputElement>();
			const editValue = ref('');
			const isEditMode = ref(false);

			const isValid = computed(() => {
				return emailValidator(props.modelValue) && !props.invalidValues?.includes(props.modelValue);
			});

			const toEditMode = () => {
				const width = badge.value.getBoundingClientRect().width * 1.1;
				isEditMode.value = true;
				editValue.value = props.modelValue;
				nextTick(() => {
					input.value.focus();
					input.value.style.width = width + 'px';
				});
			};

			const setNewValue = () => {
				isEditMode.value = false;
				emit('update:modelValue', editValue.value?.trim());
			};

			return {
				badge,
				input,
				editValue,
				isEditMode,
				isValid,
				toEditMode,
				setNewValue,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-input-emails-option {
		display: flex;
		height: 20px;

		&__badge {
			cursor: pointer;
			transition: all 0.3s ease;

			&-change {
				background: inherit;
				border: none;
				outline: none;
			}
		}
	}
</style>
