<template>
	<ScButton
		v-if="$slots.default != null"
		:class="classes"
		v-bind="$attrs"
		@click="toggleDropdownAndEmitClick($event)"
	>
		<!-- @slot Use for any text -->
		<slot/>
	</ScButton>
	<ScButton
		v-else
		:class="classes"
		v-bind="$attrs"
		@click="toggleDropdownAndEmitClick($event)"
	>
	</ScButton>
</template>

<script lang="ts">
	import { computed, defineComponent, inject } from 'vue';
	import { DropdownApi } from './dropdown-api';
	import ScButton from '../button/button.vue';

	/** Renders html tag button which is used as the active element to show or hide the dropdown list */
	export default defineComponent({
		name: 'ScDropdownButton',
		components: { ScButton },
		emits: [
			/** Click event */
			'click'
		],
		setup(_, { emit }) {
			const dropdown = inject<DropdownApi>('dropdown');

			const classes = computed(() => ({
				'sc-dropdown-button': true,
				'sc-button_activated': dropdown.isOpened.value,
			}));

			const toggleDropdownAndEmitClick = (e: MouseEvent) => {
				dropdown.toggleDropdown();
				emit('click', e);
			};
			return { classes, toggleDropdownAndEmitClick };
		},
	});
</script>
