
<div
	ref="popup"
	:style="{ width }"
	:class="`sc-popup sc-popup_${padding}`"
>
	<ScButton
		v-if="closable"
		view="flat"
		icon="close"
		class="sc-popup_close"
		data-test-id="popup-close"
		@click="$emit('cancel')"
	/>
	<!-- @slot Use for any content -->
	<slot name="default"/>
</div>
