<template>
	<div
		:class="{
			'sc-dropdown-group': true,
			'sc-dropdown-group_bordered': !title,
		}"
		:data-group-title="title"
	>
		<ScText
			v-if="title"
			class="sc-dropdown-group__title"
			color="mulberry-purple-40"
			weight="semibold"
			size="12"
		>
			{{ title }}
		</ScText>
		<!-- @slot Use for content in dropdown -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScText from '../text/text.vue';

	/** The ScDropdownGroup component used to group the content part of the dropdown component */
	export default defineComponent({
		name: 'ScDropdownGroup',
		components: { ScText },
		props: {
			/** Title */
			title: {
				type: String,
				default: null,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-dropdown-group {
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&_bordered {
			border-top: 1px solid @mulberry-purple-20;
			padding-top: 10px;

			&:first-of-type {
				border-top: none;
				padding-top: 0;
			}
		}

		&__title {
			display: block;
			padding: 10px 12px;
			cursor: default;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: uppercase;
		}
	}
</style>
