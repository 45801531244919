
<div
	ref="avatarContainer"
	:class="`sc-avatar sc-avatar_${size} sc-avatar_${view} sc-avatar_bg${avatarBackgroundIx}`"
>
	<span class="sc-avatar__label">{{ avatarLabel }}</span>
	<span
		v-if="status !== 'none' && view === 'circle'"
		:class="`sc-avatar__status ${status}`"
	/>
</div>
