
<div
	:class="classes"
	:tabindex="disabled ? null : 0"
>
	<div
		v-if="canShowPlaceholder"
		class="sc-select-label__placeholder"
	>
		<span class="sc-select-label__placeholder-text">{{ placeholder }}</span>
	</div>
	<div
		v-else
		class="sc-select-label__content"
	>
		<slot v-if="$slots.default"/>
		<ScTooltip
			v-else
			active="overflowed"
		>
			<template #activator>
				<span class="sc-select-label__content-text">
					{{ selectedOption?.text }}
				</span>
			</template>
			<template #default>
				<span class="sc-select-label__content-text">
					{{ selectedOption?.text }}
				</span>
			</template>
		</ScTooltip>
	</div>
	<ScButton
		v-if="canClearSelectedOption"
		view="flat"
		icon="close"
		size="28"
		class="sc-select-label__clear-button"
		@click.stop="$emit('update:modelValue', null)"
		@keydown.enter.stop="$emit('update:modelValue', null)"
	/>
	<div class="sc-select-label__icon">
		<ScIcon
			name="chevron-down"
			size="16"
		/>
	</div>
</div>
