<template>
	<div class="sc-time">
		<ScSelect
			:modelValue="hour"
			:options="isHour12 ? selectOptionsHours12 : selectOptionsHours24"
			:search="false"
			class="sc-time__select"
			size="28"
			view="plate-light"
			@update:modelValue="hour = $event;setTime()"
		/>
		<span>:</span>
		<ScSelect
			:modelValue="minute"
			:options="selectOptionsMinutes"
			:search="false"
			class="sc-time__select"
			size="28"
			view="plate-light"
			@update:modelValue="minute = $event;setTime()"
		/>

		<ScSelect
			v-if="isHour12"
			:modelValue="amPm"
			:options="selectOptionsAmPm"
			:search="false"
			class="sc-time__select half-day"
			size="28"
			view="flat"
			@update:modelValue="amPm = $event;setAmPm()"
		/>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, ref, watch } from 'vue';
	import { ScSelect } from '../select';
	import { SelectOption } from '../select/option';
	import { cultureName } from '../../i18n';
	import ScText from '../text/text.vue';

	enum AmPm {
		AM = 'AM',
		PM = 'PM',
	}

	export default defineComponent({
		name: 'ScTime',
		components: { ScText, ScSelect },
		props: {
			/** Start date for range and single calendar*/
			modelValue: {
				type: Date,
				default: null,
			},
			/** Set minimal choose date*/
			minDate: {
				type: Date,
				default: null,
			},
			/** Set maximum choose date*/
			maxDate: {
				type: Date,
				default: null,
			},
		},
		setup(props, { emit }) {
			const startDate = ref(new Date(props.modelValue?.getFullYear(), props.modelValue?.getMonth(), props.modelValue?.getDate()));
			const locale = new Intl.Locale(cultureName.value);
			const hour = ref(props.modelValue ? props.modelValue.getHours() : 0);
			const minute = ref(props.modelValue ? props.modelValue.getMinutes() : 0);
			const amPm = ref<AmPm>(hour.value >= 12 ? AmPm.PM : AmPm.AM);
			const minHour = computed(() => props.minDate?.getHours());
			const maxHour = computed(() => props.maxDate?.getHours());
			const minDate = computed(() => new Date(props.minDate?.getFullYear(), props.minDate?.getMonth(), props.minDate?.getDate()));
			const maxDate = computed(() => new Date(props.maxDate?.getFullYear(), props.maxDate?.getMonth(), props.maxDate?.getDate()));
			const isMinDay = computed(() => startDate?.value.getTime() === minDate?.value.getTime());
			const isMaxDay = computed(() => startDate?.value.getTime() === maxDate?.value.getTime());
			const isMaxHour = computed(() => isMaxDay.value && hour.value >= maxHour.value);
			const isMinHour = computed(() => isMinDay.value && hour.value <= minHour.value);
			const isHour12 = computed(() => locale.baseName === 'en');

			watch(() => props.modelValue, (modelValue) => {
				if (!modelValue) {
					hour.value = 0;
					minute.value = 0;
				}
				startDate.value = new Date(modelValue?.getFullYear(), modelValue?.getMonth(), modelValue?.getDate());
				checkTime();
			});

			const selectOptionsHours12 = computed<SelectOption[]>(() => {
				let arr = createArray(12).map((_, index) => ({
					text: formatTimeValue(index === 0 ? 12 : index),
					value: amPm.value === AmPm.AM ? index : index + 12,
				}));

				if (isMinDay.value) {
					if (amPm.value === AmPm.AM && minHour.value >= 0) {
						arr = arr.filter(option => option.value >= minHour.value);
					} else if (amPm.value === AmPm.PM && minHour.value >= 12) {
						arr = arr.filter(option => option.value >= minHour.value);
					}
				}

				if (isMaxDay.value) {
					if (amPm.value === AmPm.AM && maxHour.value >= 0) {
						arr = arr.filter(option => option.value <= maxHour.value);
					} else if (amPm.value === AmPm.PM && maxHour.value >= 12) {
						arr = arr.filter(option => option.value <= maxHour.value);
					}
				}
				return arr;
			});

			const selectOptionsHours24 = computed<SelectOption[]>(() => {
				let arr = createArray(24).map((_, index) => ({
					text: formatTimeValue(index),
					value: index,
				}));

				if (isMinDay.value) {
					arr = arr.filter(option => option.value >= minHour.value);
				}

				if (isMaxDay.value) {
					arr = arr.filter(option => option.value <= maxHour.value);
				}
				return arr;
			});

			const selectOptionsMinutes = computed<SelectOption[]>(() => {
				let arr = createArray(60).map((_, index) => ({
					text: formatTimeValue(index),
					value: index,
				}));

				const maxMinutes = props.maxDate?.getMinutes();
				const minMinutes = props.minDate?.getMinutes();

				if (isMinDay.value && hour.value === minHour.value) {
					arr = arr.filter(option => option.value >= minMinutes);
				}

				if (isMaxDay.value && hour.value === maxHour.value) {
					arr = arr.filter(option => option.value <= maxMinutes);
				}
				return arr;
			});

			const selectOptionsAmPm = computed<SelectOption[]>(() => {
				let arr = [{ text: AmPm.AM, value: AmPm.AM }, { text: AmPm.PM, value: AmPm.PM }];

				if (isMinDay.value && minHour.value >= 12) {
					arr = arr.filter(el => el.value !== AmPm.AM);
				}

				if (isMaxDay.value && maxHour.value < 12) {
					arr = arr.filter(el => el.value !== AmPm.PM);
				}
				return arr;
			});

			const formatTimeValue = (i: number) => i < 10 ? `0${i}` : `${i}`;

			const createArray = (length: number) => {
				return Array.from({ length }, (_, index) => index + 1);
			};

			const checkTime = () => {
				if (isMaxDay.value) {
					hour.value = hour.value < maxHour.value ? hour.value : maxHour.value;
					minute.value = isMaxHour.value && minute.value > props.maxDate.getMinutes()
						? props.maxDate.getMinutes()
						: minute.value;
				}

				if (isMinDay.value) {
					hour.value = hour.value > minHour.value ? hour.value : minHour.value;
					minute.value = isMinHour.value && minute.value < props.minDate.getMinutes()
						? props.minDate.getMinutes()
						: minute.value;
				}

				amPm.value = hour.value < 12 ? AmPm.AM : AmPm.PM;
			};

			const setAmPm = () => {
				if (isHour12.value && amPm.value === AmPm.AM && hour.value >= 12) {
					hour.value = hour.value - 12;
				}
				if (isHour12.value && amPm.value === AmPm.PM && hour.value < 12) {
					hour.value = hour.value + 12;
				}

				setTime();
			};

			const setTime = () => {
				const dateNow = new Date();

				if (props.modelValue) {
					checkTime();
					emit('update:modelValue', new Date(props.modelValue?.getFullYear(), props.modelValue?.getMonth(), props.modelValue?.getDate(), hour.value, minute.value));
				} else {
					emit('update:modelValue', new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), hour.value, minute.value));
				}
			};

			return {
				selectOptionsHours24,
				selectOptionsHours12,
				selectOptionsMinutes,
				selectOptionsAmPm,
				hour,
				isHour12,
				minute,
				amPm,
				setAmPm,
				setTime,
			};
		},
	});
</script>

<style lang="less" scoped>
	.sc-time {
		display: flex;
		gap: 4px;
		align-items: center;

		&__select {
			width: 60px;

			&.half-day {
				.sc-text {
					font-weight: 600;
				}
			}
		}
	}
</style>
