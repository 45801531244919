
<ScDropdownLine
	:activated="activated"
	:disabled="option.disabled"
	:clickabled="true"
	:data-value="option.value"
	@click="onToggle"
>
	<ScCheckbox
		class="sc-multiselect__checkbox"
		:modelValue="option.checked"
		:disabled="option.disabled"
		color="black"
		readonly
	>
		<!-- @slot Use for custom option content -->
		<slot
			v-if="$slots.option"
			name="option"
			v-bind="option"
		/>
		<ScText
			v-else
			class="sc-multiselect__line-text"
		>
			{{ option.text }}
		</ScText>
	</ScCheckbox>
</ScDropdownLine>
