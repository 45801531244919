<template>
	<ScDropdown
		:disabled="disabled"
		:overflow="false"
		:class="{
			'sc-datepicker': true,
		 	'sc-datepicker_inline': inline,
		}"
		@close="$emit('close')"
		@open="$emit('open')"
	>
		<template #activator="{ isOpened, toggleDropdown }">
			<div
				class="sc-datepicker__activator"
				@click="toggleDropdown()"
				@keydown.enter="toggleDropdown()"
			>
				<slot
					v-if="slots.placeholder && !startValue"
					name="placeholder"
					v-bind="{ isOpened }"
				/>
				<ScDatePickerLabel
					v-else
					:disabled="disabled"
					:error="error"
					:is-opened="isOpened"
					:placeholder="placeholder"
					:size="size"
					:startValue="startValue"
					:endValue="endDate"
					:time="isTimeActive"
					:range="isRangeActive"
					@update:startValue="removeDate"
					@update:endValue="removeDate"
					:view="view"
				>
					<template
						v-if="slots.label"
						#default="{currentFormat}"
					>
						<slot
							name="label"
							v-bind="{startValue:modelValue, currentFormat}"
						/>
					</template>
				</ScDatePickerLabel>
			</div>
		</template>
		<template #content>
			<div class="sc-datepicker__dropdown">
				<ScCalendar
					:startValue="startDate"
					@update:startValue="updateStartDate"
					:endValue="endDate"
					@update:endValue="updateEndDate"
					:range="isRangeActive"
					:minDate="minDate"
					:maxDate="maxDate"
				/>
				<div
					v-if="range"
					class="sc-datepicker__switch"
				>
					<ScSwitch
						:modelValue="isRangeActive"
						@update:modelValue="isRangeActive = $event"
					/>
					<ScText>{{ translate('EndDate') }}</ScText>
				</div>
				<div
					v-if="time"
					class="sc-datepicker__switch"
				>
					<ScSwitch
						:disabled="requireTime"
						:modelValue="isTimeActive"
						@update:modelValue="isTimeActive = $event"
					/>
					<ScText>{{ translate('IncludeTime') }}</ScText>
				</div>
				<div
					v-show="isTimeActive && isRangeActive"
					class="sc-datepicker__multi-time"
				>
					<ScLabel :caption="translate('StartDateTime')">
						<ScTime
							:modelValue="startDate"
							@update:modelValue="updateStartTime"
							:minDate="minDate"
							:maxDate="maxDate"
						/>
					</ScLabel>
					<ScLabel :caption="translate('EndDateTime')">
						<ScTime
							:modelValue="endDate"
							@update:modelValue="updateEndTime"
							:minDate="minDate"
							:maxDate="maxDate"
						/>
					</ScLabel>
				</div>
				<div
					v-show="isTimeActive && !isRangeActive"
					class="sc-datepicker__single-time"
				>
					<ScTime
						:modelValue="startDate"
						@update:modelValue="updateStartTime"
						:minDate="minDate"
						:maxDate="maxDate"
					/>
				</div>
				<div
					v-if="requireConfirmation"
					class="sc-datepicker__buttons"
				>
					<div class="sc-datepicker__buttons-clear">
						<ScButton
							view="flat"
							@click="removeDate"
							:disabled="!startValue"
						>
							{{ translate('Clear') }}
						</ScButton>
					</div>
					<div class="sc-datepicker__buttons-action">
						<ScButton
							view="simple"
							@click="removeNewDate"
							:disabled="startDate === startValue && endDate === endValue"
						>
							{{ translate('Reset') }}
						</ScButton>
						<ScButton
							view="cta-black"
							@click="setDate"
							:disabled="startDate === startValue && endDate === endValue"
						>
							{{ translate('Apply') }}
						</ScButton>
					</div>
				</div>
			</div>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { defineComponent, PropType, ref, useSlots, watch } from 'vue';
	import ScDropdown from '../dropdown/dropdown.vue';
	import ScDatePickerLabel from './datepicker-label.vue';
	import ScCalendar from './calendar.vue';
	import ScButton from '../button/button.vue';
	import ScTime from './time.vue';
	import { ScSwitch } from '../switch';
	import { ScText } from '../text';
	import { translate } from '../../i18n';
	import { ScLabel } from '../label';

	/** The ScDatePicker component used for displaying a single calendar
	 * with the ability to select the selectedDateTime and minimum date
	 * */
	export default defineComponent({
		name: 'ScDatePicker',
		components: {
			ScDropdown,
			ScDatePickerLabel,
			ScCalendar,
			ScButton,
			ScTime,
			ScSwitch,
			ScText,
			ScLabel,
		},
		emits: [
			/** Update value event */
			'update:startValue',
			/** Update endValue event */
			'update:endValue',
			/** Open event */
			'open',
			/** Close event */
			'close',
			/** Create event */
			'create',
		],
		props: {
			/** Default start date */
			startValue: {
				type: Date,
				default: null,
			},
			/** Default end date */
			endValue: {
				type: Date,
				default: null,
			},
			/** Set label placeholder */
			placeholder: {
				type: String,
				default: '',
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set label size */
			size: {
				type: String as PropType<'28' | '36' | '44'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44'].includes(value),
			},
			/** Set time state */
			time: {
				type: Boolean,
				default: false,
			},
			/** Set range state */
			range: {
				type: Boolean,
				default: false,
			},
			/** Set default end date active state */
			endDateActive: {
				type: Boolean,
				default: false,
			},
			/** Set minimal choose date */
			minDate: {
				type: Date,
				default: null,
			},
			/** Set maximum choose date*/
			maxDate: {
				type: Date,
				default: null,
			},
			/** Set inline state */
			inline: {
				type: Boolean,
				default: false,
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'plate' | 'flat'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'plate', 'flat'].includes(value),
			},
			/** Set confirmations */
			requireConfirmation: {
				type: Boolean,
				default: false,
			},
			/** Set requireTime state */
			requireTime: {
				type: Boolean,
				default: false,
			},
		},

		setup(props, { emit }) {
			const startDate = ref(props.startValue);
			const isTimeActive = ref<boolean>((props.time && Boolean(props.startValue)) || (props.time && props.requireTime));
			const checkEndValue = () => {
				if (!props.startValue || !props.endValue) {
					return false;
				}
				return props.startValue?.toDateString() !== props.endValue?.toDateString();
			};
			const isRangeActive = ref<boolean>(Boolean(checkEndValue()) || props.endDateActive);
			const endDate = ref(isRangeActive.value ? props.endValue : null);
			const isMinDate = ref(null);
			const isMaxDate = ref(null);
			const startHour = ref(props.startValue?.getHours() ?? 0);
			const endHour = ref(props.endValue?.getHours() ?? 0);
			const startMinute = ref(props.startValue?.getMinutes() ?? 0);
			const endMinute = ref(props.endValue?.getMinutes() ?? 0);

			const slots = useSlots();

			watch(isRangeActive, (value) => {
				if (!value) {
					emit('update:endValue', null);
					endDate.value = null;
				}
			});

			const updateStartTime = (date: Date) => {
				if (props.requireConfirmation && startDate.value) {
					startDate.value = date;
				} else if (startDate.value) {
					startDate.value = date;
					emit('update:startValue', date);
				}

				startHour.value = date.getHours();
				startMinute.value = date.getMinutes();
			};

			const updateStartDate = (date: Date) => {
				checkMinMaxDate(date);
				if (isMinDate.value) {
					startHour.value = startHour.value < props.minDate.getHours() ? props.minDate.getHours() : startHour.value;
					startMinute.value = startMinute.value < props.minDate.getMinutes() ? props.minDate.getMinutes() : startMinute.value;
				}

				if (isMaxDate.value) {
					startHour.value = startHour.value > props.maxDate.getHours() ? props.maxDate.getHours() : startHour.value;
					startMinute.value = startMinute.value > props.maxDate.getMinutes() ? props.maxDate.getMinutes() : startMinute.value;
				}

				const emitDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHour.value, startMinute.value);
				startDate.value = emitDate;

				if (props.requireConfirmation) {
					return;
				} else {
					emit('update:startValue', emitDate);
				}
			};

			const updateEndDate = (date: Date) => {
				if (!date && props.requireConfirmation) {
					endDate.value = null;
					emit('update:endValue', props.endValue);
					return;
				} else if (!date) {
					endDate.value = null;
					endHour.value = 0;
					endMinute.value = 0;
					emit('update:endValue', null);
					return;
				}
				checkMinMaxDate(date);

				if (isMinDate.value) {
					endHour.value = endHour.value < props.minDate?.getHours() ? props.minDate.getHours() : endHour.value;
					endMinute.value = endMinute.value < props.minDate?.getMinutes() ? props.minDate.getMinutes() : endMinute.value;
				}

				if (isMaxDate.value) {
					endHour.value = endHour.value > props.maxDate?.getHours() ? props.maxDate.getHours() : endHour.value;
					endMinute.value = endMinute.value > props.maxDate?.getMinutes() ? props.maxDate.getMinutes() : endMinute.value;
				}

				if (!isTimeActive.value) {
					endHour.value = 23;
					endMinute.value = 59;
				}

				const emitDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), endHour.value, endMinute.value);
				endDate.value = emitDate;

				if (!props.requireConfirmation) {
					emit('update:endValue', emitDate);
				}
			};

			const updateEndTime = (date: Date) => {
				if (props.requireConfirmation && endDate.value) {
					endDate.value = date;
				} else if (endDate.value) {
					endDate.value = date;
					emit('update:endValue', date);
				}

				endHour.value = date.getHours();
				endMinute.value = date.getMinutes();
			};

			const checkMinMaxDate = (date: Date) => {
				isMinDate.value = date.getDate() === props.minDate?.getDate();
				isMaxDate.value = date.getDate() === props.maxDate?.getDate();
			};

			const setDate = () => {
				emit('update:startValue', startDate.value);
				emit('update:endValue', endDate.value);
			};

			const removeDate = () => {
				startDate.value = null;
				endDate.value = null;
				startHour.value = 0;
				startMinute.value = 0;
				endHour.value = 0;
				endMinute.value = 0;
				emit('update:startValue', null);
				emit('update:endValue', null);
			};

			const removeNewDate = () => {
				startDate.value = props.startValue;
				endDate.value = props.endValue;
				startHour.value = 0;
				startMinute.value = 0;
				endHour.value = 0;
				endMinute.value = 0;
				emit('update:startValue', props.startValue);
				emit('update:endValue', props.endValue);
			};

			return {
				startDate,
				endDate,
				slots,
				isTimeActive,
				isRangeActive,
				translate,
				updateStartTime,
				updateStartDate,
				removeDate,
				updateEndTime,
				updateEndDate,
				setDate,
				removeNewDate,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-datepicker {
		&,
		&__activator {
			width: 100%;

			&.sc-datepicker_inline {
				width: auto;
			}
		}

		&__dropdown {
			padding: 14px;
		}

		&__input::-webkit-inner-spin-button,
		&__input::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}

		&__input {
			width: 90px;
		}

		&__buttons {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			margin-top: 26px;
			flex-wrap: wrap;

			&-action {
				display: flex;
				gap: 10px;
				flex-wrap: wrap;
			}
		}

		&__multi-time {
			margin-top: 16px;
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		&__single-time {
			margin-top: 16px;
		}

		&__switch {
			display: flex;
			align-items: center;
			gap: 8px;
			margin-top: 16px;
		}
	}
</style>
