<template>
	<Teleport
		v-if="canRenderTeleport"
		:to="to"
	>
		<Transition
			:name="name"
			@beforeEnter="beforeEnter"
			@afterLeave="afterLeave"
		>
			<slot v-if="canRenderSlot"/>
		</Transition>
	</Teleport>
</template>

<script lang="ts">
	import { defineComponent, nextTick, ref, watchEffect } from 'vue';

	export default defineComponent({
		name: 'ScTransitionViaTeleport',
		emits: [
			'before-enter',
			'after-leave',
		],
		props: {
			condition: {
				type: Boolean,
				required: true,
				default: false,
			},
			name: {
				type: String,
				required: true,
				default: null,
			},
			to: {
				type: String,
				default: 'body',
			}
		},
		setup(props, { emit }) {
			const canRenderTeleport = ref(false);
			const canRenderSlot = ref(false);

			watchEffect(() => {
				if (props.condition) {
					canRenderTeleport.value = true;
					nextTick(() => canRenderSlot.value = true);
				} else {
					canRenderSlot.value = false;
				}
			});

			const beforeEnter = (e: unknown) => {
				canRenderTeleport.value = true;
				emit('before-enter', e);
			};

			const afterLeave = (e: unknown) => {
				canRenderTeleport.value = false;
				emit('after-leave', e);
			};

			return {
				canRenderTeleport,
				canRenderSlot,
				beforeEnter,
				afterLeave,
			};
		},
	});
</script>