<template>
	<button
		:disabled="disabled"
		class="sc-button-chips"
		type="button"
		@click="$emit('click', $event)"
	>		
		<!-- @slot Use for any text -->
		<slot/>
		<ScIcon
			v-if="closeable"
			name="close"
			size="16"
			class="sc-button-chips__icon"
			@click="$emit('close', $event)"
		/>
	</button>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScText from '../text/text.vue';
	import ScIcon from '../icon/icon.vue';

	/**
	 * The ScButtonChips component is used to convey small pieces of information.
	 * Using the closing property, the ScButtonChips becomes interactive, allowing user interaction.
	 * */
	export default defineComponent({
		name: 'ScButtonChips',
		components: { ScText, ScIcon },
		emits: [
			/** Click event */
			'click',
			/** Click event */
			'close',
		],
		props: {
			/** Set the presence of the close icon */
			closeable: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
	});

</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button-chips {
		height: 28px;
		padding: 4px 12px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
		color: @mulberry-purple-45;
		background: @mulberry-purple-0;
		border: none;
		border-radius: 14px;
		box-sizing: border-box;
		cursor: pointer;
		outline: none;
		transform: translate3d(0, 0, 0);
		white-space: nowrap;
		--icon-color: @mulberry-purple-40;

		&__icon {
			&:hover {
				--icon-color: @mulberry-purple-45;
			}

			&:active {
				--icon-color: @mulberry-purple;
			}
		}

		&:focus-visible {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3)
		}

		&:hover {
			background: @mulberry-purple-20;
		}

		&:active {
			background: @mulberry-purple-30;
		}

		&:disabled {
			background: @mulberry-purple-0;
			color: @mulberry-purple-35;
			cursor: default;
			pointer-events: none;
			--icon-color: @mulberry-purple-30;
		}
	}
</style>
