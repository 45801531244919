<template>
	<div
		:class="classes"
		:style="{ width }"
		:tabindex="disabled ? null : 0"
	>
		<div
			v-if="canShowPlaceholder"
			class="sc-multiselect-label__placeholder"
		>
			<ScText
				color="mulberry-purple-40"
				class="sc-multiselect-label__placeholder-text"
			>
				{{ placeholder }}
			</ScText>
		</div>
		<div
			v-else-if="$slots.default"
			class="sc-multiselect-label__custom"
		>
			<slot name="default" v-bind="{selectedOptions}"/>
		</div>
		<div
			v-else
			class="sc-multiselect-label__content"
		>
			<div
				ref="optionsWrapper"
				class="sc-multiselect-label__option-wrapper"
			>
				<ScBadge
					v-for="option in selectedOptions"
					:key="option.value"
					:closing="!option.disabled"
					color="grey"
					class="sc-multiselect-label__option"
					@remove="removeSelectedOption(option)"
				>
					{{ option.text }}
				</ScBadge>
			</div>
		</div>
		<ScTooltip
			v-if="canShowTooltip"
			padding="8"
		>
			<template #activator>
				<div class="sc-default-multiselect-label__counter">
					<ScText
						size="12"
						weight="semibold"
					>
						{{ selectedOptions.length }}
					</ScText>
					<ScIcon
						class="sc-multiselect-label__clear"
						name="circle-cross"
						size="16"
						@click.stop="clearAllSelectedOptions()"
					/>
				</div>
			</template>
			{{ selectedOptionsText }}
		</ScTooltip>
		<div class="sc-multiselect-label__icon">
			<ScIcon
				name="chevron-down"
				size="16"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, ref, nextTick, watch, onMounted, PropType } from 'vue';
	import { MultiselectOption } from './option';
	import ScBadge from '../badge/badge.vue';
	import ScIcon from '../icon/icon.vue';
	import ScText from '../text/text.vue';
	import ScTooltip from '../tooltip/tooltip.vue';

	/** The ScMultiselectLabel component is used to display the selected options. */
	export default defineComponent({
		name: 'ScMultiselectLabel',
		components: {
			ScBadge,
			ScIcon,
			ScText,
			ScTooltip,
		},
		emits: [
			/** update value event */
			'update:modelValue'
		],
		props: {
			/** v-model value */
			modelValue: {
				type: Array as PropType<Array<string | number>>,
				default: () => ([] as Array<string | number>),
			},
			/** Options */
			options: {
				type: Array as PropType<Array<MultiselectOption>>,
				required: true,
				default: () => ([] as Array<MultiselectOption>),
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set width */
			width: {
				type: String,
				default: null,
			},
			/** isOpened state */
			isOpened: {
				type: Boolean,
				required: true,
				default: false,
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36' | '44' | 'auto'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44', 'auto'].includes(value),
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'flat' | 'plate' | 'flat-active' | 'plate-active'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'flat', 'plate', 'flat-active', 'plate-active'].includes(value),
			},
		},
		setup(props, { emit }) {
			const optionsWrapper = ref();
			const isOverflowed = ref(false);

			const classes = computed(() => ({
				'sc-multiselect-label': true,
				[`sc-multiselect-label_size-${props.size}`]: true,
				'sc-multiselect-label_disabled': props.disabled,
				'sc-multiselect-label_error': props.error,
				'sc-multiselect-label_pressed': props.isOpened,
				[`sc-multiselect-label_view-${props.view}`]: true,
			}));

			const selectedOptions = computed(() => {
				return props.options?.filter(option => props.modelValue.includes(option.value));
			});

			const updateSelectedOptions = (options: Array<MultiselectOption>) => {
				emit('update:modelValue', options.map(option => option.value));
			};

			const selectedOptionsText = computed(() => selectedOptions.value.map(option => option.text).join(', '));

			const canShowPlaceholder = computed(() => props.placeholder && selectedOptions.value.length === 0);

			const canShowTooltip = computed(() => selectedOptions.value.length > 1 && isOverflowed.value);

			const canClearSelectedOptions = computed(() => selectedOptions.value.some(option => !option.disabled));

			const removeSelectedOption = (option: MultiselectOption) => {
				updateSelectedOptions(selectedOptions.value.filter(({ value }) => option.value !== value));
			};

			const clearAllSelectedOptions = () => {
				updateSelectedOptions(selectedOptions.value.filter(option => option.disabled));
			};

			onMounted(() => {
				watch(
					selectedOptions,
					() => {
						nextTick(() => {
							isOverflowed.value = optionsWrapper.value != null
								? optionsWrapper.value.parentElement.offsetWidth <= optionsWrapper.value.offsetWidth
								: false;
						});
					},
					{ immediate: true },
				);
			});

			return {
				optionsWrapper,
				classes,
				selectedOptions,
				selectedOptionsText,
				canShowPlaceholder,
				canShowTooltip,
				canClearSelectedOptions,
				removeSelectedOption,
				clearAllSelectedOptions,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-multiselect-label {
		cursor: pointer;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		position: relative;
		border: 1px solid @mulberry-purple-20;
		border-radius: 6px;
		box-sizing: border-box;
		transition: all .2s;
		height: 44px;
		padding: 10px 34px 10px 10px;
		outline: none;

		&__clear {
			width: 16px;
			height: 16px;
			cursor: pointer;
			--icon-color: @mulberry-purple-40;

			&:hover {
				--icon-color: @mulberry-purple-new;
			}

			&:active {
				--icon-color: @mulberry-purple;
			}
		}

		&__placeholder {
			max-width: 100%;
		}

		&__placeholder-text {
			color: @mulberry-purple-40;
			transition: color .2s;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&__icon {
			display: flex;
			align-items: center;
			--icon-color: @mulberry-purple-new;
			position: absolute;
			top: 50%;
			height: 16px;
			transform: translateY(-50%);
			transition: all .2s;
		}

		&__custom {
			color: @mulberry-purple-45;
			font-weight: 600;
			display: flex;
			gap: 10px;
			white-space: nowrap;
		}

		&:hover {
			border-color: @mulberry-purple-35;

			.sc-multiselect-label__placeholder-text {
				color: @mulberry-purple-new;
			}
		}

		&&_pressed {
			border-color: @mulberry-purple-new;

			.sc-multiselect-label__placeholder-text {
				color: @mulberry-purple;
			}

			.sc-multiselect-label__icon {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		&&_error {
			border: 1px solid @lightish-red;

			&:focus {
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #FEC5CC;
				border: 1px solid transparent;
			}
		}

		&&_disabled {
			border-color: @mulberry-purple-10;
			cursor: default;
			pointer-events: none;

			.sc-multiselect-label__placeholder-text {
				color: @mulberry-purple-35;
			}

			.sc-multiselect-label__content {
				--text-color: @mulberry-purple-35;
			}

			.sc-multiselect-label__icon {
				--icon-color: @mulberry-purple-30;
			}
		}

		&:focus {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);
			border-color: transparent;
		}

		&_size-28 {
			height: 28px;
			padding: 4px 28px 4px 8px;

			.sc-multiselect-label__icon {
				right: 8px;
			}
		}

		&_size-36 {
			height: 36px;
			padding: 8px 30px 8px 10px;

			.sc-multiselect-label__icon {
				right: 10px;
			}
		}

		&_size-44 {
			height: 44px;
			padding: 12px 32px 12px 12px;

			.sc-multiselect-label__icon {
				right: 12px;
			}
		}

		&__content {
			height: 22px;
			overflow: hidden;
			margin-right: auto;
			flex: 1;
		}

		&__option {
			margin-right: 4px;
		}

		&__option-wrapper {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
		}

		&__count-options {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			padding: 2px 4px;
			background-color: @mulberry-purple;
			color: @white;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			border-radius: 4px;
			margin-left: 4px;
			cursor: pointer;
		}

		&__clear-button {
			align-self: center;
			margin-left: 4px;
		}

		&_view-plate {
			background-color: @mulberry-purple-20;
			border-color: @mulberry-purple-20;

			&:hover {
				background-color: @mulberry-purple-30;
				border-color: @mulberry-purple-30;
			}

			&.sc-multiselect-label_pressed {
				background-color: @mulberry-purple-35;
				border-color: @mulberry-purple-35;
			}
		}
	}

	.sc-multiselect_invalid .sc-multiselect-label {
		border-color: @lightish-red;
	}

	.sc-default-multiselect-label__counter {
		display: flex;
		align-items: center;
		text-transform: none;
		margin-left: 8px;
		gap: 4px;
		--text-color: @mulberry-purple;
	}
</style>
