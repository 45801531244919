<template>
	<div
		v-show="dragActive"
		class="sc-files-drag-n-drop"
	/>
</template>

<script lang="ts">
	import { computed, defineComponent, ref, onMounted, onBeforeUnmount, PropType } from 'vue';

	/** Used to organize a file drop-n-down zone on full page. */
	export default defineComponent({
		name: 'ScFilesDragNDrop',
		props: {
			/** Set the type (extension) of uploaded files */
			accept: {
				type: Array as PropType<Array<string>>,
				default: null,
			},
			/** Set the ability to upload multiple files */
			multiple: {
				type: Boolean,
				default: false,
			},
		},
		emits: [
			/** User drop file(s) */
			'drop'
		],
		setup(props, { emit }) {
			const dragActive = ref(false);
			const dragEnterCounter = ref(0);

			const acceptRegex = computed(() => {
				return props.accept?.map((extension) => new RegExp(`\\${extension}$`, 'i')) ?? [];
			});

			const dragEnter = (e: DragEvent) => {
				dragOver(e);
				dragEnterCounter.value++;
			};

			const dragOver = (e: DragEvent) => {
				e.stopPropagation();
				e.preventDefault();
				dragActive.value = true;
			};

			const dragLeave = () => {
				dragEnterCounter.value--;
				if (dragEnterCounter.value === 0) {
					dragActive.value = false;
				}
			};

			const drop = (e: DragEvent) => {
				e.stopPropagation();
				e.preventDefault();

				dragEnterCounter.value = 0;
				dragActive.value = false;

				const { dataTransfer } = e;
				if (dataTransfer == null || dataTransfer.files == null) {
					return false;
				}

				dataTransfer.dropEffect = 'copy';

				let files = new Array(...dataTransfer.files);
				if (props.accept != null) {
					files = files.filter((file) => {
						return acceptRegex.value.some((acceptRegex) => acceptRegex.test(file.name));
					});
				}

				if (files.length === 0) {
					return false;
				}

				emit('drop', props.multiple ? files : files[0]);

				return false;
			};

			onMounted(() => {
				document.body.addEventListener('dragenter', dragEnter);
				document.body.addEventListener('dragover', dragOver);
				document.body.addEventListener('dragleave', dragLeave);
				document.body.addEventListener('drop', drop);
			});

			onBeforeUnmount(() => {
				document.body.removeEventListener('dragenter', dragEnter);
				document.body.removeEventListener('dragover', dragOver);
				document.body.removeEventListener('dragleave', dragLeave);
				document.body.removeEventListener('drop', drop);
			});

			return { dragActive };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-files-drag-n-drop {
		border: 6px solid @fuchsia-blue;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;
	}
</style>
