import { ref, Ref } from 'vue';

type Layer = Ref<HTMLElement>;

const state = ref<Layer[]>([]);

export default function useLayers() {
	const add = (element: Layer): void => {
		state.value.push(element);
	};

	const pop = (): void => {
		state.value.pop();
	};

	const getTopLayer = (): HTMLElement => {
		return state.value[state.value.length - 1]?.value;
	};

	const isTopLayer = (element: Layer): boolean => {
		return state.value.indexOf(element) === state.value.length - 1;
	};

	return {
		add,
		pop,
		getTopLayer,
		isTopLayer,
	};
}