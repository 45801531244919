<template>
	<Component
		:is="tag"
		:class="`sc-text sc-text_${size} sc-text_${weight} sc-text_${stylization} sc-text_${transform}`"
		:style="{ color: color && `var(--${color})` }"
	>
		<!-- @slot Use for any text -->
		<slot/>
	</Component>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ColorNames } from '../../types/colors';

	/** Renders any text html tag (default span) with specified font attributes and text color */
	export default defineComponent({
		name: 'ScText',
		props: {
			/** Set html tag (default span) */
			tag: {
				type: String as PropType<'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'>,
				default: 'span',
				validator: (value: string) => ['span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'].includes(value),
			},
			/** Set font size */
			size: {
				type: String as PropType<'12' | '14' | '16' | '20' | '24' | '36'>,
				default: '14',
				validator: (value: string) => ['12', '14', '16', '20', '24', '36'].includes(value),
			},
			/** Set font weight */
			weight: {
				type: String as PropType<'regular' | 'semibold' | 'bold'>,
				default: 'regular',
				validator: (value: string) => ['regular', 'semibold', 'bold'].includes(value),
			},
			/** Set font stylization */
			stylization: {
				type: String as PropType<'normal' | 'italic' | 'underline'>,
				default: 'normal',
				validator: (value: string) => ['normal', 'italic', 'underline'].includes(value),
			},
			/** Set font transform */
			transform: {
				type: String as PropType<'none' | 'uppercase' | 'lowercase' | 'capitalize'>,
				default: 'none',
				validator: (value: string) => ['none', 'uppercase', 'lowercase', 'capitalize'].includes(value),
			},
			/** Set text color */
			color: {
				type: String as PropType<ColorNames>,
				default: null,
			},
		},
	});
</script>

<style lang="less" scoped>
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}

	.sc-text {
		color: var(--text-color);
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;

		&_12 {
			font-size: 12px;
			line-height: 16px;
		}

		&_14 {
			font-size: 14px;
			line-height: 20px;
		}

		&_16 {
			font-size: 16px;
			line-height: 24px;
		}

		&_20 {
			font-size: 20px;
			line-height: 28px;
		}

		&_24 {
			font-size: 24px;
			line-height: 30px;
		}

		&_36 {
			font-size: 36px;
			line-height: 44px;
		}

		&_regular {
			font-weight: 400;
		}

		&_semibold {
			font-weight: 600;
		}

		&_bold {
			font-weight: 700;
		}

		&_normal {
			font-style: normal;
		}

		&_italic {
			font-style: italic;
		}

		&_underline {
			text-decoration: underline;
		}

		&_uppercase {
			text-transform: uppercase;
			letter-spacing: 0.6px;
		}

		&_lowercase {
			text-transform: lowercase;
		}

		&_capitalize {
			text-transform: capitalize;
		}
	}
</style>
