<template>
	<div
		:class="{
			'sc-tab-simple': true,
			disabled,
			selected,
		}"
		@click="$emit('click', $event)"
	>
		<!-- @slot Use for any text -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'ScTabSimple',
		props: {
			/** Set selected state */
			selected: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tab-simple {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 2px 10px;
		white-space: nowrap;
		cursor: pointer;
		position: relative;
		color: @mulberry-purple-new;
		font-size: 14px;
		height: 28px;
		line-height: 20px;
		font-weight: 600;
		transition: color 100ms;

		&:hover {
			color: @mulberry-purple-50;
		}

		&.selected {
			color: @fuchsia-blue;
			background: @fuchsia-blue-0;
			border-radius: 4px;

			&:hover {
				color: @fuchsia-blue-60;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;
			color: @mulberry-purple-35;
		}
	}
</style>
