
<ScDropdown
	:disabled="disabled"
	:overflow="false"
	:prefer="prefer"
	:alignWidth="true"
	:class="{
		'sc-multiselect': true,
		'sc-select_inline': inline,
	}"
	@open="open()"
	@close="close()"
>
	<template #activator="{ isOpened, toggleDropdown }">
		<div
			class="sc-multiselect__activator"
			data-test-id="multiselect-activator"
			@click="toggleDropdown()"
			@keydown.enter="toggleDropdown()"
		>
			<slot
				v-if="$slots.activator != null"
				name="activator"
				v-bind="{ isOpened }"
			/>
			<slot
				v-else-if="$slots.placeholder != null && !modelValue.length"
				name="placeholder"
				v-bind="{ isOpened }"
			/>
			<ScMultiselectLabel
				v-else
				:modelValue="modelValue"
				@update:modelValue="$emit('update:modelValue', $event)"
				:options="allOptions"
				:is-opened="isOpened"
				:disabled="disabled"
				:error="error"
				:placeholder="placeholder"
				:size="size"
				:view="view"
			>
				<template
					v-if="$slots.label != null"
					#default="{ selectedOptions }"
				>
					<slot
						name="label"
						v-bind="{ selectedOptions }"
					/>
				</template>
			</ScMultiselectLabel>
		</div>
	</template>
	<template #content="{ closeDropdown }">
		<div
			class="sc-multiselect__dropdown"
			@keydown.enter="setOption()"
			:tabindex="search ? '' : '-1'"
			ref="content"
			@keydown.down="setFocusNext"
			@keydown.up="setFocusPrev"
		>
			<div
				v-if="category"
				class="sc-multiselect__category"
				data-test-id="multiselect-categories"
			>
				<ScTabList bordered>
					<ScTab
						size="44"
						v-for="(group, index) of allCategoryGroups"
						:key="index"
						:selected="group.active"
						@click.native="setCategory(index);$event.target.scrollIntoView({ behavior: 'smooth' })"
					>
						{{ group.title }}
					</ScTab>
				</ScTabList>
			</div>
			<ScInput
				v-if="search"
				:placeholder="searchPlaceholder"
				:modelValue="searchText"
				:clearable="true"
				@update:modelValue="searchText = $event"
				icon="search"
				class="sc-multiselect__search"
				autofocus
				data-test-id="multiselect-search"
				size="44"
			/>
			<div
				v-show="visibleOptionGroups.length"
				ref="optionsList"
				:class="{
					'sc-multiselect__options': true,
					'shadow-top': search && scrollPosition > 0.01,
					'shadow-bottom': !controls && hasActionsTemplate && scrollPosition < 0.99,
				}"
				data-test-id="multiselect-options"
				@scroll="updateScrollPosition"
			>
				<ScDropdownGroup
					v-for="(group, index) of visibleOptionGroups"
					:key="index"
					:title="group.title"
				>
					<template
						v-for="option of group.options"
						:key="option.value"
					>
						<ScMultiselectRadioLine
							v-if="option.value instanceof Array"
							:modelValue="modelValue"
							:activated="option.value === focusOption"
							:option="option"
							@update:modelValue="$emit('update:modelValue', $event)"
						/>
						<ScMultiselectLine
							v-else
							:activated="option.value === focusOption"
							:option="option"
							@toggleOption="toggleOption"
						>
							<template
								v-if="$slots.option"
								#option="option"
							>
								<slot
									v-bind="option"
									name="option"
								/>
							</template>
						</ScMultiselectLine>
					</template>
					<ScDropdownLine
						v-if="canCreateEntity && index === visibleOptionGroups.length - 1"
						:clickabled="true"
						:closing="true"
						data-test-id="multiselect-creation"
						@click="createEntity()"
					>
						<!-- @slot Use for create entity -->
						<slot
							name="creation"
							:text="trimmedSearchText"
						/>
					</ScDropdownLine>
				</ScDropdownGroup>
			</div>
			<div
				v-if="hasControls"
				class="sc-multiselect__controls"
			>
				<ScButton
					v-if="!checkSelectedOptions"
					view="text"
					@click.native="setAllOptions"
				>
					{{ translate('SelectAll') }}
				</ScButton>
				<div class="sc-multiselect__controls-group">
					<ScButton
						v-if="checkSelectedOptionsLength"
						view="text"
						@click.native="removeSelectedOptions"
					>
						{{ translate('Deselect') }}
					</ScButton>
					<ScText color="mulberry-purple-40">
						{{
							checkSelectedOptionsLength
								? translate('CountSelected').replace('{count}', checkSelectedOptionsLength.toString())
								: translate('AllCounts').replace('{count}', checkAllAvailableOptionsLength.toString())
						}}
					</ScText>
				</div>
			</div>
			<div
				v-if="hasActionsTemplate"
				class="sc-multiselect__actions"
				data-test-id="multiselect-actions"
			>
				<!-- @slot Use for any content -->
				<slot
					name="actions"
					v-bind="{ closeDropdown }"
				/>
			</div>
		</div>
	</template>
</ScDropdown>
