<template>
	<div class="sc-progress-emotional">
		<ScProgress
			:percent="percent"
			:background-color="percent > 0 ? 'fuchsia-blue-10' : 'mulberry-purple-10'"
			:bar-color="percent >= 100 ? 'breathtaking-mint-60' : 'fuchsia-blue'"
		/>
		<div
			:style="{ left: `calc(${Math.min(percent, 100)}% - 10px)` }"
			class="sc-progress-emotional__content"
		>
			{{ percent <= 0 ? '🙈' : (percent >= 100 ? '🔥' : '🤞') }}
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScProgress from './progress.vue';

	/**
	 * The ScProgressEmotional is a graphical user interface element, element in which the bar to display
	 * indexing a move or completing some task is done with an emoji
	 */
	export default defineComponent({
		name: 'ScProgressEmotional',
		components: {
			ScProgress,
		},
		props: {
			/** Set the fill percentage */
			percent: {
				type: Number,
				default: 0,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-progress-emotional {
		cursor: default;
		position: relative;

		&__content {
			position: absolute;
			width: 20px;
			height: 20px;
			left: 0;
			top: -7px;
			font-size: 12px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			background: @white;
			box-shadow: 0 2px 10px @mulberry-purple-35;
			user-select: none;
		}
	}
</style>
