<template>
	<div class="sc-validation-field">
		<div class="sc-validation-field__content">
			<slot name="default"/>
		</div>
		<Component :is="transitionComponent">
			<div
				v-if="hasError && slots.errors"
				class="sc-validation-field__errors"
			>
				<!-- @slot Use for field errors. Accepts ScValidationError component only -->
				<slot name="errors"/>
			</div>
			<div
				v-else-if="slots.hint"
				class="sc-validation-field__hint"
			>
				<ScText
					color="mulberry-purple-new"
					size="12"
				>
					<!-- @slot Use for any hint text -->
					<slot name="hint"/>
				</ScText>
			</div>
		</Component>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType, provide, useSlots } from 'vue';
	import { ValidationField } from '../../composables/use-validation';
	import { Validation } from './types';
	import ScText from '../text/text.vue';
	import ScTransitionOpacity from '../transition/opacity.vue';
	import ScTransitionSlide from '../transition/slide.vue';

	export default defineComponent({
		name: 'ScValidationField',
		props: {
			modelValue: {
				type: Object as PropType<ValidationField<unknown>>,
				required: true,
				default: null,
			},
		},
		components: {
			ScText,
		},
		setup(props) {
			const slots = useSlots();

			const hasError = computed(() => Boolean(props.modelValue.$invalid && props.modelValue.$dirty));

			const errors = computed(() => props.modelValue?.$errors ?? []);

			const transitionComponent = computed(() => slots.hint ? ScTransitionOpacity : ScTransitionSlide);

			provide<Validation>('validation', { hasError, errors });

			return {
				slots,
				hasError,
				errors,
				transitionComponent,
			};
		},
	});
</script>

<style lang="less" scoped>
	.sc-validation-field {
		display: block;

		&__errors,
		&__hint {
			display: block;
			margin-top: 4px;
		}
	}
</style>
