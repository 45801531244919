
<button
	:class="{
		'sc-button': true,
		[`sc-button_${size}`]: true,
		[`sc-button_${view}`]: true,
		'sc-button_icon': icon && !$slots.default,
		'sc-button_activated': activated,
		'sc-button_highlighted': highlighted,
		'sc-button_warning': warning,
		'sc-button_response': !icon && response,
	}"
	:disabled="disabled || response"
	type="button"
	@click="$emit('click', $event)"
	@mousedown="$emit('mousedown', $event)"
>
	<template v-if="icon && iconPosition === 'left'">
		<ScLoaderSpin
			v-if="response"
			:size="loaderSize"
			class="sc-button__loader"
		/>
		<div
			v-else
			class="sc-button__icon"
		>
			<ScIcon
				:name="icon"
				:size="iconSize"
			/>
			<span
				v-if="notification && view === 'flat'"
				:class="{
					'sc-button_notification': true,
					'sc-button_notification-small': size === '28',
				}"
			/>
		</div>
	</template>
	<span
		v-if="$slots.default"
		class="sc-button__text"
	>
		<!-- @slot Use for any text -->
		<slot name="default"/>
	</span>
	<ScBadge
		v-if="$slots.badge != null"
		class="sc-button__badge"
	>
		<!-- @slot Use for the badge -->
		<slot name="badge"/>
	</ScBadge>
	<template v-if="!icon || iconPosition === 'right'">
		<ScLoaderSpin
			v-if="response"
			:size="loaderSize"
			class="sc-button__loader"
		/>
		<div
			v-else-if="icon"
			class="sc-button__icon"
		>
			<ScIcon
				:name="icon"
				:size="iconSize"
			/>
			<span
				v-if="notification && view === 'flat'"
				:class="{
					'sc-button_notification': true,
					'sc-button_notification-small': size === '28',
				}"
			/>
		</div>
	</template>
</button>
