<template>
	<ScTransitionViaTeleport
		:condition="popupComponent != null"
		name="opacity"
		@before-enter="beforeOpenPopup()"
		@after-leave="afterClosePopup()"
	>
		<div class="sc-popup-presenter">
			<div class="sc-popup-presenter__bg"></div>
			<div class="sc-popup-presenter__layout">
				<div class="sc-popup-presenter__content">
					<Suspense>
						<Component
							:is="popupComponent"
							v-bind="popupProps"
							@cancel="closePopup()"
							@confirm="closePopup($event)"
						/>
					</Suspense>
				</div>
			</div>
		</div>
	</ScTransitionViaTeleport>
</template>

<script lang="ts">
	import { computed, defineComponent } from 'vue';
	import ScTransitionViaTeleport from '../transition/via-teleport.vue';
	import usePopup from '../../composables/use-popup';

	/**
	 * The ScPopupPresenter component used as a background for the callable popup component.
	 * <br/>
	 * **Attention! Please place this component in the root component of your application for it to work correctly.**
	 * */
	export default defineComponent({
		name: 'ScPopupPresenter',
		components: {
			ScTransitionViaTeleport,
		},
		setup() {
			const popup = usePopup();

			const popupComponent = computed(() => popup.state.value?.component);

			const popupProps = computed(() => popup.state.value?.props);

			const closePopup = (data?: unknown) => {
				if (popup.state.value) {
					popup.state.value.close(data);
				}
			};

			const beforeOpenPopup = () => {
				(document.activeElement as HTMLElement)?.blur();
				document.documentElement.style.overflow = 'hidden';
			};

			const afterClosePopup = () => {
				document.documentElement.style.overflow = null;
			};

			return {
				popupComponent,
				popupProps,
				closePopup,
				beforeOpenPopup,
				afterClosePopup,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";

	.sc-popup-presenter {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9998;

		&__bg {
			background: @mulberry-purple-30;
			opacity: 0.8;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 9998;
		}

		&__layout {
			z-index: 9999;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 16px;
			animation: slide-fade 200ms ease-in-out;

			.screen-lt-480({
				padding: 0;
			});
		}

		&__content {
			min-height: 100%;
			margin: auto;
			position: relative;
			width: fit-content;
			display: flex;
			align-items: center;

			.screen-lt-480({
				margin: 0;
				width: 100%;
			});
		}
	}

	@keyframes slide-fade {
		0% {
			transform: translateY(-16px);
		}
		100% {
			transform: translateY(0);
		}
	}

	.opacity-enter-active,
	.opacity-leave-active {
		transition: opacity 200ms ease-in-out;
	}

	.opacity-enter-from,
	.opacity-leave-to {
		opacity: 0;
	}
</style>
