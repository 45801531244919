
<span :class="`sc-loader-spin sc-loader-spin_${size}`">
	<svg
		:style="{ stroke: color && `var(--${color})` }"
		viewBox="0 0 24 24"
	>
		<circle
			cx="12"
			cy="12"
			r="10"
		/>
		<circle
			cx="12"
			cy="12"
			r="10"
		/>
	</svg>
</span>
