<template>
	<div
		:class="{
			'sc-tab-rounded': true,
			disabled,
			selected,
		}"
		@click="$emit('click', $event)"
	>
		<ScIcon
			v-if="icon"
			:name="icon"
			size="16"
		/>
		<!-- @slot Use for any text -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { IconNames } from '../icon';
	import ScIcon from '../icon/icon.vue';

	/** The ScTabRounded component is used to that organizes the content (makes it more compact) */
	export default defineComponent({
		name: 'ScTabRounded',
		components: { ScIcon },
		props: {
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set selected state */
			selected: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tab-rounded {
		padding: 4px 12px;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		background: @mulberry-purple-0;
		border-radius: 14px;
		color: @mulberry-purple-45;
		cursor: pointer;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		transition: background 200ms, color 200ms;
		white-space: nowrap;
		--icon-color: @mulberry-purple-new;

		&:hover {
			background: @mulberry-purple-20;
		}

		&:active {
			background: @mulberry-purple-35;
		}

		&.selected {
			background: @mulberry-purple-30;

			&:hover {
				background: @mulberry-purple-35;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;
			background: @mulberry-purple-0;
			color: @mulberry-purple-35;
			--icon-color: @mulberry-purple-30;

			&.selected {
				background: @mulberry-purple-10;
			}
		}
	}
</style>
