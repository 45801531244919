
<ScText
	:size="size"
	:weight="weight"
	:color="color"
	tag="h1"
>
	<!-- @slot Use for any text -->
	<slot/>
</ScText>
