<template>
	<slot v-bind="{ selectFiles }"/>
</template>

<script lang="ts">
	import { onMounted, onBeforeUnmount, PropType, defineComponent } from 'vue';

	/** The component is used to select user file(s) */
	export default defineComponent({
		name: 'ScFilesSelect',
		props: {
			/** Set the type (extension) of uploaded files */
			accept: {
				type: Array as PropType<Array<string>>,
				default: null,
			},
			/** Set the ability to upload multiple files */
			multiple: {
				type: Boolean,
				default: false,
			},
		},
		emits: [
			/** User select file(s) */
			'select'
		],
		setup(props, { emit }) {
			let input: HTMLInputElement = null;

			const selectFiles = () => {
				if (input != null) {
					const event = document.createEvent('MouseEvents');
					event.initEvent('click', true, true);
					input.dispatchEvent(event);
				}
			};

			onMounted(() => {
				input = document.createElement('input');
				input.type = 'file';
				input.style.height = '0px';
				input.style.opacity = '0';
				input.style.position = 'fixed';
				input.style.visibility = 'hidden';
				input.style.width = '0px';

				input.setAttribute('accept', props.accept ? props.accept.join(',') : '');
				input.setAttribute('multiple', props.multiple ? 'multiple' : '');

				input.addEventListener('change', () => {
					const files = input.files;
					emit('select', props.multiple ? new Array(...files) : files[0]);
					input.value = null;
				});

				document.body.appendChild(input);
			});

			onBeforeUnmount(() => {
				if (input != null) {
					document.body.removeChild(input);
					input = null;
				}
			});

			return { selectFiles };
		},
	});
</script>
