<template>
	<span :class="`sc-loader-spin sc-loader-spin_${size}`">
		<svg
			:style="{ stroke: color && `var(--${color})` }"
			viewBox="0 0 24 24"
		>
			<circle
				cx="12"
				cy="12"
				r="10"
			/>
			<circle
				cx="12"
				cy="12"
				r="10"
			/>
		</svg>
	</span>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ColorNames } from '../../types/colors';

	/** The loader for displaying loading state of any small section. */
	export default defineComponent({
		name: 'ScLoaderSpin',
		props: {
			/** Set size */
			size: {
				type: String as PropType<'16' | '20' | '24' | '32'>,
				default: '24',
				validator: (value: string) => ['16', '20', '24', '32'].includes(value),
			},
			/** Set color */
			color: {
				type: String as PropType<ColorNames>,
				default: '',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	@duration: 1400ms;
	@offset: 68;

	.sc-loader-spin {
		display: inline-flex;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
		padding: 1px;

		svg {
			animation: spin @duration linear infinite;
			fill: none;
			stroke: var(--loader-color);
			stroke-linecap: round;
			stroke-miterlimit: 10;
			stroke-width: 2;

			circle:nth-child(1) {
				animation: dash @duration ease-in-out infinite;
				stroke-dasharray: @offset;
				stroke-dashoffset: 0;
				transform-origin: center;
			}

			circle:nth-child(2) {
				opacity: 0.15;
			}
		}

		&_16 {
			width: 16px;
			height: 16px;

			svg {
				stroke-width: 1.5px;
			}
		}

		&_20 {
			width: 20px;
			height: 20px;

			svg {
				stroke-width: 2px;
			}
		}

		&_24 {
			width: 24px;
			height: 24px;

			svg {
				stroke-width: 2.2px;
			}
		}

		&_32 {
			width: 32px;
			height: 32px;

			svg {
				stroke-width: 2.5px;
			}
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(270deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: @offset;
		}
		50% {
			stroke-dashoffset: (@offset/4);
			transform:rotate(135deg);
		}
		100% {
			stroke-dashoffset: @offset;
			transform:rotate(450deg);
		}
	}
</style>
