<template>
	<div
		class="sc-progress"
		:class="{
			[`sc-progress_${backgroundColor}`]: true,
			'sc-progress--shine': shine,
		}"
	>
		<div
			:class="`sc-progress__bar_${barColor}`"
			:style="{ width: `${percent}%` }"
			class="sc-progress__bar"
		/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	type BackgroundColors = 'fuchsia-blue-0' | 'fuchsia-blue-10' | 'mulberry-purple-10' | 'mulberry-purple-20';

	type BarColors =
		'pink'
		| 'red'
		| 'blue'
		| 'purple-light'
		| 'purple'
		| 'mint-light'
		| 'mint'
		| 'orange-light'
		| 'orange'
		| 'green'
		| 'grey'
		| 'fuchsia-blue'
		| 'breathtaking-mint-60'
		| 'gradient'
		| 'mulberry-purple-40';

	/**
	 * The ScProgress is a graphical user interface element, which is a panel for displaying
	 * the indexing of a move or performing some kind of task
	 */
	export default defineComponent({
		name: 'ScProgress',
		props: {
			/** Set the fill percentage */
			percent: {
				type: Number,
				default: 0,
				validator: (value: number) => value >= 0 && value <= 100,
			},
			/** Set bar color */
			barColor: {
				type: String as PropType<BarColors>,
				default: 'pink',
			},
			/** Set background color */
			backgroundColor: {
				type: String as PropType<BackgroundColors>,
				default: 'fuchsia-blue-10',
			},
			/** Set the visual display of the boot */
			shine: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-progress {
		position: relative;
		width: 100%;
		height: 6px;
		border-radius: 4px;
		cursor: default;
		overflow: hidden;

		&--shine .sc-progress__bar::before {
			content: "";
			position: absolute;
			width: 400px;
			height: 6px;
			background-image: linear-gradient(90deg, rgba(200, 198, 206, 0.05) 0,
			rgba(230, 229, 232, 0.7) 30px,
			rgba(200, 198, 206, 0.05) 60px);
			animation: progress-animation 3000ms linear infinite;
		}

		&__bar {
			border-radius: 4px;
			height: 100%;

			&_pink {
				background-color: @pink-insanity-30;
			}

			&_red {
				background-color: @lightish-red-30;
			}

			&_blue {
				background-color: @denim-30;
			}

			&_orange-light {
				background-color: @radiation-carrot-30;
			}

			&_orange {
				background-color: @radiation-carrot-60;
			}

			&_green {
				background-color: @artificial-turf-30;
			}

			&_purple-light {
				background-color: @fuchsia-blue-30;
			}

			&_purple {
				background-color: @fuchsia-blue-60;
			}

			&_mint-light {
				background-color: @breathtaking-mint-30;
			}

			&_mint {
				background-color: @breathtaking-mint;
			}

			&_grey {
				background-color: @mulberry-purple-new;
			}

			&_fuchsia-blue {
				background-color: @fuchsia-blue;
			}

			&_breathtaking-mint-60 {
				background-color: @breathtaking-mint-60;
			}

			&_mulberry-purple-40 {
				background-color: @mulberry-purple-40;
			}

			&_gradient {
				background: linear-gradient(90deg, @fuchsia-blue 0%, @pink-insanity 100%);
			}
		}

		&_fuchsia-blue-0 {
			background-color: @fuchsia-blue-0;
		}

		&_fuchsia-blue-10 {
			background-color: @fuchsia-blue-10;
		}

		&_mulberry-purple-10 {
			background-color: @mulberry-purple-10;
		}

		&_mulberry-purple-20 {
			background-color: @mulberry-purple-20;
		}
	}

	@keyframes progress-animation {
		0% {
			transform: translateX(-200%);
		}
		100% {
			transform: translateX(200%);
		}
	}
</style>
