<template>
	<label
		class="sc-checkbox"
		:class="{
			'sc-checkbox_checked': modelValue,
			'sc-checkbox_readonly': readonly,
			'sc-checkbox_disabled': disabled,
			'sc-checkbox_indeterminate': indeterminate,
		}"
	>
		<input
			:checked="modelValue"
			:disabled="disabled"
			:data-test-id="null"
			type="checkbox"
			class="sc-checkbox__input"
			@change="$emit('update:modelValue', $event.target.checked)"
		/>
		<span :class="{ 'sc-checkbox__state': true, indeterminate }"/>
		<span
			v-if="$slots.default != null"
			class="sc-checkbox__label"
			@click.stop
		>
			<!-- @slot Use for any text -->
			<slot/>
		</span>
	</label>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	/**
	 * Checkbox component.
	 * - Used for selecting multiple values from several options.
	 * - If you use only one checkbox, it is the same as using Switch to toggle between two states.
	 *   The difference is that Switch will trigger the state change directly, but Checkbox just marks the state as changed and this needs to be submitted.
	 */
	export default defineComponent({
		name: 'ScCheckbox',
		emits: [
			/** Updating the v-model */
			'update:modelValue',
		],
		props: {
			/** Set selected state */
			modelValue: {
				type: Boolean,
				default: false,
			},
			/** Set readonly state */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set indeterminate state */
			indeterminate: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-checkbox {
		position: relative;
		text-align: left;
		display: flex;
		flex-flow: row nowrap;
		cursor: pointer;
		border-radius: 6px;
		column-gap: 6px;

		&__input {
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			z-index: 1;
			font-size: 10px;
			border: none;
			outline: none;
			cursor: pointer;
			opacity: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		&__state {
			margin: 2px;
			display: flex;
			flex: none;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			box-sizing: border-box;
			background: transparent;
			position: relative;
			border: 2px solid @mulberry-purple-30;
			border-radius: 4px;
			cursor: pointer;
			transition: all 150ms ease-in-out;

			&::before {
				content: "";
				position: absolute;
				width: 5.5px;
				height: 9.5px;
				opacity: 0;
				box-sizing: border-box;
				border-right: 3px solid @white;
				border-bottom: 3px solid @white;
				top: -4.5px;
				transform: translate3d(0, -6px, 0) scale(0) rotate(45deg);
				transition: all 150ms ease-in-out;
			}

			&.indeterminate::before {
				width: 10px;
				height: 0;
				border-bottom: 2.8px solid #FFFFFF;
				border-radius: 2px;
				border-right: 0;
			}
		}

		&__label {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: @mulberry-purple;
			--text-color: @mulberry-purple;
		}

		&:hover {
			.sc-checkbox__state {
				border-color: @mulberry-purple-35;
			}

			&.sc-checkbox_checked .sc-checkbox__state {
				border-color: @mulberry-purple-70;
			}
		}

		&_checked .sc-checkbox__state {
			border: 10px solid @mulberry-purple-90;

			&::before {
				opacity: 1;
				transform: translate3d(0, -2px, 0) scale(1) rotate(45deg);
			}
		}

		&_checked .indeterminate__state {
			border: 10px solid @mulberry-purple-90;

			&::before {
				opacity: 1;
				transform: translate3d(0, 0, 0) scale(1) rotate(0);
			}
		}

		&_indeterminate .sc-checkbox__state {
			border: 10px solid @mulberry-purple-90;

			&::before {
				opacity: 1;
				transform: translate3d(0, 3px, 0) scale(1) rotate(0);
			}
		}

		&_readonly {
			pointer-events: auto;
		}

		&_disabled {
			cursor: default;

			.sc-checkbox__input {
				cursor: default;
			}

			&:hover {
				.sc-checkbox__state {
					border-color: @mulberry-purple-10;
					cursor: default;
				}

				&.sc-checkbox_checked .sc-checkbox__state {
					border-color: @mulberry-purple-35;
				}
			}

			.sc-checkbox__state {
				border-color: @mulberry-purple-10;
			}

			&.sc-checkbox_checked .sc-checkbox__state {
				border-color: @mulberry-purple-35;
			}

			.sc-checkbox__label {
				color: @mulberry-purple-40;
				--text-color: @mulberry-purple-40;
			}
		}
	}
</style>
