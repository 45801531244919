<template>
	<div
		:style="{
			'--table-left': tableRect.left + 'px',
			'--table-right': tableRect.right + 'px',
			'--table-top': tableRect.top + 'px',
			'--table-scroll-top': tableRect.scrollTop + 'px',
			'--table-height': tableRect.height + 'px',
			'--table-width': tableRect.width + 'px',
		}"
		:class="{ 'sc-table': true, [`sc-table__${width}`]: true }"
	>
		<table ref="table">
			<thead>
			<tr class="sc-table__row">
				<th
					v-if="checkabled"
					:style="{ left: checkboxLeft, width: '48px' }"
					class="sc-table__cell sc-table__cell_sticky"
				>
					<ScCheckbox
						:modelValue="checkedAllRows"
						:indeterminate="checkedManyRows"
						@click.stop="checkedManyRows ? $emit('update:modelValue', []) : toggleAllRows()"
					/>
				</th>
				<th
					v-for="(column, index) in config"
					:key="column.title"
					:style="columnStyles[index]"
					:class="{ 'sc-table__cell': true, 'sc-table__cell_sticky': column.sticky }"
				>
					<ScText
						v-if="index === 0 && modelValue.length"
						color="mulberry-purple-90"
						size="12"
						weight="semibold"
					>
						{{ translate('SELECTEDCount').replace('{count}', modelValue.length.toString()) }}
					</ScText>
					<ScText
						v-else
						color="mulberry-purple-40"
						size="12"
						weight="semibold"
					>
						{{ column.title }}
					</ScText>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr
				v-for="row in rows"
				:key="row.id"
				class="sc-table__row sc-table__row_bordered"
				:data-test-id="row.id"
				:class="{ 'sc-table__row_checked': modelValue.some((el) => row.id === el.id) || row.new }"
				@click="$emit('row-click', row)"
			>
				<td
					v-if="checkabled"
					:style="{ left: checkboxLeft, width: '48px' }"
					class="sc-table__cell sc-table__cell_sticky"
				>
					<ScCheckbox
						:modelValue="modelValue.some((el) => row.id === el.id)"
						@click.stop="toggleRow(row)"
					/>
				</td>
				<td
					v-for="(column, index) in config"
					:key="column.title"
					:style="columnStyles[index]"
					:class="{ 'sc-table__cell': true, 'sc-table__cell_sticky': column.sticky }"
				>
					<div
						class="sc-table__cell-inner"
						:style="{'justify-content': column.align ?? 'left'}"
					>
						<slot
							v-bind="row"
							:name="column.name"
						/>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, onDeactivated, onMounted, PropType, reactive, ref } from 'vue';
	import { TableConfig } from './config';
	import { ScCheckbox } from '../checkbox';
	import { ScText } from '../text';
	import { translate } from '../../i18n';

	interface TableRow {
		id: number | string;
		new?: boolean;
	}

	/** ScTable component used to display user data as a table */
	export default defineComponent({
		name: 'ScTable',
		components: { ScCheckbox, ScText },
		props: {
			/** v-model */
			modelValue: {
				type: Array as PropType<Array<TableRow>>,
				default: () => ([] as Array<TableRow>),
			},
			/** Table columns config */
			config: {
				type: Array as PropType<Array<TableConfig>>,
				required: true,
				default: () => ([] as Array<TableConfig>),
			},
			/** Table rows */
			rows: {
				type: Array as PropType<Array<TableRow>>,
				required: true,
				default: () => ([] as Array<TableRow>),
			},
			/** Set checkbox */
			checkabled: {
				type: Boolean,
				default: true,
			},
			/** Width prop for using table inside horizontal layouts with sidebar(s) */
			width: {
				type: String as PropType<'full' | 'partial'>,
				default: () => 'full',
			},
		},
		setup(props, { emit }) {
			const resizeObserver = ref<ResizeObserver>();
			const table = ref<HTMLTableElement>();
			const tableRect = reactive({
				left: 0,
				right: 0,
				top: 0,
				scrollTop: 0,
				height: 0,
				width: 0,
			});

			const isPartialWidth = computed(() => props.width === 'partial');

			const columnStyles = computed(() => {
				let accumulatedWidth = props.checkabled ? 48 : 0;
				return props.config.map((column, index) => {
					const isLast = index === props.config.length - 1;
					const left = isPartialWidth.value ? '0' : !isLast && column.sticky ? (accumulatedWidth + tableRect.left) + 'px' : null;
					const right = isPartialWidth.value ? '0' : isLast && column.sticky ? tableRect.right + 'px' : null;
					const boxShadow = isLast
						? 'inset 1px 0 0 0 #efefef'
						: column.sticky && !props.config[index + 1].sticky
							? 'inset -1px 0 0 0 #efefef'
							: null;
					accumulatedWidth += column.width;
					return {
						left,
						right,
						boxShadow,
						width: column.width + 'px',
						textAlign: column.align
					};
				});
			});

			const checkedAnyRow = computed(() => props.modelValue.length > 0);

			const checkedAllRows = computed(() => {
				return props.rows.length > 0 && props.modelValue.length === props.rows.length;
			});

			const checkedManyRows = computed(() => checkedAnyRow.value && !checkedAllRows.value);

			const checkboxLeft = computed(() => isPartialWidth.value ? '0px' : `${tableRect.left}px`)

			const toggleRow = (row: TableRow) => {
				emit('update:modelValue', props.modelValue.some((el) => row.id === el.id)
					? props.modelValue.filter((el) => el.id !== row.id)
					: props.rows.filter((el) => el.id === row.id || props.modelValue.some((el2) => el.id === el2.id)),
				);
			};

			const toggleAllRows = () => {
				emit('update:modelValue', checkedAllRows.value ? [] : [...props.rows]);
			};

			const recalculateTablePosition = () => {
				if (!table.value) {
					return;
				}
				const { left, top, right, height, width } = table.value.getBoundingClientRect();
				tableRect.left = left + window.scrollX;
				tableRect.right = document.documentElement.scrollWidth - right - window.scrollX;
				tableRect.top = top + window.scrollY;
				tableRect.scrollTop = top;
				tableRect.height = height;
				tableRect.width = width;
			};

			onMounted(() => {
				recalculateTablePosition();
				resizeObserver.value = new ResizeObserver(recalculateTablePosition);
				resizeObserver.value.observe(table.value);
				window.addEventListener('scroll', recalculateTablePosition);
			});

			onDeactivated(() => {
				resizeObserver.value.disconnect();
				window.removeEventListener('scroll', recalculateTablePosition);
			});

			return {
				table,
				tableRect,
				columnStyles,
				checkedAllRows,
				checkedManyRows,
				isPartialWidth,
				checkboxLeft,
				toggleRow,
				toggleAllRows,
				translate,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-table {
		position: relative;
		margin: 0 auto;
		z-index: 10;

		&__full {
			&::before,
			&::after {
				content: '';
				background: @white;
				position: fixed;
				top: var(--table-scroll-top);
				height: var(--table-height);
				z-index: 20;
			}

			&::before {
				left: 0;
				width: var(--table-left);
			}

			&::after {
				right: 0;
				width: var(--table-right);
			}

			thead {
				top: var(--table-top);
			}
		}

		&__row {
			cursor: pointer;

			&_bordered {
				border-top: 1px solid @border-color;

				&:last-child {
					border-bottom: 1px solid @border-color;
				}

				&:hover {
					border-color: transparent;

					& + .sc-table__row_bordered {
						border-top-color: transparent;
					}

					.sc-table__cell {
						background: @mulberry-purple-0;
					}
				}

				&.sc-table__row_checked {
					border-top-color: transparent;

					& + .sc-table__row_bordered {
						border-top-color: transparent;
					}

					&:last-child {
						border-bottom-color: transparent;
					}
				}
			}

			&_checked td {
				background: @mulberry-purple-0;
			}
		}

		&__cell {
			background: @white;
			padding: 0 12px;
			text-align: left;
			box-sizing: border-box;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			z-index: 1;

			&.sc-table__cell_sticky {
				position: sticky;
				z-index: 2;
			}

			&-inner {
				display: flex;
				align-items: center;
				gap: 8px;
				width: 100%;
			}
		}
	}

	table {
		background: white;
		border: none;
		border-collapse: collapse;
		margin: 0 auto;
		padding: 0;
		table-layout: fixed;
		width: 100%;
	}

	thead {
		position: sticky;
		z-index: 3;

		.sc-table__row {
			cursor: default;
			box-shadow: inset 0 -1px 0 @border-color;
		}
	}

	th {
		height: 36px;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		vertical-align: middle;
	}

	td {
		height: 48px;
		vertical-align: middle;

		&:first-of-type {
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}

		&:last-of-type {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}
</style>
