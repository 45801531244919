<template>
	<div :class="{
		'sc-tab-list': true,
		 bordered,
	}">
		<!-- @slot Use for any tab components -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	/** The ScTabList component is used to display a list of badge components */
	export default defineComponent({
		name: 'ScTabList',
		props: {
			bordered: {
				type: Boolean,
				default: false,
			}
		}
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tab-list {
		display: flex;
		gap: 20px;
		padding: 0;
		width: 100%;

		&.bordered {
			box-shadow: 0 1px 0 @border-color;
			margin: 0 0 2px 0;
		}
	}
</style>
