
<ScButton
	v-if="$slots.default != null"
	:class="classes"
	v-bind="$attrs"
	@click="toggleDropdownAndEmitClick($event)"
>
	<!-- @slot Use for any text -->
	<slot/>
</ScButton>
<ScButton
	v-else
	:class="classes"
	v-bind="$attrs"
	@click="toggleDropdownAndEmitClick($event)"
>
</ScButton>
