
<label
	class="sc-checkbox"
	:class="{
		'sc-checkbox_checked': modelValue,
		'sc-checkbox_readonly': readonly,
		'sc-checkbox_disabled': disabled,
		'sc-checkbox_indeterminate': indeterminate,
	}"
>
	<input
		:checked="modelValue"
		:disabled="disabled"
		:data-test-id="null"
		type="checkbox"
		class="sc-checkbox__input"
		@change="$emit('update:modelValue', $event.target.checked)"
	/>
	<span :class="{ 'sc-checkbox__state': true, indeterminate }"/>
	<span
		v-if="$slots.default != null"
		class="sc-checkbox__label"
		@click.stop
	>
		<!-- @slot Use for any text -->
		<slot/>
	</span>
</label>
