<template>
	<div
		:class="classes"
		:tabindex="disabled ? null : 0"
	>
		<div
			v-if="canShowPlaceholder"
			class="sc-select-label__placeholder"
		>
			<span class="sc-select-label__placeholder-text">{{ placeholder }}</span>
		</div>
		<div
			v-else
			class="sc-select-label__content"
		>
			<slot v-if="$slots.default"/>
			<ScTooltip
				v-else
				active="overflowed"
			>
				<template #activator>
					<span class="sc-select-label__content-text">
						{{ selectedOption?.text }}
					</span>
				</template>
				<template #default>
					<span class="sc-select-label__content-text">
						{{ selectedOption?.text }}
					</span>
				</template>
			</ScTooltip>
		</div>
		<ScButton
			v-if="canClearSelectedOption"
			view="flat"
			icon="close"
			size="28"
			class="sc-select-label__clear-button"
			@click.stop="$emit('update:modelValue', null)"
			@keydown.enter.stop="$emit('update:modelValue', null)"
		/>
		<div class="sc-select-label__icon">
			<ScIcon
				name="chevron-down"
				size="16"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType } from 'vue';
	import { SelectOption } from './option';
	import ScButton from '../button/button.vue';
	import ScIcon from '../icon/icon.vue';
	import ScText from '../text/text.vue';
	import ScTooltip from '../tooltip/tooltip.vue';

	/** The ScSelectLabel component is used to display the selected option. */
	export default defineComponent({
		name: 'ScSelectLabel',
		components: { ScTooltip, ScButton, ScIcon, ScText },
		emits: [
			/** update value event */
			'update:modelValue',
		],
		props: {
			/** v-model value */
			modelValue: {
				type: [String, Number],
				default: null,
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36' | '44' | 'auto'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44', 'auto'].includes(value),
			},
			/** Set clearable */
			clearable: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set inline state */
			inline: {
				type: Boolean,
				default: false,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Option */
			selectedOption: {
				type: Object as PropType<SelectOption>,
				default: null,
			},
			/** isOpened state */
			isOpened: {
				type: Boolean,
				required: true,
				default: null,
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'flat' | 'plate' | 'flat-active' | 'plate-active' | 'tab' | 'plate-light'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'flat', 'plate', 'flat-active', 'plate-active', 'tab', 'plate-light'].includes(value),
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set highlight state */
			highlight: {
				type: Boolean,
				default: false,
			},
		},
		setup(props) {
			const classes = computed(() => ({
				'sc-select-label': true,
				[`sc-select-label_size-${props.size}`]: true,
				'sc-select-label_disabled': props.disabled,
				'sc-select-label_inline': props.inline,
				'sc-select-label_pressed': props.isOpened,
				[`sc-select-label_view-${props.view}`]: true,
				'sc-select-label_error': props.error,
				'sc-select-label_highlight': props.highlight,
			}));

			const canShowPlaceholder = computed(() => {
				return props.placeholder && props.selectedOption?.value == null;
			});

			const canClearSelectedOption = computed(() => {
				return props.clearable && !props.disabled && props.selectedOption?.value != null;
			});

			return {
				classes,
				canShowPlaceholder,
				canClearSelectedOption,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-select-label {
		cursor: pointer;
		display: flex;
		align-items: center;
		position: relative;
		border: 1px solid @mulberry-purple-20;
		border-radius: 6px;
		box-sizing: border-box;
		transition: all .2s;
		outline: none;

		&__placeholder {
			--text-color: @mulberry-purple-40;
			max-width: 100%;
		}

		&__placeholder-text {
			font-size: 14px;
			color: @mulberry-purple-40;
			transition: color 0.2s;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&__content {
			flex: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			--text-color: @mulberry-purple;
			--icon-color: @mulberry-purple-45;
			font-size: 14px;
			font-weight: 400;
			color: @mulberry-purple;
		}

		&__icon {
			display: flex;
			align-items: center;
			--icon-color: @mulberry-purple-new;
			position: absolute;
			top: 50%;
			height: 16px;
			right: 9px;
			transform: translateY(-50%);
			transition: all .2s;
		}

		&:hover {
			border-color: @mulberry-purple-35;

			.sc-select-label__placeholder {
				--text-color: @mulberry-purple-new;
			}

			.sc-select-label__placeholder-text {
				color: @mulberry-purple-new;
			}
		}

		&:active {
			.sc-select-label__placeholder {
				--text-color: @mulberry-purple-40;
			}

			.sc-select-label__placeholder-text {
				color: @mulberry-purple-40;
			}
		}

		&&_pressed {
			border-color: @mulberry-purple-new;

			.sc-select-label__placeholder {
				--text-color: @mulberry-purple-40;
			}

			.sc-select-label__placeholder-text {
				color: @mulberry-purple-40;
			}

			.sc-select-label__icon {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		&.sc-select-label_error {
			border: 1px solid @lightish-red;

			&:focus {
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #FEC5CC;
				border: 1px solid transparent;
			}
		}

		&.sc-select-label_disabled {
			cursor: default;
			pointer-events: none;
			border-color: @mulberry-purple-10;

			.sc-select-label__placeholder,
			.sc-select-label__content {
				--text-color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-35;
			}

			.sc-select-label__placeholder-text {
				color: @mulberry-purple-35;
			}

			.sc-select-label__icon {
				--icon-color: @mulberry-purple-30;
			}
		}

		&:focus {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);
			border-color: transparent;
		}

		&_inline {
			background: @mulberry-purple-10;
			border-color: @mulberry-purple-10;

			&:hover,
			&:active {
				background: @mulberry-purple-20;
				border-color: @mulberry-purple-20;
			}

			&.sc-select-label_disabled {
				background: @mulberry-purple-10;
			}
		}

		&_size-28 {
			height: 28px;
			padding: 4px 28px 4px 8px;
		}

		&_size-36 {
			height: 36px;
			padding: 8px 30px 8px 10px;

			.sc-select-label__icon {
				right: 10px;
			}

			&.sc-select_inline {
				padding-right: 24px;
			}
		}

		&_size-44 {
			height: 44px;
			padding: 12px 32px 12px 12px;

			.sc-select-label__icon {
				right: 12px;
			}
		}

		&.sc-select_error {
			border-color: @lightish-red;
		}

		&__clear-button {
			margin-left: 4px;
		}

		&_view-flat {
			background-color: transparent;
			border-color: transparent;

			.sc-select-label__placeholder,
			.sc-select-label__content {
				font-weight: 600;
				--text-color: @mulberry-purple-45;
				color: @mulberry-purple-45;
			}

			&:hover {
				background-color: @mulberry-purple-20;
				border-color: @mulberry-purple-20;
			}

			&.sc-select-label_pressed {
				background-color: @mulberry-purple-30;
				border-color: @mulberry-purple-30;
			}

			&.sc-select-label_disabled {
				background-color: transparent;
				border-color: transparent;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					--text-color: @mulberry-purple-40;
					color: @mulberry-purple-40;
				}
			}

			&.sc-select-label_error {
				background-color: @lightish-red-10;
				border-color: @lightish-red-10;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					--text-color: @lightish-red-70;
					color: @lightish-red-70;
				}

				.sc-select-label__icon {
					--icon-color: @lightish-red;
				}

				&:hover, &_pressed, &_pressed:hover {
					background-color: @lightish-red-20;
					border-color: @lightish-red-20;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @lightish-red-80;
						color: @lightish-red-80;
					}

					.sc-select-label__icon {
						--icon-color: @lightish-red-70;
					}
				}
			}

			&-active {
				background: transparent;
				border-color: transparent;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					font-weight: 600;
					--text-color: @fuchsia-blue-60;
					color: @fuchsia-blue-60;
				}

				.sc-select-label__icon {
					--icon-color: @fuchsia-blue;
				}

				&:hover {
					background: @fuchsia-blue-10;
					border-color: @fuchsia-blue-10;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @fuchsia-blue-70;
						color: @fuchsia-blue-70;
					}

					.sc-select-label__icon {
						--icon-color: @fuchsia-blue-60;
					}
				}

				&.sc-select-label_pressed {
					background-color: @fuchsia-blue-20;
					border-color: @fuchsia-blue-20;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @fuchsia-blue-70;
						color: @fuchsia-blue-70;
					}

					.sc-select-label__icon {
						--icon-color: @fuchsia-blue-60;
					}
				}

				&.sc-select-label_disabled {
					background-color: transparent;
					border-color: transparent;

					.sc-select-label__placeholder {
						--text-color: @mulberry-purple-40;
					}
				}

				&.sc-select-label_error {
					background: @lightish-red-10;
					border-color: @lightish-red-10;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @lightish-red-70;
						color: @lightish-red-70;
					}

					.sc-select-label__icon {
						--icon-color: @lightish-red;
					}

					&:hover, &_pressed, &_pressed:hover {
						background: @lightish-red-20;
						border-color: @lightish-red-20;

						.sc-select-label__placeholder,
						.sc-select-label__content {
							--text-color: @lightish-red-80;
							color: @lightish-red-80;
						}

						.sc-select-label__icon {
							--icon-color: @lightish-red-70;
						}
					}
				}
			}
		}

		&_view-plate {
			background-color: @mulberry-purple-20;
			border-color: @mulberry-purple-20;

			.sc-select-label__placeholder,
			.sc-select-label__content {
				font-weight: 600;
				--text-color: @mulberry-purple-45;
			}

			&:hover {
				background-color: @mulberry-purple-30;
				border-color: @mulberry-purple-30;
			}

			&.sc-select-label_pressed {
				background-color: @mulberry-purple-35;
				border-color: @mulberry-purple-35;
			}

			&.sc-select-label_disabled {
				background-color: @mulberry-purple-10;
				border-color: @mulberry-purple-10;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					--text-color: @mulberry-purple-40;
				}
			}

			&.sc-select-label_error {
				background-color: @lightish-red-10;
				border-color: @lightish-red-10;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					--text-color: @lightish-red-70;
				}

				.sc-select-label__icon {
					--icon-color: @lightish-red;
				}

				&:hover, &_pressed, &_pressed:hover {
					background-color: @lightish-red-20;
					border-color: @lightish-red-20;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @lightish-red-80;
					}

					.sc-select-label__icon {
						--icon-color: @lightish-red-70;
					}
				}
			}

			&-active {
				background-color: @fuchsia-blue-10;
				border-color: @fuchsia-blue-10;

				.sc-select-label__placeholder,
				.sc-select-label__content {
					font-weight: 600;
					--text-color: @fuchsia-blue-60;
					color: @fuchsia-blue-60;
				}

				.sc-select-label__icon {
					--icon-color: @fuchsia-blue;
				}

				&:hover {
					background-color: @fuchsia-blue-20;
					border-color: @fuchsia-blue-20;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @fuchsia-blue-70;
						color: @fuchsia-blue-70;
					}

					.sc-select-label__icon {
						--icon-color: @fuchsia-blue-60;
					}
				}

				&.sc-select-label_pressed {
					background-color: @fuchsia-blue-30;
					border-color: @fuchsia-blue-30;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @fuchsia-blue-70;
						color: @fuchsia-blue-70;
					}

					.sc-select-label__icon {
						--icon-color: @fuchsia-blue-60;
					}
				}

				&.sc-select-label_disabled {
					background-color: @mulberry-purple-10;
					border-color: @mulberry-purple-10;

					.sc-select-label__placeholder {
						--text-color: @mulberry-purple-40;
					}
				}

				&.sc-select-label_error {
					background-color: @lightish-red-10;
					border-color: @lightish-red-10;

					.sc-select-label__placeholder,
					.sc-select-label__content {
						--text-color: @lightish-red-70;
						color: @lightish-red-70;
					}

					.sc-select-label__icon {
						--icon-color: @lightish-red;
					}

					&:hover, &_pressed, &_pressed:hover {
						background-color: @lightish-red-20;
						border-color: @lightish-red-20;

						.sc-select-label__placeholder,
						.sc-select-label__content {
							--text-color: @lightish-red-80;
							color: @lightish-red-80;
						}

						.sc-select-label__icon {
							--icon-color: @lightish-red-70;
						}
					}
				}
			}
		}

		&_view-plate-light {
			background-color: @mulberry-purple-0;
			border-color: @mulberry-purple-0;

			.sc-select-label__placeholder {
				font-weight: 400;
			}

			.sc-select-label__content {
				font-weight: 400;
				--text-color: @mulberry-purple;
			}

			&:hover {
				background-color: @mulberry-purple-10;
				border-color: @mulberry-purple-10;

				.sc-select-label__placeholder {
					--text-color: @mulberry-purple-new;
				}
			}

			&.sc-select-label_pressed {
				background-color: @fuchsia-blue-0;
				border-color: transparent;
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);

				&.sc-select-label_error {
					border-color: transparent;
					box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #FEC5CC;
				}
			}

			&.sc-select-label_disabled {
				background-color: @mulberry-purple-0;
				border-color: @mulberry-purple-0;

				.sc-select-label__placeholder {
					--text-color: @mulberry-purple-35;
				}

				.sc-select-label__content {
					--text-color: @mulberry-purple-new;
				}
			}

			&.sc-select-label_error {
				background-color: @lightish-red-0;
				border-color: @lightish-red;

				.sc-select-label__placeholder {
					--text-color: @mulberry-purple-40;
				}

				.sc-select-label__content {
					--text-color: @mulberry-purple;
				}

				.sc-select-label__icon {
					--icon-color: @mulberry-purple-new;
				}

				&:hover {
					.sc-select-label__placeholder {
						--text-color: @mulberry-purple-new;
					}
				}

				&_pressed {
					.sc-select-label__placeholder {
						--text-color: @mulberry-purple-40;
					}
				}
			}

			&.sc-select-label_highlight {
				box-shadow: 0 0 0 4px rgba(98, 57, 198, 0.1);
			}
		}

		&_view-tab {
			height: auto;
			padding: 0;
			background: none;
			border: none;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				height: auto;
				padding: 0;
				background: none;
				border: none;
				box-shadow: none;
			}

			.sc-select-label__icon {
				display: none;
			}
		}

		&_size-auto {
			height: auto;
			padding: 11px 28px 11px 12px;

			.sc-select-label__icon {
				right: 12px;
				top: 22px;
			}
		}
	}
</style>
