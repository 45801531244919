
<div
	:class="classes"
	:tabindex="disabled ? null : 0"
>
	<div
		v-if="canShowPlaceholder"
		class="sc-datepicker-label__placeholder"
	>
		<ScText color="mulberry-purple-40">{{ placeholder }}</ScText>
	</div>
	<div
		v-else-if="slots.default"
		class="sc-datepicker-label__custom"
	>
		<slot name="default" v-bind="{startValue, endValue, currentFormat}"/>
	</div>
	<div
		v-else
		class="sc-datepicker-label__content"
	>
		<div
			ref="optionsWrapper"
			class="sc-datepicker-label__option-wrapper"
		>
			<ScBadge
				v-show="startValue"
				closing
				color="grey"
				size="small"
				@remove="removeValue"
				@click.stop
			>
				{{ currentFormat }}
			</ScBadge>
		</div>
	</div>
	<ScIcon
		v-if="view !== 'flat'"
		color="mulberry-purple-40"
		name="calendar"
		size="16"
	/>
</div>
