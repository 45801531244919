<template>
	<button
		:class="{
			'sc-button-smartwords': true,
			[`sc-button-smartwords_${view}`]: true,
			[`sc-button-smartwords_${size}`]: true,
			'sc-button-smartwords_activated': activated,
			'sc-button-smartwords_notification': notification,
		}"
		type="button"
		@click="$emit('click', $event)"
	>
		<span class="sc-button-smartwords__icon">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="16"
				fill="none"
			>
				<g clip-path="url(#a)">
					<circle
						cx="8"
						cy="8"
						r="8"
						:fill="view === 'default' ? 'url(#b)' : (view === 'active' ? '#231D33' : '#FB3048')"
					/>
					<path
						:stroke="view === 'default' ? '#FAEDFE' : (view === 'active' ? '#F9F8F9' : '#FFECEE')"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="1.2"
						d="M10 5.8A1.5 1.5 0 0 0 8.8 5H7.3a1.5 1.5 0 1 0 0 3h1.4a1.5 1.5 0 0 1 0 3H7.3a1.5 1.5 0 0 1-1.4-.8"
					/>
				</g>
				<defs>
					<linearGradient
						v-if="view === 'default'"
						id="b"
						x1="9.5"
						x2="9.5"
						y1="16"
						y2="0"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#8160D1"/>
						<stop
							offset="1"
							stop-color="#C010EF"
						/>
					</linearGradient>
					<clipPath id="a">
						<path
							fill="#fff"
							d="M0 0h16v16H0z"
						/>
					</clipPath>
				</defs>
			</svg>
		</span>
		<span
			v-if="$slots.default"
			class="sc-button-smartwords__text"
		>
			<!-- @slot Use for any text -->
			<slot name="default"/>
		</span>
	</button>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	/**
	 * The ScButtonSmartwords component is used to display the button with the main and additional action
	 */
	export default defineComponent({
		name: 'ScButtonSmartwords',
		emits: [
			/** Click event */
			'click',
		],
		props: {
			/** Set the visual design */
			view: {
				type: String as PropType<'default' | 'active' | 'alert'>,
				default: 'default',
				validator: (value: string) => ['default', 'active', 'alert'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'32' | '36'>,
				default: '32',
				validator: (value: string) => ['32', '36'].includes(value),
			},
			/** Set activated state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set notification state */
			notification: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button-smartwords {
		position: relative;
		width: fit-content;
		margin: 0;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		background: @white;
		border: 1px solid @mulberry-purple-20;
		border-radius: 60px;
		box-sizing: border-box;
		cursor: pointer;
		font-family: Inter, sans-serif;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		outline: none;
		white-space: nowrap;
		transition: background 100ms ease, color 100ms;
		transform: translate3d(0, 0, 0);

		&_notification:after {
			content: '';
			position: absolute;
			border: 4px solid @white;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @lightish-red;
			z-index: 3;
			right: -4px;
			top: -3px;
			box-sizing: content-box;
		}

		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:hover {
			background: @mulberry-purple-0;
		}

		&:active {
			background: @mulberry-purple-10;
		}

		&_activated {
			background: @mulberry-purple-10;
		}

		&:focus-visible {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);
			border-color: transparent;
		}

		&_32 {
			height: 32px;
			padding: 6px 12px;
		}

		&_36 {
			height: 36px;
			padding: 8px 12px;
		}

		&_default,
		&_active {
			color: @mulberry-purple-45;
		}

		&_alert {
			color: @lightish-red-60;
		}
	}
</style>
