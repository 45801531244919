
<div class="sc-multiselect-radio">
	<ScDropdownLine
		:activated="activated"
		:disabled="option.disabled"
		:clickabled="true"
		:data-text="option.text"
		@click="isChecked = !isChecked"
	>
		<ScCheckbox
			class="sc-multiselect__checkbox"
			:modelValue="isChecked"
			:disabled="option.disabled"
			color="black"
			readonly
		>
			<ScText class="sc-multiselect__line-text">{{ option.text }}</ScText>
		</ScCheckbox>
	</ScDropdownLine>
	<ScDropdownLine
		v-for="radio in option.value"
		:key="radio.value"
		:clickabled="true"
		:disabled="!isChecked"
		:data-value="radio.value"
		class="sc-multiselect-radio__line"
		@click="selectedRadio = radio.value"
	>
		<ScRadio
			:modelValue="selectedRadio"
			:value="radio.value"
			:disabled="!isChecked"
		>
			{{ radio.text }}
		</ScRadio>
	</ScDropdownLine>
</div>
