<template>
	<div
		:style="style"
		ref="skeleton"
		class="sc-skeleton"
	/>
</template>

<script lang="ts">
	import { computed, ref, onMounted, defineComponent } from 'vue';

	const animationDuration = 1500;

	/** The skeleton component is used to visually display the element while the data is being loaded. */
	export default defineComponent({
		name: 'ScSkeleton',
		props: {
			/** Set width */
			width: {
				type: String,
				default: '16px',
			},
			/** Set height */
			height: {
				type: String,
				default: '16px',
			},
		},
		setup(props) {
			const skeleton = ref();
			const animationDelay = ref(0);

			const style = computed(() => ({
				width: props.width,
				height: props.height,
				animationDelay: animationDelay.value + 'ms',
			}));

			onMounted(() => {
				const { x } = skeleton.value.getBoundingClientRect();
				animationDelay.value = Math.round(animationDuration * (x / window.innerWidth));
			});

			return { skeleton, style };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-skeleton {
		display: block;
		overflow: hidden;
		border-radius: 4px;
		background-color: @mulberry-purple-20;
		background-image: linear-gradient(90deg, @mulberry-purple-20 0, @mulberry-purple-0 60px, @mulberry-purple-20 120px);
		background-repeat: no-repeat;
		background-position: -120px 0;
		background-size: 120px 100%;
		animation: shine 1500ms steps(45) infinite;
		will-change: background-position;
	}

	@keyframes shine {
		to {
			background-position: calc(100vw + 120px) 0;
		}
	}
</style>
