<template>
	<ScDropdown
		:class="classes"
		:disabled="disabled"
		:overflow="false"
		:prefer="prefer"
		:alignWidth="true"
		@close="close()"
		@open="open()"
	>
		<template #activator="{ isOpened, toggleDropdown }">
			<div
				class="sc-select__activator"
				data-test-id="select-activator"
				@click="toggleDropdown()"
				@keydown.enter="toggleDropdown()"
			>
				<slot
					v-if="$slots.activator != null"
					name="activator"
					v-bind="{ isOpened }"
				/>
				<slot
					v-else-if="$slots.placeholder != null && !selectedOption"
					name="placeholder"
					v-bind="{ isOpened }"
				/>
				<ScSelectLabel
					v-else
					:clearable="clearable"
					:disabled="disabled"
					:inline="inline"
					:error="error"
					:isOpened="isOpened"
					:modelValue="modelValue"
					:placeholder="placeholder"
					:selectedOption="selectedOption"
					:size="size"
					:view="view"
					:highlight="highlight"
					@update:modelValue="$emit('update:modelValue', $event)"
				>
					<template
						v-if="hasCustomLabelTemplate"
						#default
					>
						<!-- @slot Use for custom label content -->
						<slot
							name="label"
							v-bind="{ selectedOption }"
						/>
					</template>
				</ScSelectLabel>
			</div>
		</template>
		<template #content="{ closeDropdown, isOpened }">
			<div
				:tabindex="search ? '' : '-1'"
				ref="content"
				class="sc-select__dropdown"
				@keydown.enter="setOption(); closeDropdown()"
				@keydown.down="setFocusNext"
				@keydown.up="setFocusPrev"
			>
				<ScInput
					v-if="search"
					:modelValue="searchText"
					@update:modelValue="searchText = $event"
					:placeholder="searchPlaceholder"
					:clearable="true"
					autofocus
					class="sc-select__search"
					data-test-id="select-search"
					icon="search"
					size="44"
				/>
				<div
					ref="optionsList"
					:class="{
						'sc-select__options': true,
						'shadow-top': search && scrollPosition > 0.01,
						'shadow-bottom': hasActionsTemplate && scrollPosition < 0.99,
					}"
					data-test-id="select-options"
					@scroll="updateScrollPosition"
				>
					<ScDropdownGroup
						v-for="(group, index) of visibleOptionGroups"
						:key="index"
						:title="group.title"
					>
						<ScDropdownLine
							v-for="option of group.options"
							:key="option.value"
							:activated="option.checked || option.value === focusOption"
							:clickabled="true"
							:closing="true"
							:disabled="option.disabled"
							:data-value="option.value"
							@click="selectOption(option)"
						>
							<!-- @slot Use for custom option content -->
							<slot
								v-if="hasCustomOptionTemplate"
								name="option"
								v-bind="option"
							/>
							<ScText
								class="sc-select__line-text"
								v-else
							>
								{{ option.text }}
							</ScText>
						</ScDropdownLine>
						<ScDropdownLine
							v-if="canCreateEntity && index === visibleOptionGroups.length - 1"
							:clickabled="true"
							:closing="true"
							data-test-id="select-creation"
							@click="createEntity()"
						>
							<!-- @slot Use for create entity -->
							<slot
								:text="trimmedSearchText"
								name="creation"
							/>
						</ScDropdownLine>
					</ScDropdownGroup>
				</div>
				<div
					v-if="hasActionsTemplate"
					class="sc-select__actions"
					data-test-id="select-actions"
				>
					<!-- @slot Use for any content -->
					<slot
						name="actions"
						v-bind="{ closeDropdown }"
					/>
				</div>
			</div>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
	import { SelectOption, SelectOptionGroup } from './option';
	import ScInput from '../input/input.vue';
	import ScText from '../text/text.vue';
	import ScDropdown from '../dropdown/dropdown.vue';
	import ScDropdownGroup from '../dropdown/dropdown-group.vue';
	import ScDropdownLine from '../dropdown/dropdown-line.vue';
	import ScSelectLabel from './label.vue';

	interface SelectOptionRendering extends SelectOption {
		checked: boolean;
	}

	interface SelectOptionGroupRendering {
		title: string;
		options: Array<SelectOptionRendering>;
	}

	/** The ScSelect component is interface element in the form of a drop-down list, as well as a list with one or multiple selection */
	export default defineComponent({
		name: 'ScSelect',
		components: {
			ScInput,
			ScText,
			ScDropdown,
			ScDropdownGroup,
			ScDropdownLine,
			ScSelectLabel,
		},
		emits: [
			/** Update value event */
			'update:modelValue',
			/** Open event */
			'open',
			/** Close event */
			'close',
			/** Create event */
			'create',
		],
		props: {
			/** v-model */
			modelValue: {
				type: [String, Number],
				default: null,
			},
			/** Options */
			options: {
				type: Array as PropType<Array<SelectOption | SelectOptionGroup>>,
				required: true,
				default: null,
			},
			/** Set option groups */
			groups: {
				type: Boolean,
				default: false,
			},
			/** Set clear icon */
			clearable: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set inline state */
			inline: {
				type: Boolean,
				default: false,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set optionsPlaceholder */
			optionsPlaceholder: {
				type: String,
				default: null,
			},
			/** Set searchPlaceholder */
			searchPlaceholder: {
				type: String,
				default: null,
			},
			/** Set search input */
			search: {
				type: Boolean,
				default: true,
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36' | '44' | 'auto'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44', 'auto'].includes(value),
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'flat' | 'plate' | 'flat-active' | 'plate-active' | 'tab' | 'plate-light'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'flat', 'plate', 'flat-active', 'plate-active', 'tab', 'plate-light'].includes(value),
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set preferred open direction */
			prefer: {
				type: String as PropType<'auto' | 'left'>,
				default: 'auto',
			},
			/** Set highlight state */
			highlight: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit, slots }) {
			const searchText = ref('');
			const optionsList = ref();
			const content = ref();
			const focusOption = ref(null);
			const scrollPosition = ref(0);

			const classes = computed(() => ({
				'sc-select': true,
				[`sc-select_view-${props.view}`]: true,
				'sc-select_inline': props.inline,
				'sc-select_error': props.error,
			}));

			const trimmedSearchText = computed(() => searchText.value?.trim() ?? '');

			const hasCustomLabelTemplate = computed(() => slots.label != null);

			const hasCustomOptionTemplate = computed(() => slots.option != null);

			const hasActionsTemplate = computed(() => slots.actions != null);

			const optionGroups = computed<Array<SelectOptionGroup>>(() => {
				return props.groups
					? props.options as Array<SelectOptionGroup>
					: [{
						title: props.optionsPlaceholder,
						options: props.options as Array<SelectOption>,
					}];
			});

			const allOptions = computed<Array<SelectOption>>(() => {
				return props.groups
					? (props.options as Array<SelectOptionGroup>).flatMap((group) => group.options)
					: props.options as Array<SelectOption>;
			});

			const selectedOption = computed(() => allOptions.value.find(option => option.value === props.modelValue));

			const filterOptionsBySearchText = (options: Array<SelectOption>) => {
				if (!trimmedSearchText.value) {
					return options;
				}
				const trimmedSearchTextLowerCased = trimmedSearchText.value.toLowerCase();
				return options.filter((option) => {
					return option.text.toLowerCase().includes(trimmedSearchTextLowerCased);
				});
			};

			const visibleOptionGroups = computed<Array<SelectOptionGroupRendering>>(() => {
				return optionGroups.value
					.map((group) => ({
						title: group.title,
						options: filterOptionsBySearchText(group.options).map((option) => ({
							...option,
							checked: props.modelValue === option.value,
						})),
					}))
					.filter((group, index) => {
						return group.options.length > 0 || (index === 0 && slots.creation != null);
					});
			});

			const allVisibleOptions = computed(() => visibleOptionGroups.value.flatMap(group => group.options));

			const canCreateEntity = computed(() => {
				if (slots.creation == null || trimmedSearchText.value.length < 2) {
					return false;
				}
				const trimmedSearchTextLowerCased = trimmedSearchText.value.toLowerCase();
				return optionGroups.value.some((group) => {
					return group.options.every((option) => option.text.toLowerCase() !== trimmedSearchTextLowerCased);
				});
			});

			const setOption = () => {
				emit('update:modelValue', focusOption.value);
			};

			const close = () => {
				emit('close');
			};

			const updateScrollPosition = () => {
				if (!optionsList.value) {
					return;
				}
				const { clientHeight, scrollHeight, scrollTop } = optionsList.value;
				scrollPosition.value = Math.round(scrollTop) / (scrollHeight - clientHeight);
			};

			const open = () => {
				searchText.value = '';

				setTimeout(() => {
					content.value.focus();
					updateScrollPosition();
					const activeElem = optionsList.value.querySelector('.sc-dropdown-line_activated');

					if (activeElem) {
						focusOption.value = selectedOption.value.value;
						activeElem.scrollIntoView({ block: 'center' });
					}
				}, 0);
				emit('open');
			};

			const selectOption = (option: SelectOptionRendering) => {
				emit('update:modelValue', option.value);
			};

			const createEntity = () => {
				emit('create', trimmedSearchText.value);
			};

			const setFocusNext = () => {
				if (!visibleOptionGroups.value[0] || !optionsList.value) return;

				const activeOption = allVisibleOptions.value.findIndex(option => option.value === focusOption.value && !option.disabled);

				const nextOption = allVisibleOptions.value.findIndex((option, idx) => idx > activeOption && !option.disabled);

				if (nextOption > -1) {
					focusOption.value = allVisibleOptions.value[nextOption].value;
				} else {
					const firstNonDisabledOption = allVisibleOptions.value.findIndex((option) => !option.disabled);
					if (firstNonDisabledOption > -1) {
						focusOption.value = allVisibleOptions.value[firstNonDisabledOption].value;
					}
				}
				scrollToActiveOption();
			};

			const setFocusPrev = () => {
				if (!visibleOptionGroups.value[0] || !optionsList.value) return;

				const activeOption = allVisibleOptions.value.findIndex(option => option.value === focusOption.value);

				let prevOption = -1;

				for (let i = allVisibleOptions.value.length - 1; i >= 0; i--) {
					if (i < activeOption && !allVisibleOptions.value[i].disabled) {
						prevOption = i;
						break;
					}
				}
				if (prevOption > -1) {
					focusOption.value = allVisibleOptions.value[prevOption].value;

				} else {
					let lastNonDisabledOption = -1;
					for (let i = allVisibleOptions.value.length - 1; i >= 0; i--) {
						if (!allVisibleOptions.value[i].disabled) {
							lastNonDisabledOption = i;
							break;
						}
					}
					focusOption.value = allVisibleOptions.value[lastNonDisabledOption].value;
				}
				scrollToActiveOption();
			};

			const scrollToActiveOption = () => {
				requestAnimationFrame(() => {
					const activeElem = optionsList.value ? optionsList.value.querySelector('.sc-dropdown-line_activated') : '';
					if (activeElem) {
						activeElem.scrollIntoView({ block: 'center', behavior: 'smooth' });
					}
				});
			};

			onMounted(() => {
				window.addEventListener('resize', updateScrollPosition);
			});

			onUnmounted(() => {
				window.removeEventListener('resize', updateScrollPosition);
			});

			return {
				content,
				setOption,
				optionsList,
				focusOption,
				setFocusNext,
				setFocusPrev,
				classes,
				searchText,
				trimmedSearchText,
				hasCustomLabelTemplate,
				hasCustomOptionTemplate,
				hasActionsTemplate,
				optionGroups,
				allOptions,
				selectedOption,
				visibleOptionGroups,
				canCreateEntity,
				scrollPosition,
				scroll,
				updateScrollPosition,
				open,
				close,
				selectOption,
				createEntity,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";
	@import "../../styles/scroll-bar";

	.sc-select,
	.sc-select__activator {
		width: 100%;

		&.sc-select_inline {
			width: auto;
		}
	}

	.sc-select__dropdown {
		height: 100%;
		max-height: 500px;
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		outline: none;
	}

	.sc-select__search {
		flex: none;
		margin-bottom: 10px;
	}

	.sc-select__options {
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
		outline: none;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		--icon-color: @mulberry-purple-45;

		&.shadow-top {
			border-top-color: @border-color;
		}

		&.shadow-bottom {
			border-bottom-color: @border-color;
		}

		.scroll-bar();
	}

	.sc-select__line-text {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.sc-select__actions {
		flex: none;
		margin-top: 10px;
		width: 100%;
	}
</style>
