
<div
	:class="{
		'sc-tooltip': true,
		[`sc-tooltip_${active}`]: true,
		'sc-tooltip--disabled': !$slots.default,
	}"
>
	<div
		ref="activator"
		class="sc-tooltip__activator"
		@touchstart="showTooltip"
		@touchend="hiddenTooltip"
		@mouseenter="showTooltip"
		@mouseleave="hiddenTooltip"
	>
		<!-- @slot use for the element that causes the tooltip to appear -->
		<slot name="activator"/>
	</div>
	<Teleport
		v-if="tooltipVisible && $slots.default"
		to="body"
	>
		<div
			ref="content"
			:class="`sc-tooltip__content sc-tooltip__content--${theme} sc-tooltip__content--${active}`"
			:style="{'max-width': width}"
		>
			<!-- @slot used for any content -->
			<slot name="default"/>
		</div>
	</Teleport>
</div>
