<template>
	<div
		:class="{
			'sc-tab-rounded-chevron': true,
			opened,
			disabled,
			selected,
		}"
		@click="onClick($event)"
	>
		<!-- @slot Use for any text -->
		<slot/>
		<ScIcon
			class="sc-tab-rounded-chevron__icon"
			name="chevron-down"
			size="16"
		/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import ScSelect from '../select/select.vue';

	/** The ScTabRoundedDropdown component is used to that organizes the content (makes it more compact) */
	export default defineComponent({
		name: 'ScTabRoundedChevron',
		emits: [
			/** Click event */
			'click',
		],
		components: { ScIcon, ScSelect },
		props: {
			/** Set selected state */
			selected: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set opened state */
			opened: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const onClick = (e: MouseEvent) => {
				(e.target as Element).scrollIntoView({ behavior: 'smooth' });
				/** If tab has not selected state stop event propagation */
				if (!props.selected) e.stopPropagation();
				emit('click', e);
			};
			return { onClick };
		}
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tab-rounded-chevron {
		padding: 4px 30px 4px 12px;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		background: @mulberry-purple-0;
		border-radius: 14px;
		color: @mulberry-purple-45;
		cursor: pointer;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		transition: background 200ms, color 200ms;
		white-space: nowrap;
		--icon-color: @mulberry-purple-new;

		&:hover {
			background: @mulberry-purple-20;
		}

		&:active {
			background: @mulberry-purple-35;
		}

		&.selected {
			background: @mulberry-purple-30;

			&:hover {
				background: @mulberry-purple-35;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;
			background: @mulberry-purple-0;
			color: @mulberry-purple-35;
			--icon-color: @mulberry-purple-30;

			&.selected {
				background: @mulberry-purple-10;
			}
		}

		&__icon {
			display: flex;
			align-items: center;
			--icon-color: @mulberry-purple-new;
			position: absolute;
			top: 50%;
			height: 16px;
			right: 9px;
			transform: translateY(-50%);
			transition: all .2s;
		}

		&.opened {
			.sc-tab-rounded-chevron__icon {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
</style>
