<template>
	<ScPopup
		class="sc-popup-confirmation"
		data-test-id="popup-confirmation"
		@cancel="$emit('cancel')"
		@confirm="$emit('confirm', $event)"
	>
		<div
			v-if="emoji"
			class="sc-popup-confirmation__emoji"
			data-test-id="popup-emoji"
		>
			{{ emoji }}
		</div>
		<ScPopupHeader>{{ title }}</ScPopupHeader>
		<ScText
			v-html="text"
			size="16"
			class="sc-popup-confirmation__content"
			data-test-id="popup-content"
		/>
		<ScPopupActions align="center">
			<ScButton
				size="36"
				view="simple"
				data-test-id="popup-cancel"
				@click="$emit('cancel')"
			>
				{{ cancelButton }}
			</ScButton>
			<ScButton
				v-focus
				size="36"
				view="cta-black"
				data-test-id="popup-confirm"
				@click="$emit('confirm', true)"
			>
				{{ confirmButton }}
			</ScButton>
		</ScPopupActions>
	</ScPopup>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import { ScButton } from '../button';
	import { ScH1, ScText } from '../text';
	import ScPopup from './popup.vue';
	import ScPopupHeader from './header.vue';
	import ScPopupActions from './actions.vue';
	import { default as Focus } from '../../directives/focus';

	/** The ScPopupConfirm component is an interface element used to confirm user action **/
	export default defineComponent({
		name: 'ScPopupConfirm',
		components: {
			ScButton,
			ScH1,
			ScText,
			ScPopup,
			ScPopupHeader,
			ScPopupActions,
		},
		directives: {
			Focus,
		},
		props: {
			emoji: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				required: true,
				default: '',
			},
			text: {
				type: String,
				default: '',
			},
			cancelButton: {
				type: String,
				required: true,
				default: '',
			},
			confirmButton: {
				type: String,
				required: true,
				default: '',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-popup-confirmation {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		width: 400px;

		&__emoji {
			display: flex;
			justify-content: center;
			font-size: 64px;
			margin-bottom: 30px;
		}
	}
</style>
