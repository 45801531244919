<template>
	<div class="sc-legend">
		<ScH3>{{ caption }}<span v-if="required" class="sc-legend__required">*</span></ScH3>
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScH3 from '../text/h3.vue';

	export default defineComponent({
		name: 'ScLegend',
		components: {
			ScH3,
		},
		props: {
			caption: {
				type: String,
				required: true,
				default: null,
			},
			required: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-legend {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;

		&__required {
			color: @lightish-red;
		}
	}
</style>
