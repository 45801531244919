
<div
	ref="badge"
	:style="{ maxWidth: maxWidth + 'px' }"
	class="sc-input-emails-option"
>
	<input
		ref="input"
		v-if="isEditMode"
		v-model="editValue"
		class="sc-input-emails-option__badge-change"
		@blur="setNewValue()"
		@keydown.enter.space="setNewValue()"
	/>
	<ScBadge
		v-else
		class="sc-input-emails-option__badge"
		:color="isValid ? 'grey' : 'red'"
		closing
		@remove="$emit('remove', $event)"
		@click="toEditMode()"
	>
		{{ modelValue }}
	</ScBadge>
</div>
