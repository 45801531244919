
<div
	ref="root"
	:class="classes"
>
	<!-- @slot Used for any element, clicking on which will cause a dropdown to appear -->
	<slot
		name="activator"
		v-bind="{ isOpened, openDropdown, toggleDropdown }"
	/>
	<div
		v-if="isOpened"
		class="sc-dropdown__cover"
		@click="toggleDropdown"
	/>
	<ScTransitionViaTeleport
		:condition="isOpened"
		name="opacity"
		@before-enter="keepPosition"
	>
		<div
			v-show="slots.content"
			ref="dropdown"
			:data-test-id="($attrs['data-test-id'] || 'dropdown') + '-container'"
			class="sc-dropdown__container"
		>
			<div
				ref="content"
				:class="{ 
					'sc-dropdown__content_overflowed': overflow, 
					'sc-dropdown__content_disabled-scroll': !isTopLayer 
				}"
				class="sc-dropdown__content"
			>
				<!-- @slot Used for any content -->
				<slot
					name="content"
					v-bind="{ isOpened, closeDropdown }"
				/>
			</div>
		</div>
	</ScTransitionViaTeleport>
</div>
