<template>
	<div :class="{
			'sc-banner': true,
			[`sc-banner_${view}`]: true,
			[`sc-banner_${padding}`]: padding,
		}"
	>
		<div class="sc-banner__header">
			<ScIcon
				v-if="icon && !emoji"
				:name="icon"
				size="14"
				class="sc-banner__header-icon"
			/>
			<span
				v-else-if="emoji"
				class="sc-banner__header-emoji"
			>
				{{ emoji }}
			</span>
			<ScText
				:size="padding === '20' ? '16' : '14'"
				weight="bold"
				class="sc-banner__header-title"
			>
				<!-- @slot Use for any text -->
				<slot name="header"/>
			</ScText>
			<div
				v-if="$slots.help && padding === '20'"
				class="sc-banner__header-help"
			>
				<slot name="help"/>
			</div>
			<ScButton
				v-if="closable"
				view="flat"
				icon="close"
				class="sc-banner__header__close"
				data-test-id="tip-close"
				@click="$emit('cancel')"
			/>
		</div>
		<div class="sc-banner__content">
			<!-- @slot Use for any text -->
			<slot name="default"/>
		</div>
		<div
			v-if="$slots.actions"
			class="sc-banner__actions"
		>
			<slot name="actions"/>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import { ScButton } from '../button';
	import ScText from '../text/text.vue';
	import { IconNames } from '../icon';

	/**	The ScBanner component is used to display information to the user */
	export default defineComponent({
		name: 'ScBanner',
		components: { ScText, ScIcon, ScButton },
		props: {
			/** Set view */
			view: {
				type: String as PropType<'grey' | 'purple' | 'yellow' | 'red' | 'mint' | 'gradient' | 'white'>,
				default: 'grey',
				validator: (value: string) => ['grey', 'purple', 'yellow', 'red', 'mint', 'gradient', 'white'].includes(value),
			},
			/** Set padding size */
			padding: {
				type: String as PropType<'16' | '20'>,
				default: '20',
				validator: (value: string) => ['16', '20'].includes(value),
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set the emoji state */
			emoji: {
				type: String as PropType<string>,
				default: null,
			},
			/** Set closable */
			closable: {
				type: Boolean,
				default: true,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-banner {
		display: block;
		border: none;
		border-radius: 6px;

		&_16 {
			padding: 6px 6px 16px 16px;

			.sc-banner__header {
				margin-bottom: 6px;
				gap: 6px;
				padding-top: 8px;
			}

			.sc-banner__actions {
				margin-top: 12px;
			}
		}

		&_20 {
			padding: 20px 20px 30px 30px;

			.sc-banner__header {
				margin-bottom: 10px;
				gap: 8px;
			}

			.sc-banner__actions {
				margin-top: 20px;
			}
		}

		&__header {
			position: relative;
			padding-right: 38px;
			display: flex;
			height: 28px;
			align-items: center;
			justify-content: space-between;

			&-title {
				flex: 1;
			}

			&-help {
				display: flex;
				align-items: center;
				height: 100%;
				gap: 4px;
			}

			&__close {
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			padding-right: 10px;
			--text-color: @mulberry-purple;
		}

		&__actions {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		&_grey {
			background: @mulberry-purple-10;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
			--background-color: @mulberry-purple-10;
		}

		&_purple {
			background: @fuchsia-blue-0;
			color: @fuchsia-blue-90;
			--text-color: @fuchsia-blue-90;
			--icon-color: @fuchsia-blue-80;
			--background-color: @fuchsia-blue-0;
		}

		&_yellow {
			background: @radiation-carrot-0;
			color: @radiation-carrot-90;
			--text-color: @radiation-carrot-90;
			--icon-color: @radiation-carrot-80;
			--background-color: @radiation-carrot-0;
		}

		&_red {
			background: @lightish-red-0;
			color: @lightish-red-90;
			--text-color: @lightish-red-90;
			--icon-color: @lightish-red-80;
			--background-color: @lightish-red-0;
		}

		&_mint {
			background: @breathtaking-mint-0;
			color: @breathtaking-mint-90;
			--text-color: @breathtaking-mint-90;
			--icon-color: @breathtaking-mint-80;
			--background-color: @breathtaking-mint-0;
		}

		&_white {
			background: inherit;
			border: 1px solid @mulberry-purple-20;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
		}

		&_gradient {
			background: radial-gradient(163.91% 426.44% at 5.44% -114.75%, rgba(50, 138, 245, 0.2) 0%, rgba(50, 138, 245, 0) 100%), radial-gradient(115.56% 234.75% at 154.06% 243.5%, rgba(98, 57, 198, 0.2) 0%, rgba(98, 57, 198, 0) 100%), radial-gradient(127.72% 301.5% at 2.28% 307.75%, rgba(192, 16, 239, 0.1) 0%, rgba(192, 16, 239, 0) 100%), #FFFFFF;;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
			--background-color: @denim-0;
		}
	}
</style>
