<template>
	<div :class="{
		'sc-tip': true,
		[`sc-tip_${view}`]: true,
		[`sc-tip_${padding}`]: padding,
	}">
		<!-- @slot Use for any content -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	/**	The ScTip component is used to display information to the user */
	export default defineComponent({
		name: 'ScTip',
		props: {
			/** Set view */
			view: {
				type: String as PropType<'grey' | 'purple' | 'yellow' | 'red' | 'mint' | 'gradient' | 'white'>,
				default: 'grey',
				validator: (value: string) => ['grey', 'purple', 'yellow', 'red', 'mint', 'gradient', 'white'].includes(value),
			},
			/** Set padding size */
			padding: {
				type: String as PropType<'10' | '20'>,
				default: '20',
				validator: (value: string) => ['10', '20'].includes(value),
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-tip {
		display: block;
		border: none;
		border-radius: 6px;

		&_10 {
			padding: 10px 12px;
		}

		&_20 {
			padding: 20px 20px 30px 30px;
		}

		&_grey {
			background: @mulberry-purple-10;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
		}

		&_purple {
			background: @fuchsia-blue-0;
			color: @fuchsia-blue-90;
			--text-color: @fuchsia-blue-90;
			--icon-color: @fuchsia-blue-80;
		}

		&_yellow {
			background: @radiation-carrot-0;
			color: @radiation-carrot-90;
			--text-color: @radiation-carrot-90;
			--icon-color: @radiation-carrot-80;
		}

		&_red {
			background: @lightish-red-0;
			color: @lightish-red-90;
			--text-color: @lightish-red-90;
			--icon-color: @lightish-red-80;
		}

		&_mint {
			background: @breathtaking-mint-0;
			color: @breathtaking-mint-90;
			--text-color: @breathtaking-mint-90;
			--icon-color: @breathtaking-mint-80;
		}

		&_white {
			background: inherit;
			border: 1px solid @mulberry-purple-20;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
		}

		&_gradient {
			background: radial-gradient(163.91% 426.44% at 5.44% -114.75%, rgba(50, 138, 245, 0.2) 0%, rgba(50, 138, 245, 0) 100%), radial-gradient(115.56% 234.75% at 154.06% 243.5%, rgba(98, 57, 198, 0.2) 0%, rgba(98, 57, 198, 0) 100%), radial-gradient(127.72% 301.5% at 2.28% 307.75%, rgba(192, 16, 239, 0.1) 0%, rgba(192, 16, 239, 0) 100%), #FFFFFF;;
			color: @mulberry-purple-90;
			--text-color: @mulberry-purple-90;
			--icon-color: @mulberry-purple-80;
		}
	}
</style>
