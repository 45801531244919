
<div
	:class="{
		'sc-tab': true,
		[`sc-tab_${size}`]: true,
		'selected': selected,
		'disabled': disabled,
	}"
	@click="$emit('click', $event)"
>
	<!-- @slot Use for any text -->
	<slot name="default"/>

	<ScBadge
		v-if="view === 'badge'"
		:notification="!visited && notification"
		color="grey"
		class="sc-tab__badge"
	>
		<!-- @slot Use for badge content -->
		<slot name="badge"/>
	</ScBadge>
	<div
		v-if="view === 'counter' && !visited"
		class="sc-tab__counter"
	>
		{{ count }}
	</div>
</div>
