<template>
	<button
		:class="{
			'sc-button-multi': true,
			[`sc-button-multi_${size}`]: true,
			[`sc-button-multi_${view}`]: true,
			'sc-button-multi_icon': icon && !$slots.default,
			'sc-button-multi_activated': activated,
			'sc-button-multi_response': !icon && response,
		}"
		type="button"
		:disabled="disabled || response || actionsResponse"
	>
		<span
			@click="$emit('click', $event)"
			@mousedown="$emit('mousedown', $event)"
			class="sc-button-multi__button"
		>
			<ScLoaderSpin
				v-if="response"
				:size="size === '28' ? '16' : '20'"
				class="sc-button-multi__loader"
			/>
			<span
				v-else-if="icon"
				class="sc-button-multi__icon"
			>
				<ScIcon
					:name="icon"
					:size="size === '28' ? '16' : '20'"
				/>
			</span>
			<span
				v-if="$slots.default"
				class="sc-button-multi__text"
			>
				<!-- @slot Use for any text -->
				<slot name="default"/>
			</span>
		</span>
		<span
			class="sc-button-multi__actions"
			@click.stop="$emit('actions-click', $event)"
		>
			<span class="sc-button-multi__actions-content">
				<ScLoaderSpin
					v-if="actionsResponse"
					size="16"
				/>
				<ScIcon
					v-else
					:name="actionsIcon"
					size="16"
					class="sc-button-multi__actions-icon"
				/>
			</span>
		</span>
	</button>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ScButton } from './index';
	import { IconNames, ScIcon } from '../icon';
	import { ScLoaderSpin } from '../loader';

	/**
	 * The ScButtonMulti component is used to display the button with the main and additional action
	 */
	export default defineComponent({
		name: 'ScButtonMulti',
		emits: [
			/** Click event */
			'click',
			/** Actions-click event */
			'actions-click',
			/** Mousedown event */
			'mousedown',
		],
		components: { ScButton, ScLoaderSpin, ScIcon },
		props: {
			/** Set the visual design */
			view: {
				type: String as PropType<'cta-black' | 'simple'>,
				default: 'cta-black',
				validator: (value: string) => ['cta-black', 'simple'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36'>,
				default: '28',
				validator: (value: string) => ['28', '36'].includes(value),
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set the actionsIcon */
			actionsIcon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set activated state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set response state */
			response: {
				type: Boolean,
				default: false,
			},
			/** Set actionsResponse state */
			actionsResponse: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button-multi {
		width: fit-content;
		margin: 0;
		padding: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: none;
		box-sizing: border-box;
		cursor: pointer;
		font-family: Inter, sans-serif;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		outline: none;
		white-space: nowrap;
		transition: background 100ms ease, color 100ms;
		transform: translate3d(0, 0, 0);
		overflow: hidden;

		&__button {
			position: relative;
			display: flex;
			align-items: center;
			padding-right: 10px;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			gap: 8px;

			&:after {
				content: '';
				width: 1px;
				position: absolute;
				right: 0;
			}
		}

		&__icon {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__actions {
			padding: 4px;
			border-bottom-right-radius: 6px;
			border-top-right-radius: 6px;

			&-content {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
			}
		}

		&__text {
			padding-right: 10px;
		}

		&_response {
			.sc-button-multi__text {
				visibility: hidden;
			}

			.sc-button-multi__loader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&_28 {
			height: 28px;
			border-radius: 4px;

			&.sc-button-multi_icon {
				.sc-button-multi__button {
					padding: 6px;
				}

				.sc-button-multi__icon {
					margin: 0;
				}
			}

			.sc-button-multi__button {
				padding: 4px 0 4px 10px;
				gap: 6px;

				&:after {
					top: 4px;
					height: calc(100% - 8px);
				}
			}

			.sc-button-multi__actions-content {
				width: 20px;
				height: 20px;
			}
		}

		&_36 {
			height: 36px;
			border-radius: 6px;

			&.sc-button-multi_icon {
				.sc-button-multi__button {
					padding: 8px;
				}

				.sc-button-multi__icon {
					margin: 0;
				}
			}

			.sc-button-multi__button {
				padding: 8px 0 8px 16px;

				&:after {
					top: 8px;
					height: calc(100% - 16px);
				}
			}

			.sc-button-multi__actions-content {
				width: 28px;
				height: 28px;
			}
		}

		&:focus-visible {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);

			&.sc-button-multi_simple {
				border-color: transparent;
			}
		}

		&:disabled {
			cursor: default;
			pointer-events: none;
		}

		&_cta-black {
			background: @mulberry-purple-90;
			color: @white;
			--loader-color: @white;
			--icon-color: @mulberry-purple-0;

			.sc-button-multi__actions-content {
				&:hover {
					background: @mulberry-purple-70;
				}

				&:active {
					background: @mulberry-purple-100;
				}
			}

			.sc-button-multi__button {
				&:after {
					box-shadow: 1px 0 @mulberry-purple;
				}

				&:hover {
					background: @mulberry-purple-70;

					& + .sc-button-multi__actions {
						background: @mulberry-purple-70;
					}
				}

				&:active {
					background: @mulberry-purple-100;

					& + .sc-button-multi__actions {
						background: @mulberry-purple-100;
					}
				}
			}

			&.sc-button-multi_activated {
				background: @mulberry-purple-70;
			}

			&:disabled {
				background: @mulberry-purple-40;
				color: @mulberry-purple-10;
				--icon-color: @mulberry-purple-10;

				.sc-button-multi__button {
					&:after {
						box-shadow: 1px 0 @mulberry-purple-35;
					}
				}
			}
		}

		&_simple {
			background: transparent;
			border: 1px solid @mulberry-purple-20;
			color: @mulberry-purple;
			--icon-color: @mulberry-purple-45;
			--loader-color: @mulberry-purple;

			&.sc-button-multi_activated {
				background: @mulberry-purple-20;
				border-color: @mulberry-purple-30;
			}

			.sc-button-multi__actions-content {
				&:hover {
					background: @mulberry-purple-20;
				}

				&:active {
					background: @mulberry-purple-30;
				}
			}

			.sc-button-multi__button {
				&:after {
					box-shadow: 1px 0 @mulberry-purple-10;
				}

				&:hover {
					background: @mulberry-purple-20;

					& + .sc-button-multi__actions {
						background: @mulberry-purple-20;
					}

					&:after {
						box-shadow: 1px 0 @mulberry-purple-30;
					}
				}

				&:active {
					background: @mulberry-purple-30;

					& + .sc-button-multi__actions {
						background: @mulberry-purple-30;
					}

					&:after {
						box-shadow: 1px 0 @mulberry-purple-35;
					}
				}
			}

			&:hover {
				border-color: @mulberry-purple-30;
			}

			&:disabled {
				border-color: @mulberry-purple-10;
				color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-30;
			}
		}
	}
</style>
