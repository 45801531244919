<template>
	<ScText
		:weight="weight"
		:color="color"
		size="20"
		tag="h2"
	>
		<!-- @slot Use for any text -->
		<slot/>
	</ScText>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ColorNames } from '../../types/colors';
	import ScText from './text.vue';

	/** Renders html tag h2 with specified font attributes and text color */
	export default defineComponent({
		name: 'ScH2',
		components: { ScText },
		props: {
			/** Set font weight */
			weight: {
				type: String as PropType<'regular' | 'semibold' | 'bold'>,
				default: 'bold',
				validator: (value: string) => ['regular', 'semibold', 'bold'].includes(value),
			},
			/** Set text color */
			color: {
				type: String as PropType<ColorNames>,
				default: 'mulberry-purple-90',
			},
		},
	});
</script>
