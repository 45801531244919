<template>
	<div
		class="sc-notification-global"
		@click="tryIfCTAWasClicked"
	>
		<div
			class="sc-notification-global__content"
			v-html="message"
		/>
		<button
			v-if="dismissable"
			class="sc-notification-global__close"
			@click.stop="$emit('close', $event)"
		>
			<ScIcon
				name="close"
				size="16"
				color="mulberry-purple-10"
			/>
		</button>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScIcon from '../icon/icon.vue';

	export default defineComponent({
		name: 'ScNotificationGlobal',
		components: { ScIcon },
		props: {
			message: {
				type: String,
				required: true,
				default: '',
			},
			dismissable: {
				type: Boolean,
				default: true,
			},
		},
		setup(_, { emit }) {
			const tryIfCTAWasClicked = (e: Event) => {
				const clickedElement = e.target as HTMLElement;
				if (['a', 'button'].includes(clickedElement.tagName.toLowerCase())) {
					emit('cta', clickedElement.id);
				}
			};

			return {
				tryIfCTAWasClicked,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";

	.sc-notification-global {
		display: block;
		position: relative;
		width: 100%;
		background: @fuchsia-to-pink-60-horizontal;

		&__content {
			box-sizing: border-box;
			padding: 8px 10%;
			max-width: 1280px;
			position: relative;
			min-height: 40px;
			color: @white;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
			margin: 0 auto;

			.screen-lt-768({
				padding: 8px 40px 8px 8px;
			});

			/deep/ button {
				background: @white;
				border-radius: 4px;
				margin: 0 2px;
				padding: 4px 10px;
				cursor: pointer;
				height: 28px;
				font-weight: 600;
				font-size: 14px;
				line-height: 22px;
				color: @mulberry-purple;
				border: none;
				transition: background 0.1s ease, color 0.1s;

				&:hover {
					background: @mulberry-purple-100;
					color: @white;
				}

				&:active {
					background: @mulberry-purple-70;
				}
			}

			/deep/ a,
			/deep/ a:visited {
				font-weight: 700;
				font-size: 16px;
				cursor: pointer;
				line-height: 1.5;
				color: @white;
				text-decoration: underline;

				&:hover {
					color: @mulberry-purple-35;
				}

				&:active {
					color: @mulberry-purple-40;
				}
			}
		}

		&__close {
			position: absolute;
			top: calc(50% - 14px);
			right: 6px;
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			border: none;
			border-radius: 4px;
			cursor: pointer;
			transition: background 100ms ease;

			&:hover {
				background: rgba(255, 255, 255, 0.15);
			}

			&:active {
				background: rgba(255, 255, 255, 0.3);
			}
		}
	}
</style>
