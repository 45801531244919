
<div
	:class="classes"
	:style="{ width }"
	:tabindex="disabled ? null : 0"
>
	<div
		v-if="canShowPlaceholder"
		class="sc-multiselect-label__placeholder"
	>
		<ScText
			color="mulberry-purple-40"
			class="sc-multiselect-label__placeholder-text"
		>
			{{ placeholder }}
		</ScText>
	</div>
	<div
		v-else-if="$slots.default"
		class="sc-multiselect-label__custom"
	>
		<slot name="default" v-bind="{selectedOptions}"/>
	</div>
	<div
		v-else
		class="sc-multiselect-label__content"
	>
		<div
			ref="optionsWrapper"
			class="sc-multiselect-label__option-wrapper"
		>
			<ScBadge
				v-for="option in selectedOptions"
				:key="option.value"
				:closing="!option.disabled"
				color="grey"
				class="sc-multiselect-label__option"
				@remove="removeSelectedOption(option)"
			>
				{{ option.text }}
			</ScBadge>
		</div>
	</div>
	<ScTooltip
		v-if="canShowTooltip"
		padding="8"
	>
		<template #activator>
			<div class="sc-default-multiselect-label__counter">
				<ScText
					size="12"
					weight="semibold"
				>
					{{ selectedOptions.length }}
				</ScText>
				<ScIcon
					class="sc-multiselect-label__clear"
					name="circle-cross"
					size="16"
					@click.stop="clearAllSelectedOptions()"
				/>
			</div>
		</template>
		{{ selectedOptionsText }}
	</ScTooltip>
	<div class="sc-multiselect-label__icon">
		<ScIcon
			name="chevron-down"
			size="16"
		/>
	</div>
</div>
