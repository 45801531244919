import { ref } from 'vue';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import ptBR from './pt-BR.json';
import ru from './ru.json';
import tr from './tr.json';
import zhHans from './zh-Hans.json';

export const cultureName = ref('en');

const i18n: Record<string, Record<string, string>> = {
	de,
	en,
	es,
	fr,
	it,
	ja,
	'pt-BR': ptBR,
	ru,
	tr,
	'zh-Hans': zhHans,
};

export function translate(key: string) {
	return i18n[cultureName.value][key] ?? i18n['en'][key];
}