<template>
	<label
		class="sc-switch"
		:class="{
			'sc-switch_checked': modelValue,
			'sc-switch_readonly': readonly,
			'sc-switch_disabled': disabled,
		}"
	>
		<input
			:checked="modelValue"
			:disabled="disabled"
			:data-test-id="null"
			type="checkbox"
			class="sc-switch__checkbox"
			@change="$emit('update:modelValue', $event.target.checked)"
		/>
		<span class="sc-switch__label">
			<span class="sc-switch__inner" />
			<span class="sc-switch__switch" />
		</span>
		<span
			v-if="slots.default"
			class="sc-switch__label--right"
		>
			<!-- @slot Use for any text -->
			<slot />
		</span>
	</label>
</template>

<script lang="ts">
	import { defineComponent, useSlots } from 'vue';

	/** Switch component as using to toggle between two states. */
	export default defineComponent({
		name: 'ScSwitch',
		emits: [
			/** Updating the v-model */
			'update:modelValue',
		],
		props: {
			/** Set selected state */
			modelValue: {
				type: Boolean,
				default: false,
			},
			/** Set readonly state */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		setup() {
			const slots = useSlots();
			return {
				slots,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-switch {
		display: flex;
		align-items: center;
		gap: 8px;
		position: relative;
		width: auto;

		&__checkbox {
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			z-index: 1;
			font-size: 10px;
			border: none;
			outline: none;
			cursor: pointer;
			opacity: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		&__label {
			display: block;
			overflow: hidden;
			cursor: pointer;
			width: 30px;
			position: relative;

			&--right {
				cursor: pointer;
				user-select: none;
				white-space: nowrap;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
		}

		&__inner {
			display: block;
			width: 200%;
			margin-left: -100%;
			border-radius: 20px;

			&:before {
				content: '';
				padding-left: 15px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				border-radius: 20px;
			}

			&:after {
				content: '';
				padding-right: 15px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				border-radius: 20px;
			}
		}

		&__inner {
			&:before {
				background-color: @mulberry-purple-90;
			}

			&:after {
				background-color: @mulberry-purple-35;
			}
		}

		&__switch {
			display: block;
			padding: 7px;
			margin: 2px;
			background: white;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 12px;
			border-radius: 50%;
			transition: all 200ms ease-in-out;
		}

		&__inner:before,
		&__inner:after {
			display: block;
			float: left;
			width: 50%;
			height: 18px;
			box-sizing: border-box;
		}

		&_checked {
			.sc-switch__inner {
				margin-left: 0;
			}

			.sc-switch__switch {
				right: 0;
				padding: 7px;
			}
		}

		&:hover {
			.sc-switch__inner::before {
				background-color: @mulberry-purple-70;
			}

			.sc-switch__inner::after {
				background-color: @mulberry-purple-40;
			}
		}

		&_readonly {
			pointer-events: none;
		}

		&_disabled {
			pointer-events: none;
			opacity: 0.5;

			.sc-switch__inner:after {
				color: @mulberry-purple-30;
			}

			&.sc-switch_checked .sc-switch__inner:before {
				color: @mulberry-purple-35;
			}

			.sc-switch__label {
				color: @mulberry-purple-40;
			}
		}
	}
</style>
