<template>
	<ScProgress
		:percent="percent"
		background-color="fuchsia-blue-0"
		bar-color="gradient"
		:shine="shine"
	/>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';
	import ScProgress from './progress.vue';

	/**
	 * The ScProgressGradient element of the graphical user interface made in a gradient color,
	 * which is a panel for displaying indexing a move or doing some task
	 */
	export default defineComponent({
		name: 'ScProgressGradient',
		components: {
			ScProgress,
		},
		props: {
			/** Set the fill percentage */
			percent: {
				type: Number,
				default: 0,
			},
			/** Set the visual display of the boot */
			shine: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>
