<template>
	<div
		:class="{
			'sc-dropdown-line': true,
			[`sc-dropdown-line_${align}`]: true,
			'sc-dropdown-line_activated': activated,
			'sc-dropdown-line_clickabled': clickabled && !disabled,
			'sc-dropdown-line_disabled': disabled,
		}"
		@click="closeDropdownAndEmitClick($event)"
	>
		<!-- @slot Used for any content -->
		<slot/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, inject, PropType } from 'vue';
	import { DropdownApi } from './dropdown-api';

	/** The ScDropdownLine component used as a separate dropdown list item */
	export default defineComponent({
		name: 'ScDropdownLine',
		emits: [
			/** click event */
			'click',
		],
		props: {
			/** Set horizontal align */
			align: {
				type: String as PropType<'start' | 'between'>,
				default: 'start',
			},
			/** Set hover state */
			clickabled: {
				type: Boolean,
				default: false,
			},
			/** Set the ability to close the dropdown on click */
			closing: {
				type: Boolean,
				default: false,
			},
			/** Set active state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const dropdown = inject<DropdownApi>('dropdown', null);

			const closeDropdownAndEmitClick = (e: MouseEvent) => {
				if (props.closing && dropdown) {
					dropdown?.closeDropdown();
				}
				emit('click', e);
			};

			return { closeDropdownAndEmitClick };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-dropdown-line {
		display: flex;
		align-items: center;
		gap: 8px;
		min-height: 40px;
		min-width: min-content;
		padding: 10px 12px;
		border-radius: 6px;
		background: transparent;
		cursor: pointer;
		box-sizing: border-box;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: @mulberry-purple;
		font-size: 12px;
		--text-color: @mulberry-purple;

		&_start {
			justify-content: flex-start;
		}

		&_between {
			justify-content: space-between;
		}

		&_activated {
			background: @mulberry-purple-10;
		}

		&_clickabled {
			&:hover {
				background: @mulberry-purple-10;
			}

			&:active {
				background: @mulberry-purple-20;
			}
		}

		&_disabled {
			background: transparent;
			pointer-events: none;
			cursor: default;
			color: @mulberry-purple-40;
			--icon-color: @mulberry-purple-35;
			--text-color: @mulberry-purple-40;
		}

		// todo Недолжно быть использований сторонних классов
		.sc-text {
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		// todo Недолжно быть использований сторонних классов
		.sc-checkbox,
		.sc-checkbox__label {
			overflow: hidden;
		}
	}
</style>
