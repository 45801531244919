<template>
	<div
		:class="{
			'sc-input-emails': true,
			'sc-input-emails_focused': focused,
			'sc-input-emails_error': error,
			'sc-input-emails_disabled': disabled,
		}"
		@click="input.focus()"
	>
		<div
			ref="label"
			class="sc-input-emails__label"
		>
			<ScInputEmailsOption
				v-for="(option, idx) in modelValue"
				:key="idx"
				:modelValue="option"
				:maxWidth="maxWidth"
				:invalidValues="invalidValues"
				@update:modelValue="changeOption(idx, $event)"
				@remove="removeOption(idx)"
			/>
			<input
				ref="input"
				v-model="searchText"
				:placeholder="modelValue.length ? '' : placeholder"
				:disabled="disabled"
				class="sc-input-emails__input"
				@blur="removeFocus"
				@focus="focused = true"
				@keydown.enter.space="addNewOption()"
			/>
		</div>
		<div
			v-if="$slots.default != null"
			class="sc-input-emails__content"
			@click.stop
		>
			<!-- @slot Use for any content (icon, select, etc) -->
			<slot/>
		</div>
	</div>
</template>

<script lang="ts">
	import { ref, computed, defineComponent, onMounted, PropType } from 'vue';
	import ScInputEmailsOption from './input-emails-option.vue';

	/** The ScInputEmails component is interface element with the ability to enter multiple emails */
	export default defineComponent({
		name: 'ScInputEmails',
		components: {
			ScInputEmailsOption,
		},
		emits: [
			/** Update model value */
			'update:modelValue',
		],
		props: {
			/** v-model value */
			modelValue: {
				type: Array as PropType<Array<string>>,
				default: () => ([] as Array<string>),
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Invalid values */
			invalidValues: {
				type: Array as PropType<Array<string>>,
				default: null,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const label = ref<HTMLDivElement>();
			const input = ref<HTMLInputElement>();

			const searchText = ref('');
			const maxWidth = ref<number>();
			const focused = ref(false);

			const trimmedSearchText = computed(() => searchText.value?.trim() ?? '');

			const addNewOption = () => {
				if (!trimmedSearchText.value) {
					return;
				}
				emit('update:modelValue', [...props.modelValue, trimmedSearchText.value]);
				searchText.value = '';
				input.value.focus();
			};

			const changeOption = (index: number, value: string) => {
				const newOptions = props.modelValue.map((option, idx) => idx !== index ? option : value);
				emit('update:modelValue', newOptions);
				input.value.focus();
			};

			const removeOption = (index: number) => {
				const newOptions = props.modelValue.filter((_, idx) => idx !== index);
				emit('update:modelValue', newOptions);
			};

			const removeFocus = () => {
				addNewOption();
				focused.value = false;
				input.value.blur();
			};

			onMounted(() => {
				maxWidth.value = label.value.getBoundingClientRect().width;
			});

			return {
				label,
				input,
				searchText,
				maxWidth,
				focused,
				addNewOption,
				changeOption,
				removeOption,
				removeFocus,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-input-emails {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 44px;
		padding: 6px 8px;
		background: @white;
		border: 1px solid @mulberry-purple-20;
		border-radius: 6px;
		box-sizing: border-box;
		box-shadow: none;
		cursor: text;

		&__label {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			padding: 4px 0;
			width: 100%;
		}

		&__input {
			flex: 1;
			color: @mulberry-purple;
			box-sizing: border-box;
			border: none;
			outline: none;
			font-size: 14px;
			font-weight: 400;
			line-height: 14px;
			background: transparent;
			text-overflow: ellipsis;
			padding: 0;
			margin: 0;
			height: 20px;
			width: 50px;

			&::placeholder {
				color: @mulberry-purple-40;
			}
		}

		&__content {
			margin-left: 10px;
			flex: none;
		}


		&:hover {
			border-color: @mulberry-purple-35;

			.sc-input-emails__input::placeholder {
				color: @mulberry-purple-new;
			}
		}

		&.sc-input-emails_error {
			border: 1px solid @lightish-red;
		}

		&.sc-input-emails_focused {
			background: @fuchsia-blue-0;
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);

			&.sc-input-emails_error {
				border-color: transparent;
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #fec5cc;
			}
		}

		&_disabled {
			cursor: default;
			pointer-events: none;

			.sc-input-emails__input {
				color: @mulberry-purple-35;

				&::placeholder {
					color: @mulberry-purple-35;
					-webkit-text-fill-color: @mulberry-purple-35;
					opacity: 1;
				}
			}
		}
	}
</style>
