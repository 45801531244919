<template>
	<ScDropdown
		:disabled="disabled"
		:overflow="false"
		:class="{
			'sc-datepicker': true,
		 	'sc-datepicker_inline': inline,
		}"
		@close="$emit('close')"
		@open="$emit('open')"
	>
		<template #activator="{ isOpened, toggleDropdown }">
			<div
				class="sc-datepicker__activator"
				@click="toggleDropdown()"
				@keydown.enter="toggleDropdown()"
			>
				<slot
					v-if="slots.placeholder && !modelValue"
					name="placeholder"
					v-bind="{ isOpened }"
				/>
				<ScDatePickerLabel
					v-else
					:disabled="disabled"
					:error="error"
					:is-opened="isOpened"
					:placeholder="placeholder"
					:size="size"
					:startValue="modelValue"
					:time="isTimeActive"
					@update:startValue="removeDate"
					:view="view"
				>
					<template
						v-if="slots.label"
						#default="{currentFormat}"
					>
						<slot
							name="label"
							v-bind="{startValue:modelValue, currentFormat}"
						/>
					</template>
				</ScDatePickerLabel>
			</div>
		</template>
		<template #content>
			<div class="sc-datepicker__dropdown">
				<ScCalendar
					:startValue="startValue"
					@update:startValue="updateDate"
					:minDate="minDate"
					:maxDate="maxDate"
				/>
				<div
					v-if="time"
					class="sc-datepicker__switch"
				>
					<ScSwitch
						:disabled="requireTime"
						:modelValue="isTimeActive"
						@update:modelValue="isTimeActive = $event"
					/>
					<ScText>{{ translate('IncludeTime') }}</ScText>
				</div>
				<div
					v-show="isTimeActive"
					class="sc-datepicker__time"
				>
					<ScTime
						:modelValue="startValue"
						@update:modelValue="updateTime"
						:minDate="minDate"
						:maxDate="maxDate"
					/>
				</div>
				<div
					v-if="requireConfirmation"
					class="sc-datepicker__buttons"
				>
					<div class="sc-datepicker__buttons-clear">
						<ScButton
							view="flat"
							@click="removeDate"
							:disabled="!modelValue"
						>
							{{ translate('Clear') }}
						</ScButton>
					</div>
					<div class="sc-datepicker__buttons-action">
						<ScButton
							view="simple"
							@click="removeNewDate"
							:disabled="startValue === modelValue"
						>
							{{ translate('Reset') }}
						</ScButton>
						<ScButton
							view="cta-black"
							@click="setDate"
							:disabled="startValue === modelValue"
						>
							{{ translate('Apply') }}
						</ScButton>
					</div>
				</div>
			</div>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { defineComponent, PropType, ref, useSlots } from 'vue';
	import ScDropdown from '../dropdown/dropdown.vue';
	import ScDatePickerLabel from './datepicker-label.vue';
	import ScCalendar from './calendar.vue';
	import ScButton from '../button/button.vue';
	import ScTime from './time.vue';
	import { ScSwitch } from '../switch';
	import { ScText } from '../text';
	import { translate } from '../../i18n';

	/** The ScDatePicker component used for displaying a single calendar
	 * with the ability to select the selectedDateTime and minimum date
	 * */
	export default defineComponent({
		name: 'ScDatePicker',
		components: {
			ScDropdown,
			ScDatePickerLabel,
			ScCalendar,
			ScButton,
			ScTime,
			ScSwitch,
			ScText,
		},
		emits: [
			/** Update value event */
			'update:modelValue',
			/** Open event */
			'open',
			/** Close event */
			'close',
			/** Create event */
			'create',
		],
		props: {
			/** Default date */
			modelValue: {
				type: Date,
				default: null,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: '',
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set label size */
			size: {
				type: String as PropType<'28' | '36' | '44'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44'].includes(value),
			},
			/** Set time state */
			time: {
				type: Boolean,
				default: false,
			},
			/** Set minimal choose date */
			minDate: {
				type: Date,
				default: null,
			},
			/** Set maximum choose date*/
			maxDate: {
				type: Date,
				default: null,
			},
			/** Set inline state */
			inline: {
				type: Boolean,
				default: false,
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'plate' | 'flat'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'plate', 'flat'].includes(value),
			},
			/** Set confirmations */
			requireConfirmation: {
				type: Boolean,
				default: false,
			},
			/** Set requireTime state */
			requireTime: {
				type: Boolean,
				default: false,
			},
		},

		setup(props, { emit }) {
			const startValue = ref(props.modelValue);
			const isTimeActive = ref<Boolean>((props.time && Boolean(props.modelValue)) || (props.time && props.requireTime));
			const isMinDate = ref(null);
			const isMaxDate = ref(null);
			const hour = ref(props.modelValue?.getHours() ?? 0);
			const minute = ref(props.modelValue?.getMinutes() ?? 0);

			const slots = useSlots();

			const updateTime = (date: Date) => {
				if (props.requireConfirmation && startValue.value) {
					startValue.value = date;
				} else if (startValue.value) {
					startValue.value = date;
					emit('update:modelValue', date);
				}

				hour.value = date.getHours();
				minute.value = date.getMinutes();
			};
			const updateDate = (date: Date) => {
				checkMinMaxDate(date);
				if (isMinDate.value) {
					hour.value = hour.value < props.minDate.getHours() ? props.minDate.getHours() : hour.value;
					minute.value = minute.value < props.minDate.getMinutes() ? props.minDate.getMinutes() : minute.value;
				}

				if (isMaxDate.value) {
					hour.value = hour.value > props.maxDate.getHours() ? props.maxDate.getHours() : hour.value;
					minute.value = minute.value > props.maxDate.getMinutes() ? props.maxDate.getMinutes() : minute.value;
				}

				const emitDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour.value, minute.value);
				startValue.value = emitDate;

				if (props.requireConfirmation) {
					return;
				} else {
					emit('update:modelValue', emitDate);
				}
			};

			const setDate = () => {
				emit('update:modelValue', startValue.value);
			};

			const checkMinMaxDate = (date: Date) => {
				const dateValue = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour.value, minute.value);
				isMinDate.value = dateValue.getTime() <= props.minDate?.getTime();
				isMaxDate.value = dateValue.getTime() >= props.maxDate?.getTime();
			};

			const removeDate = () => {
				startValue.value = null;
				hour.value = 0;
				minute.value = 0;
				emit('update:modelValue', null);
			};

			const removeNewDate = () => {
				startValue.value = props.modelValue;
				hour.value = 0;
				minute.value = 0;
				emit('update:modelValue', props.modelValue);
			};

			return {
				startValue,
				slots,
				isTimeActive,
				translate,
				updateTime,
				updateDate,
				removeDate,
				setDate,
				removeNewDate,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-datepicker {
		&,
		&__activator {
			width: 100%;

			&.sc-datepicker_inline {
				width: auto;
			}
		}

		&__dropdown {
			padding: 14px;
		}

		&__input::-webkit-inner-spin-button,
		&__input::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}

		&__input {
			width: 90px;
		}

		&__buttons {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			margin-top: 26px;
			flex-wrap: wrap;

			&-action {
				flex-wrap: wrap;
				display: flex;
				gap: 10px;
			}
		}

		&__time {
			margin-top: 16px;
		}

		&__switch {
			display: flex;
			align-items: center;
			gap: 8px;
			margin-top: 16px;
		}
	}
</style>
