<template>
	<div
		:class="`sc-popup-header sc-popup-header_${margin}`"
		data-test-id="popup-header"
	>
		<ScH1>
		<!-- @slot Use for any header text -->
			<slot name="default"/>
		</ScH1>
		<!-- @slot Use for any actions content -->
		<slot
			v-if="$slots.actions != null"
			name="actions"
		/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ScH1 } from '../text';

	/** The ScPopupHeader component used to display content in the header of the popup **/
	export default defineComponent({
		name: 'ScPopupHeader',
		components: { ScH1 },
		props: {
			margin: {
				type: String as PropType<'4' | '10' | '20'>,
				default: '10',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-popup-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_4 {
			margin-bottom: 4px;
		}

		&_10 {
			margin-bottom: 10px;
		}

		&_20 {
			margin-bottom: 20px;
		}
	}
</style>
