
<span
	:class="{
		'sc-badge': true,
		[`sc-badge_${color}`]: true,
		'sc-badge_outlined': outline,
		'sc-badge_hoverable': closing || hoverable,
		'sc-badge_notification': notification,
		'sc-badge_disabled': disabled,
		'sc-badge_only-icon': icon && !slots.default && !closing
	}"
	@click.stop="$emit('click', $event)"
>
	<span
		v-if="icon"
		class="sc-badge__icon"
	>
		<ScIcon
			:name="icon"
			size="14"
		/>
	</span>
	<span
		v-if="slots.default"
		class="sc-badge__text"
	>
		<!-- @slot Use for any text -->
		<slot/>
	</span>
	<span
		v-if="closing"
		class="sc-badge__close"
		@click.stop="$emit('remove', $event)"
	>
		<ScIcon
			name="close"
			size="16"
		/>
	</span>
</span>
