<template>
	<div class="sc-notice">
		<!-- @slot Use for any content where the notification will be shown -->
		<slot/>
		<div
			v-if="label"
			:class="`sc-notice__label sc-notice__label_${view}`"
			:style="{ transform: `translate(calc(50% + ${offset}px), ${-offset}px)` }"
		>
			{{ label }}
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	/** The ScNotice component is used to display the number of notifications */
	export default defineComponent({
		name: 'ScNotice',
		props: {
			/** Set label */
			label: {
				type: [String, Number],
				default: '',
			},
			/** Set view state */
			view: {
				type: String as PropType<'error' | 'warning' | 'hidden'>,
				default: 'warning',
				validate: (value: string) => ['error', 'warning', 'hidden'].includes(value)
			},
			/** Set label offset in px **/
			offset: {
				type: [Number, String],
				default: 0,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-notice {
		position: relative;

		&__label {
			position: absolute;
			z-index: inherit;
			top: -8px;
			right: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 0 4px;
			height: 16px;
			min-width: 16px;
			border-radius: 8px;
			font-size: 8px;
			font-weight: 700;
			color: @white;

			&_error {
				color: @white;
				background-color: @lightish-red;
			}

			&_warning {
				color: @white;
				background-color: @radiation-carrot-40;
			}

			&_hidden {
				display: none;
			}
		}
	}
</style>
