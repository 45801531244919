
<label
	class="sc-switch"
	:class="{
		'sc-switch_checked': modelValue,
		'sc-switch_readonly': readonly,
		'sc-switch_disabled': disabled,
	}"
>
	<input
		:checked="modelValue"
		:disabled="disabled"
		:data-test-id="null"
		type="checkbox"
		class="sc-switch__checkbox"
		@change="$emit('update:modelValue', $event.target.checked)"
	/>
	<span class="sc-switch__label">
		<span class="sc-switch__inner" />
		<span class="sc-switch__switch" />
	</span>
	<span
		v-if="slots.default"
		class="sc-switch__label--right"
	>
		<!-- @slot Use for any text -->
		<slot />
	</span>
</label>
