
<div
	:class="{
		'sc-dropdown-line': true,
		[`sc-dropdown-line_${align}`]: true,
		'sc-dropdown-line_activated': activated,
		'sc-dropdown-line_clickabled': clickabled && !disabled,
		'sc-dropdown-line_disabled': disabled,
	}"
	@click="closeDropdownAndEmitClick($event)"
>
	<!-- @slot Used for any content -->
	<slot/>
</div>
