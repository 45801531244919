<template>
	<div class="sc-progress-infinite">
		<div class="sc-progress-infinite__bar"/>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	/** ScProgressInfinite is a graphical user interface element that is a panel with infinite movement */
	export default defineComponent({ name: 'ScProgressInfinite' });
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-progress-infinite {
		position: relative;
		width: 100%;
		height: 6px;
		border-radius: 4px;
		background-color: @fuchsia-blue-0;

		&__bar {
			border-radius: 4px;
			width: 17%;
			height: 100%;
			background: linear-gradient(90deg, @fuchsia-blue 0%, @pink-insanity 100%);
			animation: infinite-animation ease-in-out 1700ms infinite;
		}
	}

	@keyframes infinite-animation {
		5% {
			transform: scaleX(100%);
		}
		25% {;
			transform: scaleX(200%);
		}
		50% {
			transform: scaleX(100%);
			margin-left: 83%;
		}
		75% {
			transform: scaleX(200%);
		}
		95% {
			transform: scaleX(100%);
		}
	}
</style>
