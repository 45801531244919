import { Component, markRaw, Ref, ref, defineAsyncComponent } from 'vue';
import ScPopupConfirm from '../components/popup/confirm.vue';

interface PopupState<T = unknown> {
	component: Component | ReturnType<typeof defineAsyncComponent>;
	props: Record<string, unknown>;
	close: (data?: T) => void;
}

type PopupProps = Record<string, unknown>;

interface ConfirmPopupProps extends PopupProps {
	emoji?: string;
	title: string;
	text: string;
	cancelButton: string;
	confirmButton: string;
}

const state = ref<PopupState>(null);

export default function usePopup() {
	const show = <T = unknown>(component: Component | ReturnType<typeof defineAsyncComponent>, props?: PopupProps) => {
		return new Promise<T>((resolve) => {
			state.value = {
				component: markRaw(component),
				props,
				close(data?: any) {
					state.value = null;
					resolve(data);
				},
			};
		});
	};

	const showConfirm = (props: ConfirmPopupProps) => {
		return show(ScPopupConfirm, props);
	};

	return {
		state: state as Readonly<Ref<PopupState>>,
		show,
		showConfirm,
	};
}
