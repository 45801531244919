
<div
	class="sc-progress"
	:class="{
		[`sc-progress_${backgroundColor}`]: true,
		'sc-progress--shine': shine,
	}"
>
	<div
		:class="`sc-progress__bar_${barColor}`"
		:style="{ width: `${percent}%` }"
		class="sc-progress__bar"
	/>
</div>
