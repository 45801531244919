import { Component, markRaw, Ref, ref } from 'vue';
import TextNotification from '../components/notification/text-notification.vue';

type NotificationClose = () => void;

interface Notification {
	component: Component;
	id: string;
	timeout: number;
	timeoutId: number;
	props: Record<string, unknown>;
	startTimeout: () => void;
	close: NotificationClose;
}

interface NotificationProps extends Record<string, unknown> {
	timeout?: number;
	afterClose?: () => void;
}

interface TextNotificationProps extends NotificationProps {
	type: 'info' | 'warning' | 'success' | 'alert';
	text: string;
	ctaText?: string;
	ctaAction?: () => void;
}

const state = ref<Array<Notification>>([]);

export default function useNotification() {
	const push = (component: Component, props: NotificationProps = { timeout: 5000 }): NotificationClose => {
		const { timeout, afterClose, ..._props } = props;
		const notification: Notification = {
			component: markRaw(component),
			id: Date.now().toString() + Math.round(Math.random() * 9999),
			timeout: timeout ?? 5000,
			timeoutId: null,
			props: _props,
			startTimeout() {
				if (!notification.timeoutId) {
					notification.timeoutId = window.setTimeout(notification.close, notification.timeout);
				}
			},
			close() {
				const index = state.value.indexOf(notification);
				if (index === -1) {
					return;
				}
				state.value.splice(index, 1);
				window.clearTimeout(notification.timeoutId);
				if (afterClose) {
					afterClose();
				}
			},
		};
		state.value.push(notification);
		return () => notification.close();
	};

	const pushText = (options: TextNotificationProps) => {
		return push(TextNotification, options);
	};

	const clearAll = () => {
		state.value = [];
	};

	return {
		state: state as Readonly<Ref<Array<Notification>>>,
		clearAll,
		pushText,
		push,
	};
}
