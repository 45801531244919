
<div class="sc-label">
	<ScText
		:size="size"
		:color="size === '12' ? 'mulberry-purple-new' : 'mulberry-purple-90'"
		:weight="size === '12' ? 'regular' : 'bold'"
	>
		{{ caption }}<span v-if="required" class="sc-label__required">*</span>
	</ScText>
	<slot/>
</div>
