
<ScNotification
	:type="type"
	@cta="onCtaAction"
	@close="$emit('close')"
>
	<template #default>{{ text }}</template>
	<template
		v-if="ctaText && ctaAction"
		#cta
	>
		{{ ctaText }}
	</template>
</ScNotification>
