
<button
	:class="{
		'sc-button-multi': true,
		[`sc-button-multi_${size}`]: true,
		[`sc-button-multi_${view}`]: true,
		'sc-button-multi_icon': icon && !$slots.default,
		'sc-button-multi_activated': activated,
		'sc-button-multi_response': !icon && response,
	}"
	type="button"
	:disabled="disabled || response || actionsResponse"
>
	<span
		@click="$emit('click', $event)"
		@mousedown="$emit('mousedown', $event)"
		class="sc-button-multi__button"
	>
		<ScLoaderSpin
			v-if="response"
			:size="size === '28' ? '16' : '20'"
			class="sc-button-multi__loader"
		/>
		<span
			v-else-if="icon"
			class="sc-button-multi__icon"
		>
			<ScIcon
				:name="icon"
				:size="size === '28' ? '16' : '20'"
			/>
		</span>
		<span
			v-if="$slots.default"
			class="sc-button-multi__text"
		>
			<!-- @slot Use for any text -->
			<slot name="default"/>
		</span>
	</span>
	<span
		class="sc-button-multi__actions"
		@click.stop="$emit('actions-click', $event)"
	>
		<span class="sc-button-multi__actions-content">
			<ScLoaderSpin
				v-if="actionsResponse"
				size="16"
			/>
			<ScIcon
				v-else
				:name="actionsIcon"
				size="16"
				class="sc-button-multi__actions-icon"
			/>
		</span>
	</span>
</button>
