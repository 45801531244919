<template>
	<div class="sc-dropdown-hr"/>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	/** The ScDropdownHR used as a separate horizontal line any dropdown sections */
	export default defineComponent({ name: 'ScDropdownHR' });
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-dropdown-hr {
		display: block;
		padding: 4px 0;

		&::after {
			content: '';
			display: block;
			border-top: 1px solid @mulberry-purple-10;
		}
	}
</style>
