<template>
	<ScDropdown>
		<template #activator="{ toggleDropdown, isOpened }">
			<ScButtonMulti
				:class="{'sc-button-dropdown': true, 'opened': isOpened}"
				v-bind="$attrs"
				:size="size"
				:view="view"
				:icon="icon"
				:response="response"
				actionsIcon="chevron-down"
				:disabled="disabled"
				:activated="activated || isOpened"
				@actions-click="toggleDropdown"
				@click="$emit('click', $event)"
			>
				<template
					#default
					v-if="$slots.default"
				>
					<!-- @slot Use for any text -->
					<slot name="default"/>
				</template>
			</ScButtonMulti>
		</template>
		<template #content>
			<!-- @slot Use for any content -->
			<slot name="actions"/>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { IconNames } from '../icon';
	import { ScDropdown } from '../dropdown';
	import ScButtonMulti from './button-multi.vue';

	/**
	 * The ScButtonDropdown component is used to display the button with the main and additional action
	 */
	export default defineComponent({
		name: 'ScButtonDropdown',
		emits: [
			/** Click event */
			'click',
		],
		components: { ScButtonMulti, ScDropdown },
		props: {
			/** Set the visual design */
			view: {
				type: String as PropType<'cta-black' | 'simple'>,
				default: 'cta-black',
				validator: (value: string) => ['cta-black', 'simple'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36'>,
				default: '28',
				validator: (value: string) => ['28', '36'].includes(value),
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set activated state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set response state */
			response: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	.sc-button-dropdown {
		&.opened {
			/deep/ .sc-button-multi__actions-icon {
				transform: rotate(180deg);
			}
		}

		/deep/ .sc-button-multi__actions-icon {
			transform: rotate(0);
			transition: transform 200ms;
		}
	}
</style>