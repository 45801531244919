
<div
	:class="{
		'sc-input': true,
		[`sc-input_${view}`]: true,
		[`sc-input_${size}`]: true,
		'sc-input_focused': focused,
		'sc-input_disabled': disabled,
		'sc-input_error': error,
		'sc-input_highlight': highlight,
		[$attrs.class]: $attrs.class,
	}"
	@click="focus()"
>
	<ScIcon
		v-if="icon"
		:name="icon"
		size="16"
		class="sc-input__icon"
	/>
	<input
		ref="input"
		v-bind="{ ...$attrs, class: undefined }"
		v-model.trim="value"
		:disabled="disabled"
		:placeholder="placeholder"
		:readonly="readonly"
		:type="type"
		class="sc-input__input"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
		@focus="setFocusStyles($event)"
		@blur="removeFocusStyles($event)"
		@keydown="$emit('keydown', $event)"
		@keypress="$emit('keypress', $event)"
		@keyup="$emit('keyup', $event)"
	/>
	<ScLoaderSpin
		v-if="response"
		:size="size === '28' ? '16' : '20'"
		data-testid="loader"
	/>
	<ScIcon
		v-if="canClearValue"
		name="circle-cross"
		size="16"
		class="sc-input__clear"
		@click.stop="clear()"
		@mousedown.native.prevent
	/>
	<div
		v-if="$slots.default"
		class="sc-input__content"
	>
		<!-- @slot Use for any content (icon, select, etc) -->
		<slot v-bind="{ disabled }" />
	</div>
</div>
