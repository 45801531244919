<template>
	<ScDropdown
		:disabled="disabled"
		:overflow="false"
		:prefer="prefer"
		:alignWidth="true"
		:class="{
			'sc-multiselect': true,
			'sc-select_inline': inline,
		}"
		@open="open()"
		@close="close()"
	>
		<template #activator="{ isOpened, toggleDropdown }">
			<div
				class="sc-multiselect__activator"
				data-test-id="multiselect-activator"
				@click="toggleDropdown()"
				@keydown.enter="toggleDropdown()"
			>
				<slot
					v-if="$slots.activator != null"
					name="activator"
					v-bind="{ isOpened }"
				/>
				<slot
					v-else-if="$slots.placeholder != null && !modelValue.length"
					name="placeholder"
					v-bind="{ isOpened }"
				/>
				<ScMultiselectLabel
					v-else
					:modelValue="modelValue"
					@update:modelValue="$emit('update:modelValue', $event)"
					:options="allOptions"
					:is-opened="isOpened"
					:disabled="disabled"
					:error="error"
					:placeholder="placeholder"
					:size="size"
					:view="view"
				>
					<template
						v-if="$slots.label != null"
						#default="{ selectedOptions }"
					>
						<slot
							name="label"
							v-bind="{ selectedOptions }"
						/>
					</template>
				</ScMultiselectLabel>
			</div>
		</template>
		<template #content="{ closeDropdown }">
			<div
				class="sc-multiselect__dropdown"
				@keydown.enter="setOption()"
				:tabindex="search ? '' : '-1'"
				ref="content"
				@keydown.down="setFocusNext"
				@keydown.up="setFocusPrev"
			>
				<div
					v-if="category"
					class="sc-multiselect__category"
					data-test-id="multiselect-categories"
				>
					<ScTabList bordered>
						<ScTab
							size="44"
							v-for="(group, index) of allCategoryGroups"
							:key="index"
							:selected="group.active"
							@click.native="setCategory(index);$event.target.scrollIntoView({ behavior: 'smooth' })"
						>
							{{ group.title }}
						</ScTab>
					</ScTabList>
				</div>
				<ScInput
					v-if="search"
					:placeholder="searchPlaceholder"
					:modelValue="searchText"
					:clearable="true"
					@update:modelValue="searchText = $event"
					icon="search"
					class="sc-multiselect__search"
					autofocus
					data-test-id="multiselect-search"
					size="44"
				/>
				<div
					v-show="visibleOptionGroups.length"
					ref="optionsList"
					:class="{
						'sc-multiselect__options': true,
						'shadow-top': search && scrollPosition > 0.01,
						'shadow-bottom': !controls && hasActionsTemplate && scrollPosition < 0.99,
					}"
					data-test-id="multiselect-options"
					@scroll="updateScrollPosition"
				>
					<ScDropdownGroup
						v-for="(group, index) of visibleOptionGroups"
						:key="index"
						:title="group.title"
					>
						<template
							v-for="option of group.options"
							:key="option.value"
						>
							<ScMultiselectRadioLine
								v-if="option.value instanceof Array"
								:modelValue="modelValue"
								:activated="option.value === focusOption"
								:option="option"
								@update:modelValue="$emit('update:modelValue', $event)"
							/>
							<ScMultiselectLine
								v-else
								:activated="option.value === focusOption"
								:option="option"
								@toggleOption="toggleOption"
							>
								<template
									v-if="$slots.option"
									#option="option"
								>
									<slot
										v-bind="option"
										name="option"
									/>
								</template>
							</ScMultiselectLine>
						</template>
						<ScDropdownLine
							v-if="canCreateEntity && index === visibleOptionGroups.length - 1"
							:clickabled="true"
							:closing="true"
							data-test-id="multiselect-creation"
							@click="createEntity()"
						>
							<!-- @slot Use for create entity -->
							<slot
								name="creation"
								:text="trimmedSearchText"
							/>
						</ScDropdownLine>
					</ScDropdownGroup>
				</div>
				<div
					v-if="hasControls"
					class="sc-multiselect__controls"
				>
					<ScButton
						v-if="!checkSelectedOptions"
						view="text"
						@click.native="setAllOptions"
					>
						{{ translate('SelectAll') }}
					</ScButton>
					<div class="sc-multiselect__controls-group">
						<ScButton
							v-if="checkSelectedOptionsLength"
							view="text"
							@click.native="removeSelectedOptions"
						>
							{{ translate('Deselect') }}
						</ScButton>
						<ScText color="mulberry-purple-40">
							{{
								checkSelectedOptionsLength
									? translate('CountSelected').replace('{count}', checkSelectedOptionsLength.toString())
									: translate('AllCounts').replace('{count}', checkAllAvailableOptionsLength.toString())
							}}
						</ScText>
					</div>
				</div>
				<div
					v-if="hasActionsTemplate"
					class="sc-multiselect__actions"
					data-test-id="multiselect-actions"
				>
					<!-- @slot Use for any content -->
					<slot
						name="actions"
						v-bind="{ closeDropdown }"
					/>
				</div>
			</div>
		</template>
	</ScDropdown>
</template>

<script lang="ts">
	import { ref, computed, PropType, defineComponent, onMounted, onUnmounted } from 'vue';
	import {
		MultiselectCategoryGroup,
		MultiselectOption,
		MultiselectOptionGroup,
		MultiselectOptionGroupRendering,
		MultiselectOptionRendering,
	} from './option';
	import ScCheckbox from '../checkbox/checkbox.vue';
	import ScInput from '../input/input.vue';
	import ScDropdown from '../dropdown/dropdown.vue';
	import ScDropdownGroup from '../dropdown/dropdown-group.vue';
	import ScDropdownLine from '../dropdown/dropdown-line.vue';
	import ScTabList from '../tab/tab-list.vue';
	import ScTab from '../tab/tab.vue';
	import ScText from '../text/text.vue';
	import ScMultiselectLabel from './label.vue';
	import { translate } from '../../i18n';
	import ScRadio from '../radio/radio.vue';
	import ScMultiselectLine from './multiselect-line.vue';
	import ScMultiselectRadioLine from './multiselect-radio-line.vue';
	import ScButton from '../button/button.vue';

	/**
	 * The ScMultiselect component is interface element in the form of a drop-down list, with the possibility of multiple selection
	 */
	export default defineComponent({
		name: 'ScMultiselect',
		components: {
			ScButton,
			ScMultiselectRadioLine,
			ScMultiselectLine,
			ScRadio,
			ScCheckbox,
			ScInput,
			ScDropdown,
			ScDropdownGroup,
			ScDropdownLine,
			ScMultiselectLabel,
			ScTabList,
			ScTab,
			ScText,
		},
		emits: [
			/** Update value event */
			'update:modelValue',
			/** Open event */
			'open',
			/** Close event */
			'close',
			/** Create event */
			'create',
		],
		props: {
			/** V-model value */
			modelValue: {
				type: Array as PropType<Array<number | string>>,
				default: () => ([] as Array<number | string>),
			},
			/** Options */
			options: {
				type: Array as PropType<Array<MultiselectOption | MultiselectOptionGroup>>,
				required: true,
				default: () => ([] as Array<MultiselectOption | MultiselectOptionGroup>),
			},
			/** Set option groups */
			groups: {
				type: Boolean,
				default: false,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set options placeholder */
			optionsPlaceholder: {
				type: String,
				default: null,
			},
			/** Set search placeholder */
			searchPlaceholder: {
				type: String,
				default: null,
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36' | '44' | 'auto'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44', 'auto'].includes(value),
			},
			/** Set search input */
			search: {
				type: Boolean,
				default: true,
			},
			/** Set category */
			category: {
				type: Boolean,
				default: true,
			},
			/** Set controls */
			controls: {
				type: Boolean,
				default: true,
			},
			/** Set preferred open direction */
			prefer: {
				type: String as PropType<'auto' | 'left'>,
				default: 'auto',
			},
			/** Set inline state */
			inline: {
				type: Boolean,
				default: false,
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'plate'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'plate'].includes(value),
			},
		},
		setup(props, { emit, slots }) {
			const searchText = ref('');
			const optionsList = ref();
			const content = ref();
			const focusOption = ref(null);
			const activeCategoryIndex = ref(0);
			const scrollPosition = ref(0);

			const trimmedSearchText = computed(() => searchText.value?.trim() ?? '');

			const hasActionsTemplate = computed(() => slots.actions != null);

			const optionGroups = computed<Array<MultiselectOptionGroup>>(() => {
				return props.groups
					? props.options as Array<MultiselectOptionGroup>
					: [{
						title: props.optionsPlaceholder,
						options: props.options as Array<MultiselectOption>,
					}];
			});

			const allOptions = computed<Array<MultiselectOption>>(() => {
				const rawOptions = props.groups
					? (props.options as Array<MultiselectOptionGroup>).flatMap((group) => group.options)
					: props.options as Array<MultiselectOption>;
				return rawOptions.map((option) => Array.isArray(option.value) ? option.value : option).flat();
			});

			const filterOptionsBySearchText = (options: Array<MultiselectOption>) => {
				if (!trimmedSearchText.value) {
					return options;
				}
				const trimmedSearchTextLowerCased = trimmedSearchText.value.toLowerCase();
				return options.filter((option) => {
					return option.text.toLowerCase().includes(trimmedSearchTextLowerCased);
				});
			};

			const visibleOptionGroups = computed<Array<MultiselectOptionGroupRendering>>(() => {
				return optionGroups.value
					.filter((group, index) => activeCategoryIndex.value === 0 ? group : index === activeCategoryIndex.value - 1)
					.map((group) => ({
						title: activeCategoryIndex.value === 0 ? group.title : '',
						options: filterOptionsBySearchText(group.options).map((option) => ({
							...option,
							checked: Array.isArray(option.value)
								? option.value.some((radioOption) => props.modelValue.includes(radioOption.value))
								: props.modelValue.includes(option.value),
						})),
					}))
					.filter((group, index) => {
						return group.options.length > 0 || (index === 0 && slots.creation != null);
					});
			});
			const hasControls = computed(() => props.controls && visibleOptionGroups.value.length !== 0);

			const allCategoryGroups = computed<ReadonlyArray<MultiselectCategoryGroup>>(() => {
				return [translate('All'), ...optionGroups.value.map(group => group.title)].map((title, index) => ({
					title,
					active: activeCategoryIndex.value === index,
				}));
			});

			const allVisibleOptions = computed(() => visibleOptionGroups.value.flatMap(group => group.options));

			const canCreateEntity = computed(() => {
				if (slots.creation == null || trimmedSearchText.value.length < 2) {
					return false;
				}
				const trimmedSearchTextLowerCased = trimmedSearchText.value.toLowerCase();
				return optionGroups.value.some((group) => {
					return group.options.every((option) => option.text.toLowerCase() !== trimmedSearchTextLowerCased);
				});
			});

			const updateScrollPosition = () => {
				if (!optionsList.value) {
					return;
				}
				const { clientHeight, scrollHeight, scrollTop } = optionsList.value;
				scrollPosition.value = Math.round(scrollTop) / (scrollHeight - clientHeight);
			};

			const open = () => {
				searchText.value = '';
				focusOption.value = null;

				setTimeout(() => {
					content.value.focus();
					updateScrollPosition();
				}, 0);
				emit('open');
			};

			const close = () => {
				emit('close');
			};

			const checkSelectedOptions = computed(() => {
				return allVisibleOptions.value
					.filter(options => options.checked && !options.disabled).length === allVisibleOptions.value
					.filter(options => !options.disabled).length;
			});

			const checkSelectedOptionsLength = computed(() => {
				return allVisibleOptions.value.filter(options => options.checked).length;
			});

			const checkAllAvailableOptionsLength = computed(() => {
				return allVisibleOptions.value.filter(options => !options.disabled).length;
			});

			const setAllOptions = () => {
				const emitOptions = [
					...props.modelValue,
					...allVisibleOptions.value
						.filter(option => Array.isArray(option.value)
							? option.value.every(el => !props.modelValue.includes(el.value))
							: !props.modelValue.includes(option.value) && !option.disabled)
						.map(option => Array.isArray(option.value) ? option.value[0].value : option.value),
				];
				emit('update:modelValue', emitOptions);
			};

			const removeSelectedOptions = () => {
				const visibleOptionsValues = allVisibleOptions.value.map(option => Array.isArray(option.value)
					? option.value.map(el => el.value).flat()
					: option.value).flat();
				const emitOptions = props.modelValue.filter(el => !visibleOptionsValues.includes(el));
				emit('update:modelValue', emitOptions);
			};

			const toggleOption = (option: MultiselectOptionRendering) => {
				const checked = props.modelValue.find(value => option.value === value);
				emit('update:modelValue', checked !== undefined
					? props.modelValue.filter((value) => option.value !== value)
					: [...props.modelValue, option.value],
				);
			};

			const createEntity = () => {
				emit('create', trimmedSearchText.value);
			};

			const setFocusNext = () => {
				if (!visibleOptionGroups.value[0] || !optionsList.value) return;
				const activeOption = allVisibleOptions.value.findIndex(option => option.value === focusOption.value && !option.disabled);

				const nextOption = allVisibleOptions.value.findIndex((option, idx) => idx > activeOption && !option.disabled);

				if (nextOption > -1) {
					focusOption.value = allVisibleOptions.value[nextOption].value;
				} else {
					const firstNonDisabledOption = allVisibleOptions.value.findIndex((option) => !option.disabled);
					if (firstNonDisabledOption > -1) {
						focusOption.value = allVisibleOptions.value[firstNonDisabledOption].value;
					}
				}
				scrollToActiveOption();
			};

			const setFocusPrev = () => {
				if (!visibleOptionGroups.value[0] || !optionsList.value) return;
				const activeOption = allVisibleOptions.value.findIndex(option => option.value === focusOption.value);

				let prevOption = -1;

				for (let i = allVisibleOptions.value.length - 1; i >= 0; i--) {
					if (i < activeOption && !allVisibleOptions.value[i].disabled) {
						prevOption = i;
						break;
					}
				}
				if (prevOption > -1) {
					focusOption.value = allVisibleOptions.value[prevOption].value;

				} else {
					let lastNonDisabledOption = -1;
					for (let i = allVisibleOptions.value.length - 1; i >= 0; i--) {
						if (!allVisibleOptions.value[i].disabled) {
							lastNonDisabledOption = i;
							break;
						}
					}
					focusOption.value = allVisibleOptions.value[lastNonDisabledOption].value;
				}
				scrollToActiveOption();
			};

			const scrollToActiveOption = () => {
				requestAnimationFrame(() => {
					const activeElem = optionsList.value ? optionsList.value.querySelector('.sc-dropdown-line_activated') : '';
					if (activeElem) {
						activeElem.scrollIntoView({ block: 'center' });
					}
				});
			};

			const setOption = () => {
				const activeOption = allVisibleOptions.value.find(el => el.value === focusOption.value);
				toggleOption(activeOption);
			};

			const setCategory = (index: number) => {
				activeCategoryIndex.value = index;
			};

			onMounted(() => {
				window.addEventListener('resize', updateScrollPosition);
			});

			onUnmounted(() => {
				window.removeEventListener('resize', updateScrollPosition);
			});

			return {
				hasControls,
				removeSelectedOptions,
				setAllOptions,
				checkSelectedOptionsLength,
				checkSelectedOptions,
				setCategory,
				allCategoryGroups,
				content,
				optionsList,
				setOption,
				focusOption,
				setFocusNext,
				setFocusPrev,
				searchText,
				trimmedSearchText,
				hasActionsTemplate,
				allOptions,
				visibleOptionGroups,
				canCreateEntity,
				scrollPosition,
				checkAllAvailableOptionsLength,
				open,
				close,
				toggleOption,
				createEntity,
				updateScrollPosition,
				translate,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";
	@import "../../styles/scroll-bar";

	.sc-multiselect,
	.sc-multiselect__activator {
		width: 100%;

		&.sc-select_inline {
			width: auto;
		}
	}

	.sc-multiselect {
		&__dropdown {
			height: 100%;
			max-height: 500px;
			width: 100%;
			display: flex;
			flex-flow: column nowrap;
			outline: none;
		}

		&__line-text {
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&__checkbox {
			overflow: hidden;
			align-items: center;
		}

		&__search {
			flex: none;
			margin-bottom: 10px;
		}

		&__category {
			padding: 0 12px;
			flex: none;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;

			.sc-tab:last-child {
				padding-right: 12px;
			}

			&::-webkit-scrollbar {
				height: 0;
			}

			& + .sc-multiselect__search {
				margin-top: 20px;
			}
		}

		&__controls {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			column-gap: 20px;
			row-gap: 6px;
			border-top: 1px solid @border-color;
			padding: 20px 12px 10px 12px;

			&-group {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 20px;
			}
		}

		&__options {
			flex: 1;
			overflow-x: hidden;
			overflow-y: auto;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;

			&.shadow-top {
				border-top-color: @border-color;
			}

			&.shadow-bottom {
				border-bottom-color: @border-color;
			}

			.scroll-bar();
		}

		&__actions {
			flex: none;
			margin-top: 10px;
			width: 100%;
		}
	}
</style>
