
<button
	:class="`sc-button-circle sc-button-circle_${size} sc-button-circle_${view}`"
	:disabled="disabled"
	type="button"
	@click="$emit('click', $event)"
>
	<ScIcon
		:name="icon"
		:size="size === '28' ? '16' : '20'"
	/>
</button>
