
<ScDropdown
	:class="classes"
	:disabled="disabled"
	:overflow="false"
	:prefer="prefer"
	:alignWidth="true"
	@close="close()"
	@open="open()"
>
	<template #activator="{ isOpened, toggleDropdown }">
		<div
			class="sc-select__activator"
			data-test-id="select-activator"
			@click="toggleDropdown()"
			@keydown.enter="toggleDropdown()"
		>
			<slot
				v-if="$slots.activator != null"
				name="activator"
				v-bind="{ isOpened }"
			/>
			<slot
				v-else-if="$slots.placeholder != null && !selectedOption"
				name="placeholder"
				v-bind="{ isOpened }"
			/>
			<ScSelectLabel
				v-else
				:clearable="clearable"
				:disabled="disabled"
				:inline="inline"
				:error="error"
				:isOpened="isOpened"
				:modelValue="modelValue"
				:placeholder="placeholder"
				:selectedOption="selectedOption"
				:size="size"
				:view="view"
				:highlight="highlight"
				@update:modelValue="$emit('update:modelValue', $event)"
			>
				<template
					v-if="hasCustomLabelTemplate"
					#default
				>
					<!-- @slot Use for custom label content -->
					<slot
						name="label"
						v-bind="{ selectedOption }"
					/>
				</template>
			</ScSelectLabel>
		</div>
	</template>
	<template #content="{ closeDropdown, isOpened }">
		<div
			:tabindex="search ? '' : '-1'"
			ref="content"
			class="sc-select__dropdown"
			@keydown.enter="setOption(); closeDropdown()"
			@keydown.down="setFocusNext"
			@keydown.up="setFocusPrev"
		>
			<ScInput
				v-if="search"
				:modelValue="searchText"
				@update:modelValue="searchText = $event"
				:placeholder="searchPlaceholder"
				:clearable="true"
				autofocus
				class="sc-select__search"
				data-test-id="select-search"
				icon="search"
				size="44"
			/>
			<div
				ref="optionsList"
				:class="{
					'sc-select__options': true,
					'shadow-top': search && scrollPosition > 0.01,
					'shadow-bottom': hasActionsTemplate && scrollPosition < 0.99,
				}"
				data-test-id="select-options"
				@scroll="updateScrollPosition"
			>
				<ScDropdownGroup
					v-for="(group, index) of visibleOptionGroups"
					:key="index"
					:title="group.title"
				>
					<ScDropdownLine
						v-for="option of group.options"
						:key="option.value"
						:activated="option.checked || option.value === focusOption"
						:clickabled="true"
						:closing="true"
						:disabled="option.disabled"
						:data-value="option.value"
						@click="selectOption(option)"
					>
						<!-- @slot Use for custom option content -->
						<slot
							v-if="hasCustomOptionTemplate"
							name="option"
							v-bind="option"
						/>
						<ScText
							class="sc-select__line-text"
							v-else
						>
							{{ option.text }}
						</ScText>
					</ScDropdownLine>
					<ScDropdownLine
						v-if="canCreateEntity && index === visibleOptionGroups.length - 1"
						:clickabled="true"
						:closing="true"
						data-test-id="select-creation"
						@click="createEntity()"
					>
						<!-- @slot Use for create entity -->
						<slot
							:text="trimmedSearchText"
							name="creation"
						/>
					</ScDropdownLine>
				</ScDropdownGroup>
			</div>
			<div
				v-if="hasActionsTemplate"
				class="sc-select__actions"
				data-test-id="select-actions"
			>
				<!-- @slot Use for any content -->
				<slot
					name="actions"
					v-bind="{ closeDropdown }"
				/>
			</div>
		</div>
	</template>
</ScDropdown>
