<template>
	<div
		:class="{
			'sc-avatar-list': true,
			[`sc-avatar-list__${view}`]: true,
		}"
	>
		<ScAvatar
			v-for="(item, index) in avatars"
			class="sc-avatar-list__avatar"
			:key="index"
			:size="size"
			:userId="item.userId"
			:name="item.name"
			:status="item.status"
			:url="item.url"
			:view="avatarView"
		/>

		<div
			v-if="label"
			class="sc-avatar-list__label"
		>
			{{ label }}
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType } from 'vue';
	import ScAvatar from './avatar.vue';
	import { translate } from '../../i18n';

	interface AvatarListItem {
		userId?: string;
		name?: string;
		url?: string;
		status?: 'none' | 'online' | 'offline';
	}

	const avatarsNumber = 3;

	/**	The ScAvatarList component is used to display a list of avatar components. **/
	export default defineComponent({
		name: 'ScAvatarList',
		components: { ScAvatar },
		props: {
			/** Set list of avatars */
			list: {
				type: Array as PropType<Array<AvatarListItem>>,
				required: true,
				default: () => [] as Array<AvatarListItem>,
			},
			/** Set total count of list items */
			count: {
				type: Number,
				default: null,
			},
			/** Set size */
			size: {
				type: String as PropType<'18' | '24' | '32' | '44'>,
				default: '24',
				validator: (value: string) => ['18', '24', '32', '44'].includes(value),
			},
			/** Set view */
			view: {
				type: String as PropType<'square' | 'circle' | 'intersection'>,
				default: 'square',
				validator: (value: string) => ['square', 'circle', 'intersection'].includes(value),
			},
			/** Set show single name if users total = 1 */
			showSingleName: {
				type: Boolean,
				default: true,
			},
		},
		setup(props) {
			const avatars = computed(() => props.list.slice(0, avatarsNumber));

			const totalCount = computed(() => props.count ?? props.list.length);

			const avatarView = computed(() => (props.view === 'intersection' ? 'circle' : props.view));

			const label = computed(() => {
				if (totalCount.value === 1 && props.showSingleName) {
					return props.list[0].name;
				} else if (totalCount.value > avatarsNumber) {
					return translate('CountMore').replace('{count}', (totalCount.value - avatarsNumber).toString());
				} else {
					return '';
				}
			});

			return {
				avatars,
				label,
				avatarView,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-avatar-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;

		&__label {
			font-size: 12px;
			font-weight: 400;
			white-space: nowrap;
			color: @mulberry-purple-new;
		}

		&__intersection {
			gap: 0;

			.sc-avatar-list__avatar::before {
				border: 2px solid var(--background-color);
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}

			.sc-avatar-list__avatar:not(:last-child) {
				margin-right: -2px;
			}

			.sc-avatar-list__label {
				margin-left: 8px;
			}
		}
	}
</style>
