
<Teleport
	v-if="canRenderTeleport"
	:to="to"
>
	<Transition
		:name="name"
		@beforeEnter="beforeEnter"
		@afterLeave="afterLeave"
	>
		<slot v-if="canRenderSlot"/>
	</Transition>
</Teleport>
