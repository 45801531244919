<template>
	<div
		ref="avatarContainer"
		:class="`sc-avatar sc-avatar_${size} sc-avatar_${view} sc-avatar_bg${avatarBackgroundIx}`"
	>
		<span class="sc-avatar__label">{{ avatarLabel }}</span>
		<span
			v-if="status !== 'none' && view === 'circle'"
			:class="`sc-avatar__status ${status}`"
		/>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, onMounted, PropType, ref } from 'vue';

	let avatarUrlTemplate = '/users/{userId}/profileImage';

	/**	The ScAvatar component is used to display a picture or username abbreviation. **/
	export default defineComponent({
		name: 'ScAvatar',
		emits: ['loaded'],
		props: {
			/** Set userId */
			userId: {
				type: String,
				default: '',
			},
			/** Set name of user */
			name: {
				type: String,
				default: '',
			},
			/** Set online status */
			status: {
				type: String as PropType<'none' | 'online' | 'offline'>,
				default: 'none',
				validator: (value: string) => ['none', 'online', 'offline'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'18' | '24' | '32' | '44' | '104'>,
				default: '44',
				validator: (value: string) => ['18', '24', '32', '44', '104'].includes(value),
			},
			/** Set url */
			url: {
				type: String,
				default: '',
			},
			/** Set view */
			view: {
				type: String as PropType<'square' | 'circle'>,
				default: 'square',
				validator: (value: string) => ['square', 'circle'].includes(value),
			},
		},
		setAvatarUrlTemplate(value: string) {
			avatarUrlTemplate = value;
		},
		setup: function (props, { emit }) {
			const avatarContainer = ref();
			const imgLoaded = ref(false);

			const avatarUrl = computed(() => {
				if (props.url) {
					return props.url;
				}
				if (props.userId) {
					return avatarUrlTemplate.replace('{userId}', props.userId);
				}
				return null;
			});

			const avatarLabel = computed(() => {
				if (!props.name) {
					return '';
				}
				const nameParts = props.name
					.replace(/[^\p{L}\s]/gu, '')
					.replace(/\s+/g, ' ')
					.trim()
					.split(' ');
				return (nameParts.length > 1)
					? nameParts[0].charAt(0) + nameParts[1].charAt(0)
					: (nameParts[0] || '').charAt(0);
			});

			const avatarBackgroundIx = computed(() => {
				if (imgLoaded.value) {
					return 0;
				}
				if (!props.name) {
					return 1;
				}
				let code = 0;
				for (let i = 0; i < props.name.length; i++) {
					code = code ^ props.name.charCodeAt(i);
				}
				const result = (code % 6) + 1;
				return result > 6 ? 1 : result;
			});

			onMounted(() => {
				if (!avatarUrl.value) {
					return;
				}

				const img = new Image();
				img.src = avatarUrl.value;
				img.alt = avatarLabel.value;
				img.className = 'sc-avatar__img';

				img.addEventListener('load', () => {
					if (!avatarContainer.value) {
						return;
					}
					avatarContainer.value.appendChild(img);
					imgLoaded.value = true;
					emit('loaded');
				});
			});

			return {
				avatarContainer,
				avatarLabel,
				avatarBackgroundIx,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-avatar {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;
		white-space: nowrap;
		box-sizing: content-box;

		/deep/ &__img {
			background: @white;
			width: 100%;
			object-fit: cover;
			object-position: center;
			z-index: 2;
			border-radius: inherit;
			mix-blend-mode: darken;
		}

		&__label {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			text-align: center;
			text-transform: uppercase;
			color: @white;
			z-index: 1;
		}

		&__status {
			position: absolute;
			border: 2px solid var(--background-color);
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: @mulberry-purple-30;
			z-index: 3;
			right: -2px;
			bottom: -2px;

			&.online {
				background: @breathtaking-mint;
			}
		}

		&_18 {
			width: 18px;
			height: 18px;
			border-radius: 4px;
			font-weight: 600;
			font-size: 8px;
			line-height: 16px;
		}

		&_24 {
			width: 24px;
			height: 24px;
			border-radius: 4px;
			font-weight: 600;
			font-size: 10px;
			line-height: 16px;
		}

		&_32 {
			width: 32px;
			height: 32px;
			border-radius: 4px;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;

			.sc-avatar__status {
				width: 6px;
				height: 6px;
			}
		}

		&_44 {
			width: 44px;
			height: 44px;
			border-radius: 6px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;

			.sc-avatar__status {
				width: 8px;
				height: 8px;
			}
		}

		&_104 {
			width: 104px;
			height: 104px;
			border-radius: 6px;
			font-weight: 600;
			font-size: 36px;
			line-height: 44px;

			.sc-avatar__status {
				border-width: 4px;
				width: 14px;
				height: 14px;
				right: 0;
				bottom: 0;
			}
		}

		&_circle {
			border-radius: 50%;
		}

		&_bg0 {
			background: @mulberry-purple-0;
		}

		&_bg1 {
			background: @denim-30;
		}

		&_bg2 {
			background: @breathtaking-mint-30;
		}

		&_bg3 {
			background: @pink-insanity-30;
		}

		&_bg4 {
			background: @fuchsia-blue-30;
		}

		&_bg5 {
			background: @radiation-carrot-30;
		}

		&_bg6 {
			background: @lightish-red-30;
		}
	}
</style>
