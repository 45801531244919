<template>
	<div
		v-if="canShowMessage"
		class="sc-validation-message"
	>
		<ScIcon
			name="alert"
			color="lightish-red"
			size="16"
			class="sc-validation-message__icon"
		/>
		<ScText
			color="lightish-red"
			size="12"
		>
			<slot/>
		</ScText>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, inject } from 'vue';
	import { Validation } from './types';
	import ScIcon from '../icon/icon.vue';
	import ScText from '../text/text.vue';

	export default defineComponent({
		name: 'ScValidationMessage',
		components: { ScIcon, ScText },
		props: {
			type: {
				type: String,
				required: true,
			},
		},
		setup(props) {
			const validation = inject<Validation>('validation');

			const canShowMessage = computed(() => validation?.errors?.value?.includes(props.type));

			return {
				canShowMessage,
			};
		},
	});
</script>

<style lang="less" scoped>
	.sc-validation-message {
		display: none;

		&:first-of-type {
			display: flex;
		}

		&__icon {
			flex: none;
			margin-right: 4px;
		}
	}
</style>
