<template>
	<div
		ref="root"
		:class="classes"
	>
		<!-- @slot Used for any element, clicking on which will cause a dropdown to appear -->
		<slot
			name="activator"
			v-bind="{ isOpened, openDropdown, toggleDropdown }"
		/>
		<div
			v-if="isOpened"
			class="sc-dropdown__cover"
			@click="toggleDropdown"
		/>
		<ScTransitionViaTeleport
			:condition="isOpened"
			name="opacity"
			@before-enter="keepPosition"
		>
			<div
				v-show="slots.content"
				ref="dropdown"
				:data-test-id="($attrs['data-test-id'] || 'dropdown') + '-container'"
				class="sc-dropdown__container"
			>
				<div
					ref="content"
					:class="{ 
						'sc-dropdown__content_overflowed': overflow, 
						'sc-dropdown__content_disabled-scroll': !isTopLayer 
					}"
					class="sc-dropdown__content"
				>
					<!-- @slot Used for any content -->
					<slot
						name="content"
						v-bind="{ isOpened, closeDropdown }"
					/>
				</div>
			</div>
		</ScTransitionViaTeleport>
	</div>
</template>

<script lang="ts">
	import {
		computed,
		ref,
		provide,
		defineComponent,
		onMounted,
		onUnmounted,
		nextTick,
		PropType,
		inject,
		watch,
		useSlots,
	} from 'vue';
	import { onClickOutside } from '@vueuse/core';
	import ScTransitionViaTeleport from '../transition/via-teleport.vue';
	import useLayers from '../../composables/use-layers';
	import { DropdownApi } from './dropdown-api';

	/**
	 * The ScDropdown component this is the graphical user interface that allows you to select
	 * one of several predefined parameter values.
	 */
	export default defineComponent({
		name: 'ScDropdown',
		components: {
			ScTransitionViaTeleport,
		},
		emits: [
			/** Open dropdown event */
			'open',
			/** Close dropdown event */
			'close',
			/** Cancel dropdown event */
			'cancel',
		],
		props: {
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set content overflow strategy */
			overflow: {
				type: Boolean,
				default: true,
			},
			/** Set preferred open direction */
			prefer: {
				type: String as PropType<'auto' | 'left'>,
				default: 'auto',
			},
			/** Set dropdown is nested */
			nested: {
				type: Boolean,
				default: false,
			},
			/** Set alignWidth state */
			alignWidth: {
				type: Boolean,
				default: false,
			}
		},
		setup(props, { emit }) {
			const layers = useLayers();
			const slots = useSlots();
			const parent = props.nested ? inject<DropdownApi>('dropdown') : null;

			const dropdown = ref<HTMLDivElement>();
			const content = ref<HTMLDivElement>();
			const root = ref<HTMLDivElement>();

			const isOpened = ref(false);
			const nestings = new Set<HTMLElement>();

			const keepPosition = async () => {
				await nextTick();

				if (!content.value || !dropdown.value || !root.value) {
					return;
				}

				const indentation = 4;
				const safeZone = 20;
				const mobileSafeZone = 30;
				const defaultMaxHeight = 570;
				const mobileWidth = 479;

				const activatorRect = root.value.getBoundingClientRect();
				const isMobileWidth = window.innerWidth <= mobileWidth;
				const maxHeight = Math.min(defaultMaxHeight, window.innerHeight - 2 * safeZone);
				const maxWidth = window.innerWidth - 2 * safeZone;

				let top;
				let left;
				let right;

				if (isMobileWidth) {
					Object.assign(dropdown.value.style, {
						top: `50%`,
						bottom: null,
						left: `50%`,
						transform: `translate(-50%, -50%)`,
						right: null,
						maxHeight: `${maxHeight}px`,
						minWidth: null,
						maxWidth: `calc(100vw - ${2 * mobileSafeZone}px)`,
					});
				} else {
					const minWidth = Math.min(Math.max(dropdown.value.clientWidth, props.alignWidth ? activatorRect.width : 0), maxWidth);
					const belowHeight = Math.max(window.innerHeight - activatorRect.bottom - 2 * safeZone, 0);

					const isOpensToDown = belowHeight >= content.value.scrollHeight;
					const isOpensToUp = activatorRect.top - safeZone >= content.value.scrollHeight;
					const isOpensToRight = props.prefer === 'left'
						? activatorRect.right - minWidth < safeZone
						: (props.nested
								? window.innerWidth > activatorRect.right + minWidth + safeZone
								: window.innerWidth > activatorRect.left + minWidth + safeZone
						);

					if (props.nested) {
						top = isOpensToDown
							? `${activatorRect.top}px`
							: Math.max(safeZone, window.innerHeight - content.value.clientHeight - 2 * safeZone) + 'px';
						left = `${activatorRect.right + indentation}px`;
						right = `${document.documentElement.clientWidth - activatorRect.left + indentation}px`;
					} else {
						top = isOpensToDown
							? `${activatorRect.top + activatorRect.height + indentation}px`
							: isOpensToUp
								? null
								: Math.max(safeZone, window.innerHeight - content.value.clientHeight - 2 * safeZone) + 'px';
						left = `${activatorRect.left}px`;
						right = `${document.documentElement.clientWidth - activatorRect.right}px`;
					}

					Object.assign(dropdown.value.style, {
						top,
						bottom: isOpensToUp ? `${window.innerHeight - activatorRect.top + indentation}px` : null,
						left: isOpensToRight ? left : null,
						right: isOpensToRight ? null : right,
						maxHeight: `${maxHeight}px`,
						minWidth: props.nested ? 'fit-content' : `${minWidth}px`,
						transform: null,
						maxWidth: null,
					});
				}
			};

			const isTopLayer = computed(() => layers.isTopLayer(content) || (isOpened.value && props.nested));

			const classes = computed(() => ({
				'sc-dropdown': true,
				'sc-dropdown_opened': isOpened.value,
			}));

			const openDropdown = () => {
				if (props.disabled) {
					return;
				}
				if (!props.nested) {
					nextTick(() => {
						layers.add(content);
					})
				}
				isOpened.value = true;
				emit('open');
			};

			const closeDropdown = () => {
				if (!props.nested && !layers.isTopLayer(content)) {
					return;
				}
				if (!props.nested) {
					layers.pop();
				}
				isOpened.value = false;
				emit('close');
			};

			const toggleDropdown = () => {
				if (props.disabled) {
					return;
				}
				if (isOpened.value) {
					closeDropdown();
				} else {
					openDropdown();
				}
			};

			const onKeyDown = (e: KeyboardEvent) => {
				if (e.key === 'Escape' || e.key === 'Esc') {
					closeDropdown();
					emit('cancel');
				}
			};

			onClickOutside(content, (e: any) => {
				const elements = [root.value, content.value, ...nestings];
				if (elements.every(el => !el?.contains(e.target))) {
					closeDropdown();
					if (props.nested && layers.getTopLayer()?.contains(e.target)) {
						return;
					}
					emit('cancel');
				}
			});

			onMounted(() => {
				window.addEventListener('keydown', onKeyDown);
				window.addEventListener('resize', keepPosition);
				window.addEventListener('scroll', keepPosition);
			});

			onUnmounted(() => {
				window.removeEventListener('keydown', onKeyDown);
				window.removeEventListener('resize', keepPosition);
				window.removeEventListener('scroll', keepPosition);
			});

			if (parent != null) {
				watch(isOpened, () => {
					if (isOpened.value) {
						setTimeout(() => parent.nestings.add(content.value));
					} else {
						parent.nestings.delete(content.value);
					}
				});
			}

			provide<DropdownApi>('dropdown', {
				isOpened,
				nestings,
				openDropdown,
				toggleDropdown,
				closeDropdown: () => {
					closeDropdown();
					if (parent != null) {
						nextTick(() => parent.closeDropdown());
					}
				},
			});

			return {
				root,
				dropdown,
				content,
				isOpened,
				classes,
				isTopLayer,
				slots,
				openDropdown,
				toggleDropdown,
				closeDropdown,
				keepPosition,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";
	@import "../../styles/scroll-bar";

	.sc-dropdown {
		display: inline-flex;
		position: relative;

		&__container {
			display: flex;
			height: fit-content;
			max-width: calc(100vw - 40px);
			width: fit-content;
			position: fixed;
			padding: 6px;
			background: @white;
			box-sizing: border-box;
			box-shadow: 0 0 2px rgba(44, 36, 64, 0.48), 0 4px 10px rgba(44, 36, 64, 0.16);
			border-radius: 6px;
			z-index: 9998;

			.screen-lt-480({
				min-width: auto;
				position: fixed;
			});
		}

		&__content {
			display: block;
			min-width: 100%;
			width: min-content;

			&_overflowed {
				overflow-x: hidden;
				overflow-y: auto;
			}

			&_disabled-scroll {
				pointer-events: none;
			}

			.scroll-bar();
		}
	}

	.sc-dropdown__cover {
		content: '';
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: @mulberry-purple-30;
		opacity: 0.8;
		z-index: 9998;

		.screen-lt-480({
			display: block;
		})
	}

	.opacity-enter-active,
	.opacity-leave-active {
		transition: opacity 0.12s ease-in-out, transform 0.2s ease-in-out;

		.screen-lt-480({
			transition: opacity 0.12s ease-in-out;
		})
	}

	.opacity-enter-from,
	.opacity-leave-to {
		opacity: 0;
	}
</style>
