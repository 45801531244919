
<label
	class="sc-radio"
	:class="{
		'sc-radio_checked': modelValue === value,
		'sc-radio_readonly': readonly,
		'sc-radio_disabled': disabled,
	}"
>
	<input
		:value="value"
		:checked="modelValue === value"
		:disabled="disabled"
		type="radio"
		class="sc-radio__input"
		@change="$emit('update:modelValue', value)"
	/>
	<span class="sc-radio__label">
		<!-- @slot Use for any text -->
		<slot/>
	</span>
</label>
