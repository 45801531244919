<template>
	<div
		ref="popup"
		:style="{ width }"
		:class="`sc-popup sc-popup_${padding}`"
	>
		<ScButton
			v-if="closable"
			view="flat"
			icon="close"
			class="sc-popup_close"
			data-test-id="popup-close"
			@click="$emit('cancel')"
		/>
		<!-- @slot Use for any content -->
		<slot name="default"/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
	import { onClickOutside } from '@vueuse/core';
	import { ScButton } from '../button';
	import useLayers from '../../composables/use-layers';

	/** The ScExamplePopup component is an interface element that is displayed on top of a web page in a browser **/
	export default defineComponent({
		name: 'ScPopup',
		components: { ScButton },
		emits: [
			/** Cancel event */
			'cancel',
			/** Confirm event */
			'confirm',
		],
		props: {
			/** Set width */
			width: {
				type: String,
				default: null,
			},
			/** Set padding */
			padding: {
				type: String as PropType<'20' | '50'>,
				default: '50',
				validator: (value: string) => ['20', '50'].includes(value),
			},
			/** Set closable */
			closable: {
				type: Boolean,
				default: true,
			}
		},
		setup(props, { emit }) {
			const layers = useLayers();
			const popup = ref<HTMLDivElement>();

			const closePopup = () => {
				if (!layers.isTopLayer(popup)) {
					return;
				}
				emit('cancel');
			};

			const onKeyDown = (e: KeyboardEvent) => {
				if ((e.key === 'Escape' || e.key === 'Esc') && props.closable) {
					closePopup();
				}
			};

			onMounted(() => {
				layers.add(popup);
				document.addEventListener('keydown', onKeyDown);
			});

			onUnmounted(() => {
				layers.pop();
				document.removeEventListener('keydown', onKeyDown);
			});

			onClickOutside(popup, (e: PointerEvent) => {
				if ((e.target as HTMLElement).closest('.sc-notification') || !props.closable) {
					return;
				}
				closePopup();
			});

			return { popup };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";

	.sc-popup {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		text-align: left;
		vertical-align: middle;
		min-width: 300px;
		max-width: 90vw;
		background: @white;
		box-shadow: 0 0 2px rgba(44, 36, 64, 0.48), 0 4px 10px rgba(44, 36, 64, 0.16);
		border-radius: 12px;
		box-sizing: border-box;

		&_20 {
			padding: 20px;
		}

		&_50 {
			padding: 50px 50px 60px 50px;
		}

		.screen-lt-480({
			justify-content: center;
			min-width: 100vw;
			min-height: 100vh;
			border-radius: 0;
			box-shadow: none;
			padding: 20px;
		});

		&_close {
			position: absolute;
			top: 20px;
			right: 20px;

			.screen-lt-480({
				top: 10px;
				right: 10px;
			});
		}
	}
</style>
