<template>
	<div
		:class="{
			'sc-textarea': true,
			[`sc-textarea_size-${size}`]: true,
			[`sc-textarea_${view}`]: true,
			'sc-textarea_focused': focused,
			'sc-textarea_disabled': disabled,
			'sc-textarea_error': error,
			'sc-textarea_highlight': highlight,
			[$attrs.class]: $attrs.class,
		}"
	>
		<textarea
			ref="textarea"
			v-bind="{ ...$attrs, class: undefined }"
			v-model.trim="value"
			:disabled="disabled"
			:placeholder="placeholder"
			:readonly="readonly"
			class="sc-textarea__textarea"
			@blur="removeFocusStyles($event)"
			@change="$emit('change', $event)"
			@focus="setFocusStyles($event)"
			@keydown="$emit('keydown', $event)"
			@keypress="$emit('keypress', $event)"
			@keyup="$emit('keyup', $event)"
		/>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, onMounted, PropType, ref } from 'vue';

	/** Textarea component */
	export default defineComponent({
		name: 'ScTextarea',
		inheritAttrs: false,
		emits: [
			/** update value event */
			'update:modelValue',
			/** focus event */
			'focus',
			/** blur event */
			'blur',
			/** input event */
			'input',
			/** change input event */
			'change',
			/** keydown event */
			'keydown',
			/** keyup event */
			'keyup',
			/** keypress event */
			'keypress',
		],
		props: {
			/** v-model value */
			modelValue: {
				type: String,
				default: null,
			},
			/** Set size */
			size: {
				type: String as PropType<'56' | '72' | '88'>,
				default: '72',
				validator: (value: string) => ['56', '72', '88'].includes(value),
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set readonly state */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** Set autofocus state */
			autofocus: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set highlight state */
			highlight: {
				type: Boolean,
				default: false,
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set the view */
			view: {
				type: String as PropType<'simple' | 'background'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'background'].includes(value),
			},
		},
		setup(props, { emit }) {
			const textarea = ref<HTMLTextAreaElement>();
			const focused = ref(false);

			const value = computed({
				get() {
					return props.modelValue;
				},
				set(newValue) {
					emit('update:modelValue', newValue);
				},
			});

			const focus = (): void => {
				focused.value = true;
				textarea.value.focus();
			};

			const setFocusStyles = (e: Event) => {
				focused.value = true;
				emit('focus', e);
			};

			const removeFocusStyles = (e: Event) => {
				focused.value = false;
				emit('blur', e);
			};

			onMounted(() => {
				if (props.autofocus) {
					focus();
				}
			});

			return {
				value,
				textarea,
				focused,
				focus,
				setFocusStyles,
				removeFocusStyles,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";
	@import "../../styles/scroll-bar";

	.sc-textarea {
		display: flex;
		min-height: 56px;
		border-radius: 6px;
		box-sizing: border-box;
		box-shadow: none;
		transform: translate3d(0, 0, 0);

		&__textarea {
			flex: 1;
			min-width: 50px;
			resize: none;
			color: @mulberry-purple;
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			border: none;
			outline: none;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			background: transparent;
			text-overflow: ellipsis;
			overflow: auto;
			font-family: inherit;

			&::placeholder {
				color: @mulberry-purple-40;
			}

			.scroll-bar();
		}

		&_size-56 {
			height: 56px;
			padding: 4px 6px 4px 8px;

			.sc-textarea__textarea {
				padding: 0 2px 0 0;
			}
		}

		&_size-72 {
			height: 72px;
			padding: 4px 6px 4px 10px;

			.sc-textarea__textarea {
				padding: 4px 2px 4px 0;
			}
		}

		&_size-88 {
			height: 88px;
			padding: 4px 6px 4px 12px;

			.sc-textarea__textarea {
				padding: 8px 2px 8px 0;
			}
		}

		&:hover {
			.sc-textarea__textarea::placeholder {
				color: @mulberry-purple-new;
			}
		}

		&:active {
			.sc-textarea__textarea::placeholder {
				color: @mulberry-purple;
			}
		}

		&.sc-textarea_focused {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);

			&.sc-textarea_error {
				border-color: transparent;
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #fec5cc;
			}
		}

		&.sc-textarea_disabled {
			cursor: default;
			pointer-events: none;
		}

		&.sc-textarea_highlight {
			box-shadow: 0 0 0 4px rgba(98, 57, 198, 0.1);

			&.sc-textarea_background {
				background: @mulberry-purple-0;
			}

			&:hover {
				&.sc-textarea_background {
					background: @mulberry-purple-10;
				}
			}

			&.sc-textarea_error {
				border-color: transparent;
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #fec5cc;
			}
		}

		&.sc-textarea_simple {
			background: transparent;
			border: 1px solid @mulberry-purple-20;

			&:hover {
				border-color: @mulberry-purple-35;
			}

			&:active {
				border-color: @mulberry-purple-new;
			}

			&.sc-textarea_focused {
				border-color: transparent;
			}

			&.sc-textarea_error {
				border: 1px solid @lightish-red;

				&.sc-textarea_focused {
					border: 1px solid transparent;
				}
			}

			&.sc-textarea_disabled {
				border-color: @mulberry-purple-10;

				.sc-textarea__textarea::placeholder {
					color: @mulberry-purple-35;
					-webkit-text-fill-color: @mulberry-purple-35;
					opacity: 1;
				}

				.sc-textarea__textarea {
					color: @mulberry-purple-35;
				}
			}
		}

		&.sc-textarea_background {
			background: @mulberry-purple-0;

			&:hover {
				background: @mulberry-purple-10;
			}

			&:active {
				background: @mulberry-purple-20;
			}

			&.sc-textarea_focused {
				background: @fuchsia-blue-0;

				.sc-textarea__textarea::placeholder {
					color: @mulberry-purple-35;
					-webkit-text-fill-color: @mulberry-purple-35;
					opacity: 1;
				}
			}

			&.sc-textarea_error {
				background: @lightish-red-0;

				.sc-textarea__textarea::placeholder {
					color: @mulberry-purple-40;
					-webkit-text-fill-color: @mulberry-purple-40;
					opacity: 1;
				}

				&:hover {
					.sc-textarea__textarea::placeholder {
						color: @mulberry-purple-new;
						-webkit-text-fill-color: @mulberry-purple-new;
						opacity: 1;
					}
				}
			}

			&.sc-textarea_disabled {
				background: @mulberry-purple-0;

				.sc-textarea__textarea::placeholder {
					color: @mulberry-purple;
					-webkit-text-fill-color: @mulberry-purple-30;
					opacity: 1;
				}
			}
		}
	}
</style>
