<template>
	<div
		:class="{
			'sc-input': true,
			[`sc-input_${view}`]: true,
			[`sc-input_${size}`]: true,
			'sc-input_focused': focused,
			'sc-input_disabled': disabled,
			'sc-input_error': error,
			'sc-input_highlight': highlight,
			[$attrs.class]: $attrs.class,
		}"
		@click="focus()"
	>
		<ScIcon
			v-if="icon"
			:name="icon"
			size="16"
			class="sc-input__icon"
		/>
		<input
			ref="input"
			v-bind="{ ...$attrs, class: undefined }"
			v-model.trim="value"
			:disabled="disabled"
			:placeholder="placeholder"
			:readonly="readonly"
			:type="type"
			class="sc-input__input"
			@input="$emit('input', $event)"
			@change="$emit('change', $event)"
			@focus="setFocusStyles($event)"
			@blur="removeFocusStyles($event)"
			@keydown="$emit('keydown', $event)"
			@keypress="$emit('keypress', $event)"
			@keyup="$emit('keyup', $event)"
		/>
		<ScLoaderSpin
			v-if="response"
			:size="size === '28' ? '16' : '20'"
			data-testid="loader"
		/>
		<ScIcon
			v-if="canClearValue"
			name="circle-cross"
			size="16"
			class="sc-input__clear"
			@click.stop="clear()"
			@mousedown.native.prevent
		/>
		<div
			v-if="$slots.default"
			class="sc-input__content"
		>
			<!-- @slot Use for any content (icon, select, etc) -->
			<slot v-bind="{ disabled }" />
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, ref, onMounted, defineComponent, PropType, nextTick } from 'vue';
	import { ScIcon, IconNames } from '../icon';
	import { ScLoaderSpin } from '../loader';

	/** Input component */
	export default defineComponent({
		components: { ScIcon, ScLoaderSpin },
		name: 'ScInput',
		inheritAttrs: false,
		emits: [
			/** update value event */
			'update:modelValue',
			/** focus event */
			'focus',
			/** blur event */
			'blur',
			/** input event */
			'input',
			/** change input event */
			'change',
			/** keydown event */
			'keydown',
			/** keyup event */
			'keyup',
			/** keypress event */
			'keypress',
		],
		expose: ['focus'],
		props: {
			/** v-model value */
			modelValue: {
				type: [String, Number],
				default: null,
			},
			/** Set icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set type */
			type: {
				type: String,
				default: 'text',
			},
			/** Set font size */
			size: {
				type: String as PropType<'28' | '36' | '44'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44'].includes(value),
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set readonly state */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** Set autofocus state */
			autofocus: {
				type: Boolean,
				default: false,
			},
			/** Set autoselect state */
			autoselect: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set highlight state */
			highlight: {
				type: Boolean,
				default: false,
			},
			/** Set the view */
			view: {
				type: String as PropType<'simple' | 'background'>,
				default: 'background',
				validator: (value: string) => ['simple', 'background'].includes(value),
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
			/** Set clearable state */
			clearable: {
				type: Boolean,
				default: false,
			},
			/** Set response state */
			response: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const input = ref<HTMLInputElement>();

			const value = computed({
				get() {
					return props.modelValue;
				},
				set(newValue) {
					emit('update:modelValue', newValue);
				},
			});

			const focused = ref(false);

			const canClearValue = computed(() => {
				return focused.value && !props.disabled && !props.readonly && props.modelValue && props.clearable;
			});

			const clear = () => {
				emit('update:modelValue', null);
			};

			const focus = (): void => {
				if (props.disabled || props.readonly || !input.value) {
					return;
				}
				focused.value = true;
				nextTick(() => input.value.focus());
			};

			const select = (): void => {
				input.value.select();
			};

			const setFocusStyles = (e: Event) => {
				focused.value = true;
				emit('focus', e);
			};

			const removeFocusStyles = (e: Event) => {
				focused.value = false;
				emit('blur', e);
			};

			onMounted(() => {
				if (props.autofocus) {
					focus();
				}
				if (props.autoselect) {
					select();
				}
			});

			return {
				value,
				input,
				focused,
				canClearValue,
				clear,
				focus,
				setFocusStyles,
				removeFocusStyles,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-input {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		min-height: 28px;
		padding: 4px;
		border-radius: 6px;
		box-sizing: border-box;
		box-shadow: none;
		cursor: text;
		transform: translate3d(0, 0, 0);

		&__input {
			flex: 1;
			min-width: 50px;
			color: @mulberry-purple;
			margin: 0;
			padding: 1px 0 0;
			box-sizing: border-box;
			border: none;
			outline: none;
			font-size: 14px;
			font-weight: 400;
			height: 20px;
			line-height: 20px;
			background: transparent;
			text-overflow: ellipsis;

			&::placeholder {
				color: @mulberry-purple-40;
			}
		}

		&__icon {
			margin-right: 6.25px;
			width: 16px;
			height: 16px;
			--icon-color: @mulberry-purple-40;
		}

		&__clear {
			width: 16px;
			height: 16px;
			margin-left: 6px;
			cursor: pointer;
			--icon-color: @mulberry-purple-40;

			&:hover {
				--icon-color: @mulberry-purple-new;
			}

			&:active {
				--icon-color: @mulberry-purple;
			}
		}

		&__content {
			margin-left: 6px;
		}

		&_28 {
			height: 28px;
			padding: 4px 4px 4px 8px;
		}

		&_36 {
			height: 36px;
			padding: 8px 8px 8px 10px;
		}

		&_44 {
			height: 44px;
			padding: 12px;
		}

		&:hover {
			.sc-input__input::placeholder {
				color: @mulberry-purple-new;
			}
		}

		&.sc-input_disabled {
			cursor: default;
			pointer-events: none;

			.sc-input__input {
				color: @mulberry-purple-35;

				&::placeholder {
					color: @mulberry-purple-35;
					-webkit-text-fill-color: @mulberry-purple-35;
					opacity: 1;
				}
			}

			.sc-input__icon {
				--icon-color: @mulberry-purple-30;
			}
		}

		&.sc-input_error {
			border: 1px solid @lightish-red;
		}

		&.sc-input_focused {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);

			&.sc-input_error {
				border-color: transparent;
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #fec5cc;
			}
		}
	}

	/*** Highlight ***/

	.sc-input.sc-input_highlight {
		&.sc-input_simple,
		&.sc-input_background {
			box-shadow: 0 0 0 4px rgba(98, 57, 198, 0.1);
		}

		&.sc-input_background {
			background: @fuchsia-blue-0;
		}
	}

	.sc-input_simple {
		background: transparent;
		border: 1px solid @mulberry-purple-20;

		&:hover {
			border-color: @mulberry-purple-35;
		}

		&:active {
			border-color: @mulberry-purple-new;
		}

		&.sc-input_focused {
			border-color: transparent;
		}

		&.sc-input_disabled {
			border-color: @mulberry-purple-10;
		}
	}

	.sc-input_background {
		background: @mulberry-purple-0;
		border: 1px solid transparent;

		&:hover {
			background: @mulberry-purple-10;

			.sc-input__icon {
				--icon-color: @mulberry-purple-new;
			}
		}

		&:active {
			background: @mulberry-purple-20;

			.sc-input__icon {
				--icon-color: @mulberry-purple-50;
			}
		}

		&.sc-input_focused {
			background: @fuchsia-blue-0;

			.sc-input__icon {
				--icon-color: @mulberry-purple-50;
			}
		}

		&.sc-input_error {
			background: @lightish-red-0;

			.sc-input__icon {
				--icon-color: @lightish-red;
			}

			.sc-input__badge {
				color: @lightish-red-80;
				background: @lightish-red-10;
			}
		}

		&.sc-input_disabled {
			background: @mulberry-purple-0;
		}
	}
</style>
