
<ScPopup
	class="sc-popup-confirmation"
	data-test-id="popup-confirmation"
	@cancel="$emit('cancel')"
	@confirm="$emit('confirm', $event)"
>
	<div
		v-if="emoji"
		class="sc-popup-confirmation__emoji"
		data-test-id="popup-emoji"
	>
		{{ emoji }}
	</div>
	<ScPopupHeader>{{ title }}</ScPopupHeader>
	<ScText
		v-html="text"
		size="16"
		class="sc-popup-confirmation__content"
		data-test-id="popup-content"
	/>
	<ScPopupActions align="center">
		<ScButton
			size="36"
			view="simple"
			data-test-id="popup-cancel"
			@click="$emit('cancel')"
		>
			{{ cancelButton }}
		</ScButton>
		<ScButton
			v-focus
			size="36"
			view="cta-black"
			data-test-id="popup-confirm"
			@click="$emit('confirm', true)"
		>
			{{ confirmButton }}
		</ScButton>
	</ScPopupActions>
</ScPopup>
