<template>
	<ScInput
		v-bind="$attrs"
		:modelValue="modelValue"
		:size="size"
		:autofocus="autofocus"
		:type="isVisible ? 'text' : 'password' "
		:error="error"
		:view="view"
		:placeholder="placeholder"
		class="sc-input-password"
		@update:modelValue="$emit('update:modelValue', $event)"
	>
		<span
			class="sc-input-password__icon"
			@click.stop="isVisible = !isVisible"
			@mousedown.prevent
		>
			<ScIcon
				:name="isVisible ? 'eye-off' : 'eye-on'"
				size="16"
			/>
		</span>
	</ScInput>
</template>

<script lang="ts">
	import { ref, defineComponent, PropType } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import { ScInput } from './index';

	/** Input password component */
	export default defineComponent({
		components: { ScIcon, ScInput },
		name: 'ScInputPassword',
		inheritAttrs: false,
		emits: [
			/** update value event */
			'update:modelValue',
		],
		props: {
			/** v-model value */
			modelValue: {
				type: String,
				default: null,
			},
			/** Set font size */
			size: {
				type: String as PropType<'28' | '36' | '44'>,
				default: '36',
				validator: (value: string) => ['28', '36', '44'].includes(value),
			},
			/** Set autofocus state */
			autofocus: {
				type: Boolean,
				default: false,
			},
			/** Set error state */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set highlight state */
			highlight: {
				type: Boolean,
				default: false,
			},
			/** Set the view */
			view: {
				type: String as PropType<'simple' | 'background'>,
				default: 'background',
				validator: (value: string) => ['simple', 'background'].includes(value),
			},
			/** Set placeholder */
			placeholder: {
				type: String,
				default: null,
			},
		},
		setup() {
			const isVisible = ref(false);

			return { isVisible };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-input-password {
		&__icon {
			display: inline-block;
			cursor: pointer;
			--icon-color: @mulberry-purple-40;

			&:hover {
				--icon-color: @mulberry-purple-new;
			}

			&:active {
				--icon-color: @mulberry-purple;
			}
		}
	}
</style>
