<template>
	<ScDropdownLine
		:activated="activated"
		:disabled="option.disabled"
		:clickabled="true"
		:data-value="option.value"
		@click="onToggle"
	>
		<ScCheckbox
			class="sc-multiselect__checkbox"
			:modelValue="option.checked"
			:disabled="option.disabled"
			color="black"
			readonly
		>
			<!-- @slot Use for custom option content -->
			<slot
				v-if="$slots.option"
				name="option"
				v-bind="option"
			/>
			<ScText
				v-else
				class="sc-multiselect__line-text"
			>
				{{ option.text }}
			</ScText>
		</ScCheckbox>
	</ScDropdownLine>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ScText } from '../text';
	import { ScCheckbox } from '../checkbox';
	import { ScDropdownLine } from '../dropdown';
	import { MultiselectOptionRendering } from './option';

	export default defineComponent({
		name: 'ScMultiselectLine',
		components: { ScDropdownLine, ScCheckbox, ScText },
		props: {
			option: {
				type: Object as PropType<MultiselectOptionRendering>,
				required: true,
			},
			activated: {
				type: Boolean,
				required: true,
			},
		},
		setup(props, { emit }) {
			const onToggle = () => {
				if (props.option.disabled) {
					return;
				}
				emit('toggleOption', props.option);
			};

			return { onToggle };
		},
	});
</script>
