<template>
	<div :class="{
		'sc-notification': true,
		[`sc-notification_${type}`]: true,
		'sc-notification_cta': $slots.cta != null,
	}">
		<div class="sc-notification__icon">
			<ScIcon
				:name="type === 'alert' ? 'alert' : (type === 'success' ? 'check-solid' : 'info-solid')"
				color="white"
				size="14"
			/>
		</div>
		<div class="sc-notification__text">
			<ScText
				semibold
				color="white"
			>
				<!-- @slot Use for any text -->
				<slot/>
			</ScText>
		</div>
		<button
			v-if="$slots.cta != null"
			class="sc-notification__cta-button"
			@click="$emit('cta', $event)"
		>
			<!-- @slot Use for cta button text -->
			<slot name="cta"/>
		</button>
		<button
			class="sc-notification__close"
			data-test-id="notification-close"
			@click="$emit('close', $event)"
		>
			<ScIcon
				name="close"
				size="16"
				color="mulberry-purple-10"
			/>
		</button>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import ScText from '../text/text.vue';

	/** Component for user interaction with system notification. */
	export default defineComponent({
		name: 'ScNotification',
		components: { ScIcon, ScText },
		emits: [
			/** Click on Close button */
			'close',
			/** Click on CTA button */
			'cta',
		],
		props: {
			/** Set the visual design of the notification */
			type: {
				type: String as PropType<'info' | 'warning' | 'success' | 'alert'>,
				default: 'info',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/adaptive";
	@import "../../styles/colors";

	.sc-notification {
		position: relative;
		background: @mulberry-purple-50;
		padding: 6px 44px 6px 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 6px;
		box-sizing: border-box;
		width: fit-content;
		max-width: 910px;
		gap: 10px;
		overflow: hidden;

		&_cta {
			.screen-lt-480({
				flex-direction: column;
				align-items: flex-start;
				padding: 10px;
				max-width: 480px;
				width: 100%;
			});
		}

		&__icon {
			border-radius: 4px;
			width: 32px;
			height: 32px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}

		&__text {
			margin-right: 10px;
		}

		&_cta {
			.sc-notification__text {
				.screen-lt-480({
					margin-right: 0;
				});
			}
		}

		&__close {
			position: absolute;
			right: 6px;
			width: 28px;
			min-height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			border: none;
			border-radius: 4px;
			cursor: pointer;
			transition: background 100ms ease;

			&:hover {
				background: @mulberry-purple-45;
			}

			&:active {
				background: @mulberry-purple-40;
			}
		}

		&__cta-button {
			background: transparent;
			border: 1px solid @mulberry-purple-45;
			color: @white;
			border-radius: 6px;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 14px;
			font-family: Inter, sans-serif;
			font-weight: 600;
			line-height: 1;
			text-align: center;
			outline: none;
			white-space: nowrap;
			display: inline-flex;
			width: fit-content;
			align-items: center;
			justify-content: center;
			vertical-align: middle;
			min-height: 28px;
			padding: 4px 10px;
			transition: background 100ms ease, border-color 100ms;

			&:hover {
				background: @mulberry-purple-45;
				border-color: @mulberry-purple-45;
			}

			&:active {
				background: @mulberry-purple-35;
				border-color: @mulberry-purple-35;
			}
		}

		&_info .sc-notification__icon {
			background: @fuchsia-blue;
		}

		&_warning .sc-notification__icon {
			background: @radiation-carrot;
		}

		&_success .sc-notification__icon {
			background: @breathtaking-mint;
		}

		&_alert {
			.sc-notification__icon {
				background: @lightish-red-60;
			}

			.sc-notification__cta-button {
				background: @lightish-red-60;
				border-color: @lightish-red-60;

				&:hover {
					background: @lightish-red;
					border-color: @lightish-red;
				}

				&:active {
					background: @lightish-red-80;
					border-color: @lightish-red-80;
				}
			}
		}
	}
</style>
