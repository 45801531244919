
<div
	:class="{
		'sc-textarea': true,
		[`sc-textarea_size-${size}`]: true,
		[`sc-textarea_${view}`]: true,
		'sc-textarea_focused': focused,
		'sc-textarea_disabled': disabled,
		'sc-textarea_error': error,
		'sc-textarea_highlight': highlight,
		[$attrs.class]: $attrs.class,
	}"
>
	<textarea
		ref="textarea"
		v-bind="{ ...$attrs, class: undefined }"
		v-model.trim="value"
		:disabled="disabled"
		:placeholder="placeholder"
		:readonly="readonly"
		class="sc-textarea__textarea"
		@blur="removeFocusStyles($event)"
		@change="$emit('change', $event)"
		@focus="setFocusStyles($event)"
		@keydown="$emit('keydown', $event)"
		@keypress="$emit('keypress', $event)"
		@keyup="$emit('keyup', $event)"
	/>
</div>
