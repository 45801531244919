<template>
	<div
		:class="{
			'sc-tooltip': true,
			[`sc-tooltip_${active}`]: true,
			'sc-tooltip--disabled': !$slots.default,
		}"
	>
		<div
			ref="activator"
			class="sc-tooltip__activator"
			@touchstart="showTooltip"
			@touchend="hiddenTooltip"
			@mouseenter="showTooltip"
			@mouseleave="hiddenTooltip"
		>
			<!-- @slot use for the element that causes the tooltip to appear -->
			<slot name="activator"/>
		</div>
		<Teleport
			v-if="tooltipVisible && $slots.default"
			to="body"
		>
			<div
				ref="content"
				:class="`sc-tooltip__content sc-tooltip__content--${theme} sc-tooltip__content--${active}`"
				:style="{'max-width': width}"
			>
				<!-- @slot used for any content -->
				<slot name="default"/>
			</div>
		</Teleport>
	</div>
</template>

<script lang="ts">
	import { defineComponent, nextTick, onMounted, onUnmounted, PropType, ref } from 'vue';

	/** The ScTooltip used for short explanatory texts, if it is necessary to explain the details of the interface */
	export default defineComponent({
		name: 'ScTooltip',
		expose: ['showTooltip'],
		props: {
			/** Choose a color theme */
			theme: {
				type: String as PropType<'dark' | 'light'>,
				default: 'dark',
				validator: (value: string) => ['dark', 'light'].includes(value),
			},
			/** Choose active */
			active: {
				type: String as PropType<'always' | 'overflowed'>,
				default: 'always',
				validator: (value: string) => ['always', 'overflowed'].includes(value),
			},
			/** Set width */
			width: {
				type: String,
				default: '300px'
			}
		},
		setup(props) {
			const activator = ref<HTMLDivElement>();
			const content = ref<HTMLDivElement>();
			const tooltipVisible = ref(false);

			const updateTooltipPosition = () => {
				if (!activator.value || !content.value) {
					return;
				}
				const activatorCoords = activator.value.getBoundingClientRect();
				const contentCoords = content.value.getBoundingClientRect();

				if (!activatorCoords?.width || !contentCoords?.width) {
					return;
				}

				const offsetFromEdgeWindow = 20;
				const pointerHeight = 10;

				let left = activatorCoords.left + (activatorCoords.width / 2) - (contentCoords.width / 2);
				let top = activatorCoords.top - contentCoords.height - pointerHeight;

				if (top <= contentCoords.height * 2) {
					top = activatorCoords.top + activatorCoords.height + pointerHeight;
					content.value.classList.add('sc-tooltip__content--bottom');
				} else {
					content.value.classList.add('sc-tooltip__content--top');
				}

				const needRightPosition = activatorCoords.x - ((contentCoords.width / 2) - (activatorCoords.width / 2)) < offsetFromEdgeWindow;
				const needLeftPosition = activatorCoords.x + contentCoords.width > window.innerWidth - offsetFromEdgeWindow;

				if (needRightPosition) {
					left = activatorCoords.left + activatorCoords.width + pointerHeight;
					top = activatorCoords.top - (contentCoords.height / 2) + (activatorCoords.height / 2);
					content.value.classList.add('sc-tooltip__content--right');
					content.value.classList.remove('sc-tooltip__content--bottom');
					content.value.classList.remove('sc-tooltip__content--top');
				} else if (needLeftPosition) {
					top = activatorCoords.top - (contentCoords.height / 2) + (activatorCoords.height / 2);
					left = activatorCoords.left - contentCoords.width - pointerHeight;
					content.value.classList.add('sc-tooltip__content--left');
					content.value.classList.remove('sc-tooltip__content--bottom');
					content.value.classList.remove('sc-tooltip__content--top');
				}

				content.value.style.left = left + 'px';
				content.value.style.top = top + 'px';
			};

			const showTooltip = async () => {
				if (tooltipVisible.value || !activator.value) {
					return;
				}

				const isOverflow = activator.value.scrollWidth > activator.value.clientWidth;

				if (props.active === 'always' || (props.active === 'overflowed' && isOverflow)) {
					tooltipVisible.value = true;
					await nextTick();
					updateTooltipPosition();
				}
			};

			const hiddenTooltip = () => {
				if (tooltipVisible.value) tooltipVisible.value = false;
			};

			const scrollHandler = () => {
				hiddenTooltip();
			};

			onMounted(() => {
				document.addEventListener('scroll', scrollHandler);
				document.addEventListener('touchmove', scrollHandler);
			});

			onUnmounted(() => {
				document.removeEventListener('scroll', scrollHandler);
				document.removeEventListener('touchmove', scrollHandler);
			});

			return {
				activator,
				content,
				tooltipVisible,
				showTooltip,
				hiddenTooltip,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";
	@import "../../styles/adaptive";

	@arrow-size: 10px;
	@sideEquilateralTriangle: 7px;

	.sc-tooltip {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		position: relative;
		width: fit-content;
		overflow: visible;

		&.sc-tooltip_overflowed {
			display: initial;
			font-size: inherit;

			.sc-tooltip__activator {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&__activator {
			cursor: pointer;
		}

		&--disabled .sc-tooltip__activator {
			cursor: default;
		}

		&__content {
			position: fixed;
			display: inline-block;
			width: max-content;
			height: auto;
			box-sizing: border-box;
			min-height: 32px;
			padding: 8px;
			border-radius: 6px;
			opacity: 1;
			transition: 150ms transform, 150ms opacity;
			vertical-align: middle;
			box-shadow: 0 0 15px rgb(0 0 0 / 15%);
			z-index: 999999;
			font-size: 12px;
			top: -9999px;
			left: -9999px;

			&--overflowed {
				display: flex;
				align-items: center;
			}

			&::after {
				content: "";
				position: absolute;
				width: 8px;
				height: 4px;
			}

			&--dark {
				background-color: @mulberry-purple;
				color: @white;
				--text-color: @white;

				&.sc-tooltip__content::after {
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgOCA0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC43MDcxMSAzLjI5Mjg5TDggMEwwIDBMMy4yOTI4OSAzLjI5Mjg5QzMuNjgzNDIgMy42ODM0MiA0LjMxNjU4IDMuNjgzNDIgNC43MDcxMSAzLjI5Mjg5WiIgZmlsbD0iIzQxM0E1MyIvPgo8L3N2Zz4K)
				}
			}

			&--light {
				background-color: @white;
				color: @mulberry-purple;
				--text-color: @mulberry-purple;

				&.sc-tooltip__content::after {
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgOCA0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC43MDcxMSAzLjI5Mjg5TDggMEwwIDBMMy4yOTI4OSAzLjI5Mjg5QzMuNjgzNDIgMy42ODM0MiA0LjMxNjU4IDMuNjgzNDIgNC43MDcxMSAzLjI5Mjg5WiIgZmlsbD0iI0ZGRkZGRiIvPgo8L3N2Zz4K)
				}
			}

			&--right::after {
				top: calc(50% - 2px);
				left: -6px;
				transform: rotate(90deg);
			}

			&--left::after {
				top: calc(50% - 2px);
				right: -6px;
				transform: rotate(-90deg);
			}

			&--top::after {
				bottom: -4px;
				left: calc(50% - 4px);
			}

			&--bottom::after {
				top: -4px;
				left: calc(50% - 4px);
				transform: rotate(180deg);
			}
		}
	}
</style>
