<template>
	<label
		class="sc-radio"
		:class="{
			'sc-radio_checked': modelValue === value,
			'sc-radio_readonly': readonly,
			'sc-radio_disabled': disabled,
		}"
	>
		<input
			:value="value"
			:checked="modelValue === value"
			:disabled="disabled"
			type="radio"
			class="sc-radio__input"
			@change="$emit('update:modelValue', value)"
		/>
		<span class="sc-radio__label">
			<!-- @slot Use for any text -->
			<slot/>
		</span>
	</label>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	/** Input radio button component */
	export default defineComponent({
		name: 'ScRadio',
		props: {
			/** v-model value */
			modelValue: {
				type: [String, Number],
				default: null,
			},
			/** The value of each element */
			value: {
				type: [String, Number],
				default: null,
			},
			/** Set readonly state */
			readonly: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-radio {
		display: inline-block;
		position: relative;
		padding-left: 26px;
		min-height: 20px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 20px;
			box-shadow: 0 0 0 2px @mulberry-purple-30 inset;
			border-radius: 50%;
			transition: box-shadow 150ms ease-in-out;
		}

		&:hover {
			cursor: pointer;

			&:before {
				box-shadow: 0 0 0 2px @mulberry-purple-35 inset;
			}
		}

		&.sc-radio_checked {
			&:before {
				box-shadow: 0 0 0 6px @mulberry-purple-90 inset;
			}

			&:hover {
				&:before {
					box-shadow: 0 0 0 6px @mulberry-purple-70 inset;
				}
			}
		}

		.sc-radio__input {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			outline: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		.sc-radio__label {
			display: inline-block;
			height: 100%;
			min-height: 20px;
			font-size: 14px;
			padding-top: 1px;
		}

		&.sc-radio_readonly {
			pointer-events: none;
		}

		&.sc-radio_centered:before {
			top: 50%;
			transform: translateY(-50%);
		}

		&.sc-radio_disabled {
			cursor: default;

			&:before {
				box-shadow: 0 0 0 2px @mulberry-purple-10 inset;
			}

			&.sc-radio_checked {
				&:before {
					box-shadow: 0 0 0 6px @mulberry-purple-35 inset;
				}
			}

			.sc-radio__label {
				color: @mulberry-purple-40;
			}
		}
	}
</style>
