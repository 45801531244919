<template>
	<Transition
		name="opacity"
		mode="out-in"
	>
		<slot/>
	</Transition>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({ name: 'ScTransitionOpacity' });
</script>

<style lang="less" scoped>
	.opacity-enter,
	.opacity-leave-to,
	.opacity-enter-from {
		opacity: 0;
	}

	.opacity-enter-active,
	.opacity-leave-active {
		transition: opacity 150ms linear;
	}
</style>
