
<div :class="{
	'sc-notification': true,
	[`sc-notification_${type}`]: true,
	'sc-notification_cta': $slots.cta != null,
}">
	<div class="sc-notification__icon">
		<ScIcon
			:name="type === 'alert' ? 'alert' : (type === 'success' ? 'check-solid' : 'info-solid')"
			color="white"
			size="14"
		/>
	</div>
	<div class="sc-notification__text">
		<ScText
			semibold
			color="white"
		>
			<!-- @slot Use for any text -->
			<slot/>
		</ScText>
	</div>
	<button
		v-if="$slots.cta != null"
		class="sc-notification__cta-button"
		@click="$emit('cta', $event)"
	>
		<!-- @slot Use for cta button text -->
		<slot name="cta"/>
	</button>
	<button
		class="sc-notification__close"
		data-test-id="notification-close"
		@click="$emit('close', $event)"
	>
		<ScIcon
			name="close"
			size="16"
			color="mulberry-purple-10"
		/>
	</button>
</div>
