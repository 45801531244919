<template>
	<span
		:class="{
			'sc-badge': true,
			[`sc-badge_${color}`]: true,
			'sc-badge_outlined': outline,
			'sc-badge_hoverable': closing || hoverable,
			'sc-badge_notification': notification,
			'sc-badge_disabled': disabled,
			'sc-badge_only-icon': icon && !slots.default && !closing
		}"
		@click.stop="$emit('click', $event)"
	>
		<span
			v-if="icon"
			class="sc-badge__icon"
		>
			<ScIcon
				:name="icon"
				size="14"
			/>
		</span>
		<span
			v-if="slots.default"
			class="sc-badge__text"
		>
			<!-- @slot Use for any text -->
			<slot/>
		</span>
		<span
			v-if="closing"
			class="sc-badge__close"
			@click.stop="$emit('remove', $event)"
		>
			<ScIcon
				name="close"
				size="16"
			/>
		</span>
	</span>
</template>

<script lang="ts">
	import { defineComponent, PropType, useSlots } from 'vue';
	import ScIcon from '../icon/icon.vue';
	import { IconNames } from '../icon';
	import { BadgeColors } from './colors';

	/**
	 * The ScBadge component is used to convey small pieces of information.
	 * Using the closing property, the ScBadge becomes interactive, allowing user interaction.
	 * */
	export default defineComponent({
		name: 'ScBadge',
		components: { ScIcon },
		emits: [
			/** Click event */
			'click',
			/** Remove event */
			'remove',
		],
		props: {
			/** Set outline state only for color grey */
			outline: {
				type: Boolean,
				default: false,
			},
			/** Set close icon */
			closing: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set color */
			color: {
				type: String as PropType<BadgeColors>,
				default: 'purple',
			},
			/** Set hover state */
			hoverable: {
				type: Boolean,
				default: false,
			},
			/** Set notification state */
			notification: {
				type: Boolean,
				default: false,
			},
		},
		setup() {
			const slots = useSlots();

			return { slots };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-badge {
		position: relative;
		display: inline-flex;
		align-items: center;
		gap: 4px;
		padding: 2px 4px;
		border-radius: 4px;
		white-space: nowrap;
		max-width: 100%;
		border: 1px solid transparent;
		box-sizing: border-box;
		cursor: default;
		pointer-events: none;
		transition: background-color 200ms ease, border-color 200ms ease;
		height: 20px;

		&__text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
		}

		&__close {
			display: inline-flex;
			align-items: center;
			height: 100%;
			cursor: pointer;
		}

		&__icon {
			display: inline-flex;
			align-items: center;
			height: 100%;
		}

		&_only-icon {
			padding: 2px 2px;
		}

		&_hoverable {
			pointer-events: auto;
		}

		&_notification:after {
			content: '';
			position: absolute;
			border: 2px solid @white;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: @lightish-red;
			z-index: 3;
			right: -6px;
			top: -6px;
			box-sizing: content-box;
		}

		&_black {
			color: @mulberry-purple-10;
			background: @mulberry-purple-90;
			--icon-color: @mulberry-purple-20;

			&:hover {
				background: @mulberry-purple-70;
			}

			&:active {
				background: @mulberry-purple-100;
			}
		}

		&_white {
			color: @mulberry-purple-0;
			background: inherit;
			border-color: @mulberry-purple-0;
			--icon-color: @mulberry-purple-10;

			&:hover {
				border-color: @mulberry-purple-35;
			}

			&:active {
				border-color: @mulberry-purple-new;
			}
		}

		&_purple {
			color: @fuchsia-blue-80;
			background: @fuchsia-blue-10;
			--icon-color: @fuchsia-blue-70;

			&:hover {
				background: @fuchsia-blue-20;
			}

			&:active {
				background: @fuchsia-blue-30;
			}
		}

		&_purple-light {
			color: @fuchsia-blue;
			background: @fuchsia-blue-0;
			--icon-color: @fuchsia-blue-40;

			&:hover {
				background: @fuchsia-blue-10;
				color: @fuchsia-blue-80;
				--icon-color: @fuchsia-blue-70;
			}

			&:active {
				background: @fuchsia-blue-20;
			}
		}

		&_pink {
			color: @pink-insanity-80;
			background: @pink-insanity-10;
			--icon-color: @pink-insanity-70;

			&:hover {
				background: @pink-insanity-20;
			}

			&:active {
				background: @pink-insanity-30;
			}
		}

		&_green {
			color: @artificial-turf-90;
			background: @artificial-turf-10;
			--icon-color: @artificial-turf-80;

			&:hover {
				background: @artificial-turf-20;
			}

			&:active {
				background: @artificial-turf-30;
			}
		}

		&_blue {
			color: @denim-80;
			background: @denim-10;
			--icon-color: @denim-70;

			&:hover {
				background: @denim-20;
			}

			&:active {
				background: @denim-30;
			}
		}

		&_yellow {
			color: @radiation-carrot-90;
			background: @radiation-carrot-10;
			--icon-color: @radiation-carrot-80;

			&:hover {
				background: @radiation-carrot-20;
			}

			&:active {
				background: @radiation-carrot-30;
			}
		}

		&_red {
			color: @lightish-red-80;
			background: @lightish-red-10;
			--icon-color: @lightish-red-70;

			&:hover {
				background: @lightish-red-20;
			}

			&:active {
				background: @lightish-red-30;
			}
		}

		&_grey {
			color: @mulberry-purple;
			background: @mulberry-purple-20;
			--icon-color: @mulberry-purple-50;

			&:hover {
				background: @mulberry-purple-30;
			}

			&:active {
				background: @mulberry-purple-35;
			}

			&.sc-badge_outlined {
				border-color: @mulberry-purple-20;

				&:hover {
					border-color: @mulberry-purple-35;
				}

				&:active {
					border-color: @mulberry-purple-new;
				}
			}

			&.sc-badge_disabled {
				pointer-events: none;
				color: @mulberry-purple-40;
				--icon-color: @mulberry-purple-40;
			}
		}

		&_grey-light {
			color: @mulberry-purple-50;
			background: @mulberry-purple-10;
			--icon-color: @mulberry-purple-45;

			&:hover {
				background: @mulberry-purple-20;
				color: @mulberry-purple;
				--icon-color: @mulberry-purple-50;
			}

			&:active {
				background: @mulberry-purple-30;
			}
		}

		&_mint {
			color: @breathtaking-mint-90;
			background: @breathtaking-mint-10;
			--icon-color: @breathtaking-mint-80;

			&:hover {
				background: @breathtaking-mint-20;
			}

			&:active {
				background: @breathtaking-mint-30;
			}
		}

		&_mint-black {
			color: @mulberry-purple;
			background: @breathtaking-mint-10;
			--icon-color: @mulberry-purple-50;

			&:hover {
				background: @breathtaking-mint-20;
			}

			&:active {
				background: @breathtaking-mint-30;
			}
		}

		&_outlined,
		&_outlined&_hoverable {
			background: transparent;
		}
	}
</style>
