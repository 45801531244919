<template>
	<ScDropdownLine
		:class="classes"
		:clickabled="true"
		:closing="true"
		:activated="activated"
		:disabled="disabled"
		@click="$emit('click', $event)"
	>
		<ScText :color="color">
			<!-- @slot Used for any content -->
			<slot/>
		</ScText>
	</ScDropdownLine>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType } from 'vue';
	import ScText from '../text/text.vue';
	import ScDropdownLine from './dropdown-line.vue';
	import { ColorNames } from '../../types/colors';


	/** The ScDropdownOption component used to set the color theme of individual list items */
	export default defineComponent({
		name: 'ScDropdownOption',
		components: { ScText, ScDropdownLine },
		emits: ['click'],
		props: {
			/** Set active state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set color text */
			color: {
				type: String as PropType<ColorNames>,
				default: null,
			},
			/** Set color theme */
			view: {
				type: String as PropType<'simple' | 'danger'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'danger'].includes(value),
			},
		},
		setup({ view }) {
			const classes = computed(() => ({
				'sc-dropdown-option': true,
				[`sc-dropdown-option--${view}`]: view,
			}));
			return { classes };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-dropdown-option {
		&--danger {
			--text-color: @lightish-red;
			color: @lightish-red;

			&:hover {
				background: @lightish-red-0;
			}
		}
	}
</style>
