
<div
	:class="`sc-popup-header sc-popup-header_${margin}`"
	data-test-id="popup-header"
>
	<ScH1>
	<!-- @slot Use for any header text -->
		<slot name="default"/>
	</ScH1>
	<!-- @slot Use for any actions content -->
	<slot
		v-if="$slots.actions != null"
		name="actions"
	/>
</div>
