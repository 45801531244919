<template>
	<div
		:class="classes"
		:tabindex="disabled ? null : 0"
	>
		<div
			v-if="canShowPlaceholder"
			class="sc-datepicker-label__placeholder"
		>
			<ScText color="mulberry-purple-40">{{ placeholder }}</ScText>
		</div>
		<div
			v-else-if="slots.default"
			class="sc-datepicker-label__custom"
		>
			<slot name="default" v-bind="{startValue, endValue, currentFormat}"/>
		</div>
		<div
			v-else
			class="sc-datepicker-label__content"
		>
			<div
				ref="optionsWrapper"
				class="sc-datepicker-label__option-wrapper"
			>
				<ScBadge
					v-show="startValue"
					closing
					color="grey"
					size="small"
					@remove="removeValue"
					@click.stop
				>
					{{ currentFormat }}
				</ScBadge>
			</div>
		</div>
		<ScIcon
			v-if="view !== 'flat'"
			color="mulberry-purple-40"
			name="calendar"
			size="16"
		/>
	</div>
</template>

<script lang="ts">
	import { computed, ref, defineComponent, PropType, useSlots } from 'vue';
	import ScBadge from '../badge/badge.vue';
	import ScIcon from '../icon/icon.vue';
	import ScText from '../text/text.vue';
	import { cultureName } from '../../i18n';

	export default defineComponent({
		name: 'ScDatePickerLabel',
		components: { ScBadge, ScIcon, ScText },
		emits: ['update:modelValue', 'update:startValue', 'update:endValue'],
		props: {
			/** Start date for range calendar */
			startValue: {
				type: Date,
				default: null,
			},
			/** End dates for range calendar */
			endValue: {
				type: Date,
				default: null,
			},
			/** Set disabled label */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set error label */
			error: {
				type: Boolean,
				default: false,
			},
			/** Set label placeholder */
			placeholder: {
				type: String,
				default: '',
			},
			/** Set datepicker open */
			isOpened: {
				type: Boolean,
				default: false,
				required: true,
			},
			/** Size label */
			size: {
				type: String,
				default: '36',
				validator: (value: string) => ['28', '36', '44'].includes(value),
			},
			/** Show time picker */
			time: {
				type: Boolean,
				default: false,
			},
			/** Two or one dates */
			range: {
				type: Boolean,
				default: false,
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'plate' | 'flat'>,
				default: 'simple',
				validator: (value: string) => ['simple', 'plate', 'flat'].includes(value),
			},
		},
		setup(props, { emit }) {
			const optionsWrapper = ref();
			const slots = useSlots();

			const classes = computed(() => ({
				'sc-datepicker-label': true,
				[`sc-datepicker-label--size-${props.size}`]: true,
				'sc-datepicker-label--disabled': props.disabled,
				'sc-datepicker-label--error': props.error,
				'sc-datepicker-label--pressed': props.isOpened,
				[`sc-datepicker-label_view-${props.view}`]: true,
			}));

			const isSameDateRange = computed(() => !props.time && props.startValue?.toDateString() === props.endValue?.toDateString());

			const formatDate = (date: Date) => {
				return props.time
					? new Date(date).toLocaleTimeString(cultureName.value, {
						year: 'numeric',
						month: 'short',
						day: '2-digit',
						hour: 'numeric',
						minute: '2-digit',
					})
					: new Date(date).toLocaleString(cultureName.value, {
						year: 'numeric',
						month: 'short',
						day: '2-digit',
					});
			};

			const currentFormat = computed(() => {
				if (!props.startValue) {
					return '';
				} else if (props.range && props.endValue) {
					return formatDate(props.startValue) + (isSameDateRange.value ? '' : ` - ${formatDate(props.endValue)}`);
				} else {
					return formatDate(props.startValue);
				}
			});

			const canShowPlaceholder = computed(() => props.placeholder && !currentFormat.value);
			const removeValue = () => {
				emit('update:startValue', null);

				if (props.endValue) {
					emit('update:endValue', null);
				}
			};
			return {
				removeValue,
				optionsWrapper,
				classes,
				currentFormat,
				canShowPlaceholder,
				slots,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-datepicker-label {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		position: relative;
		border: 1px solid @mulberry-purple-20;
		border-radius: 6px;
		box-sizing: border-box;
		transition: all .2s;
		outline: none;
		gap: 6px;

		&--size-28 {
			height: 28px;
			padding: 4px 8px 4px 8px;

		}

		&--size-36 {
			height: 36px;
			padding: 8px 10px 8px 10px;

		}

		&--size-44 {
			height: 44px;
			padding: 12px 12px 12px 12px;
		}

		&__placeholder {
			max-width: 100%;
		}

		&__placeholder .sc-text {
			color: @mulberry-purple-40;
			transition: color .2s;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:hover {
			border-color: @mulberry-purple-35;

			.sc-datepicker-label__placeholder .sc-text {
				color: @mulberry-purple-new;
			}
		}

		&&--pressed {
			border-color: @mulberry-purple-new;

			.sc-datepicker-label__placeholder .sc-text {
				color: @mulberry-purple;
			}
		}

		&&--error {
			border: 1px solid @lightish-red;

			&:focus {
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px #FEC5CC;
				border: 1px solid transparent;
			}
		}

		&&--disabled {
			border-color: @mulberry-purple-10;
			cursor: default;
			pointer-events: none;

			.sc-datepicker-label__placeholder .sc-text,
			.sc-datepicker-label__content .sc-text {
				color: @mulberry-purple-35;
			}

			.sc-datepicker-label__icon {
				--icon-color: @mulberry-purple-30;
			}
		}

		&:focus {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);
			border-color: transparent;
		}

		&__content {
			height: 20px;
			overflow: hidden;
			margin-right: auto;
			flex: 1;
		}

		&__option-wrapper {
			display: flex;
			align-items: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
		}

		&__count-options {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			padding: 2px 4px;
			background-color: @mulberry-purple;
			color: @white;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			border-radius: 4px;
			margin-left: 4px;
			cursor: pointer;
		}

		&__clear-button {
			align-self: center;
			margin-left: 4px;
		}

		&__custom {
			color: @mulberry-purple-45;
			font-weight: 600;
			white-space: nowrap;
		}

		&_view {
			&-plate {
				background-color: @mulberry-purple-20;
				border-color: @mulberry-purple-20;

				&:hover {
					background-color: @mulberry-purple-30;
					border-color: @mulberry-purple-30;
				}

				&.sc-datepicker-label--pressed {
					background-color: @mulberry-purple-35;
					border-color: @mulberry-purple-35;
				}
			}

			&-flat {
				background: transparent;
				color: @mulberry-purple-45;
				border: none;

				&:hover {
					background: @mulberry-purple-20;
				}

				&.sc-datepicker-label--pressed {
					background: @mulberry-purple-30;
				}
			}
		}
	}
</style>
