<template>
	<div
		:class="`sc-popup-actions sc-popup-actions_${align}`"
		data-test-id="popup-actions"
	>
		<!-- @slot Use for any buttons -->
		<slot name="default"/>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	/** The ScPopupActions component used to display a group of actions buttons **/
	export default defineComponent({
		name: 'ScPopupActions',
		props: {
			/** Set horizontal align */
			align: {
				type: String as PropType<'end' | 'between' | 'center'>,
				default: 'end',
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-popup-actions {
		position: sticky;
		bottom: 0;
		margin: 30px 0 -30px;
		padding-bottom: 30px;

		background: @white;
		display: flex;
		gap: 8px;
		align-items: center;

		&_end {
			justify-content: flex-end;
		}

		&_between {
			justify-content: space-between;
		}

		&_center {
			justify-content: center;
		}
	}
</style>
