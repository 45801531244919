
<div class="sc-time">
	<ScSelect
		:modelValue="hour"
		:options="isHour12 ? selectOptionsHours12 : selectOptionsHours24"
		:search="false"
		class="sc-time__select"
		size="28"
		view="plate-light"
		@update:modelValue="hour = $event;setTime()"
	/>
	<span>:</span>
	<ScSelect
		:modelValue="minute"
		:options="selectOptionsMinutes"
		:search="false"
		class="sc-time__select"
		size="28"
		view="plate-light"
		@update:modelValue="minute = $event;setTime()"
	/>

	<ScSelect
		v-if="isHour12"
		:modelValue="amPm"
		:options="selectOptionsAmPm"
		:search="false"
		class="sc-time__select half-day"
		size="28"
		view="flat"
		@update:modelValue="amPm = $event;setAmPm()"
	/>
</div>
