<template>
	<ScNotification
		:type="type"
		@cta="onCtaAction"
		@close="$emit('close')"
	>
		<template #default>{{ text }}</template>
		<template
			v-if="ctaText && ctaAction"
			#cta
		>
			{{ ctaText }}
		</template>
	</ScNotification>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ScNotification from './notification.vue';

export default defineComponent({
	components: { ScNotification },
	name: 'TextNotification',
	props: {
		type: {
			type: String as PropType<'info' | 'warning' | 'success' | 'alert'>,
			default: 'info',
		},
		text: {
			type: String as PropType<string>,
			default: '',
			required: true,
		},
		ctaText: {
			type: String,
			default: '',
		},
		ctaAction: {
			type: Function as PropType<() => void>,
			default: null,
		},
	},
	setup(props) {
		const onCtaAction = () => {
			if (props.ctaAction) {
				props.ctaAction();
			}
		};

		return { onCtaAction };
	}
});
</script>
