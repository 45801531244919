
<button
	:class="{
		'sc-button-smartwords': true,
		[`sc-button-smartwords_${view}`]: true,
		[`sc-button-smartwords_${size}`]: true,
		'sc-button-smartwords_activated': activated,
		'sc-button-smartwords_notification': notification,
	}"
	type="button"
	@click="$emit('click', $event)"
>
	<span class="sc-button-smartwords__icon">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
		>
			<g clip-path="url(#a)">
				<circle
					cx="8"
					cy="8"
					r="8"
					:fill="view === 'default' ? 'url(#b)' : (view === 'active' ? '#231D33' : '#FB3048')"
				/>
				<path
					:stroke="view === 'default' ? '#FAEDFE' : (view === 'active' ? '#F9F8F9' : '#FFECEE')"
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="1.2"
					d="M10 5.8A1.5 1.5 0 0 0 8.8 5H7.3a1.5 1.5 0 1 0 0 3h1.4a1.5 1.5 0 0 1 0 3H7.3a1.5 1.5 0 0 1-1.4-.8"
				/>
			</g>
			<defs>
				<linearGradient
					v-if="view === 'default'"
					id="b"
					x1="9.5"
					x2="9.5"
					y1="16"
					y2="0"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#8160D1"/>
					<stop
						offset="1"
						stop-color="#C010EF"
					/>
				</linearGradient>
				<clipPath id="a">
					<path
						fill="#fff"
						d="M0 0h16v16H0z"
					/>
				</clipPath>
			</defs>
		</svg>
	</span>
	<span
		v-if="$slots.default"
		class="sc-button-smartwords__text"
	>
		<!-- @slot Use for any text -->
		<slot name="default"/>
	</span>
</button>
