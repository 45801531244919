<template>
	<label
		class="sc-radio-card"
		:class="{
			'sc-radio-card_checked': modelValue === value,
			'sc-radio-card_with-radio': withRadio,
			[`sc-radio-card_${padding}`]: true,
			[`sc-radio-card_${view}`]: true,
			[`sc-radio-card_${corner}`]: true,
		}"
	>
		<input
			:value="value"
			:checked="modelValue === value"
			type="radio"
			class="sc-radio-card__input"
			@change="$emit('update:modelValue', value)"
		/>
		<span
			v-if="withRadio"
			class="sc-radio-card__custom-radio"
		/>
		<span class="sc-radio-card__label">
			<!-- @slot Use for any content -->
			<slot/>
		</span>
		<span
			v-if="slots.actions"
			class="sc-radio-card__actions"
			@click.prevent.stop
		>
			<!-- @slot Use for any content -->
			<slot name="actions"/>
		</span>
	</label>
</template>

<script lang="ts">
	import { defineComponent, PropType, useSlots } from 'vue';

	/** Custom radio button component */
	export default defineComponent({
		name: 'ScRadioCard',
		props: {
			/** v-model value */
			modelValue: {
				type: [String, Number],
				default: null,
			},
			/** The value of each element */
			value: {
				type: [String, Number],
				default: null,
			},
			/** Set size */
			padding: {
				type: String as PropType<'10' | '12'>,
				default: '12',
				validator: (value: string) => ['10', '12'].includes(value),
			},
			/** Add inner radio button */
			withRadio: {
				type: Boolean,
				default: false,
			},
			/** Set corner */
			corner: {
				type: String as PropType<'left' | 'right'>,
				default: 'left',
				validator: (value: string) => ['left', 'right'].includes(value),
			},
			/** Set view */
			view: {
				type: String as PropType<'simple' | 'background'>,
				default: 'background',
				validator: (value: string) => ['simple', 'background'].includes(value),
			},
		},
		setup() {
			const slots = useSlots();

			return {
				slots,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-radio-card {
		display: flex;
		flex-flow: row wrap;
		position: relative;
		padding: 12px 16px;
		min-height: 20px;
		box-shadow: 0 0 0 1px @mulberry-purple-20;
		border-radius: 6px;
		box-sizing: border-box;
		cursor: pointer;
		transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
		gap: 8px;

		&_right {
			flex-direction: row-reverse;
		}

		&__input {
			border: 0;
			clip: rect(0 0 0 0);
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			outline: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
		}

		&__custom-radio {
			align-items: center;
			display: flex;
			flex: none;
			height: 24px;
			justify-content: center;
			width: 24px;

			&:before {
				content: "";
				border-radius: 50%;
				box-shadow: 0 0 0 2px @mulberry-purple-40 inset;
				height: 20px;
				transition: box-shadow 100ms;
				width: 20px;
			}
		}

		&__label {
			flex: 1;
		}

		&__actions {
			width: 100%;
		}

		&:hover {
			background: @mulberry-purple-0;

			.sc-radio-card__custom-radio:before {
				box-shadow: 0 0 0 2px @mulberry-purple-new inset;
			}

			&.sc-radio-card_simple {
				background: transparent;
			}
		}

		&_10 {
			padding: 10px;
		}

		&_12 {
			padding: 12px 16px;

			&.sc-radio-card_with-radio {
				padding: 16px;
			}
		}

		&_checked {
			background: @mulberry-purple-0;
			box-shadow: 0 0 0 2px @mulberry-purple-90 inset;

			.sc-radio-card__custom-radio:before {
				box-shadow: 0 0 0 6px @mulberry-purple-90 inset;
			}

			&:hover {
				.sc-radio-card__custom-radio:before {
					box-shadow: 0 0 0 6px @mulberry-purple-70 inset;
				}
			}

			&.sc-radio-card_simple {
				background: transparent;
				box-shadow: 0 0 0 1px @mulberry-purple-20;
			}
		}
	}
</style>
