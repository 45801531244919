<template>
	<a
		v-bind="$attrs"
		:class="{
			'sc-link': true,
			[`sc-link_${weight}`]: true,
			[`sc-link_${color}`]: true,
			[`sc-link_${size}`]: true,
			[`sc-link_${border}`]: true,
			[`sc-link_disabled`]: disabled,
		}"
		@click="$emit('click', $event)"
	>
		<!-- @slot Use for any text -->
		<slot />
	</a>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';

	/** Renders tag A with the specified props & attributes options */
	export default defineComponent({
		name: 'ScLink',
		props: {
			/** Set link color */
			color: {
				type: String as PropType<
					| 'inherit'
					| 'fuchsia-blue'
					| 'white'
					| 'mulberry-purple'
					| 'mulberry-purple-40'
					| 'mulberry-purple-50'
					| 'mulberry-purple-new'
					| 'mulberry-purple-90'
				>,
				default: 'fuchsia-blue',
				validator: (value: string) =>
					[
						'inherit',
						'fuchsia-blue',
						'white',
						'mulberry-purple',
						'mulberry-purple-40',
						'mulberry-purple-50',
						'mulberry-purple-new',
						'mulberry-purple-90',
					].includes(value),
			},
			/** Set font size */
			size: {
				type: String as PropType<'inherit' | '10' | '12' | '14' | '16'>,
				default: '14',
				validator: (value: string) => ['inherit', '10', '12', '14', '16'].includes(value),
			},
			/** Set font weight */
			weight: {
				type: String as PropType<'inherit' | 'regular' | 'semibold' | 'bold'>,
				default: 'regular',
				validator: (value: string) => ['inherit', 'regular', 'semibold', 'bold'].includes(value),
			},
			/** Set border style */
			border: {
				type: String as PropType<'solid' | 'dashed' | 'none'>,
				default: 'solid',
				validator: (value: string) => ['solid', 'dashed', 'none'].includes(value),
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['click'],
	});
</script>

<style lang="less" scoped>
	@import '../../styles/colors';

	.sc-link {
		display: inline;
		text-underline-offset: 2px;
		text-decoration-color: currentColor;

		&,
		&:visited {
			cursor: pointer;
			transition: color 200ms ease, opacity 200ms ease;
			text-decoration: none;
		}

		&_inherit,
		&_inherit:visited {
			border-color: currentColor;
			color: inherit;
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;

			&:hover,
			&:active {
				opacity: 0.1;
			}
		}

		&_fuchsia-blue,
		&_fuchsia-blue:visited {
			color: @fuchsia-blue;

			&:hover {
				color: @fuchsia-blue-60;
				opacity: 1;
			}

			&:active {
				color: @fuchsia-blue-70;
			}

			&.sc-link_disabled {
				color: @fuchsia-blue-20;
			}
		}

		&_mulberry-purple,
		&_mulberry-purple:visited {
			color: @mulberry-purple;

			&:hover {
				color: @mulberry-purple-new;
				opacity: 1;
			}

			&:active {
				color: @mulberry-purple-100;
			}
		}

		&_mulberry-purple-40,
		&_mulberry-purple-40:visited {
			color: @mulberry-purple-40;

			&:hover {
				color: @mulberry-purple-45;
				opacity: 1;
			}

			&:active {
				color: @mulberry-purple-70;
			}
		}

		&_mulberry-purple-50,
		&_mulberry-purple-50:visited {
			color: @mulberry-purple-50;

			&:hover {
				color: @mulberry-purple-new;
				opacity: 1;
			}

			&:active {
				color: @mulberry-purple-100;
			}
		}

		&_mulberry-purple-new,
		&_mulberry-purple-new:visited {
			color: @mulberry-purple-new;

			&:hover {
				color: @mulberry-purple-50;
				opacity: 1;
			}

			&:active {
				color: @mulberry-purple-80;
			}
		}

		&_mulberry-purple-90,
		&_mulberry-purple-90:visited {
			color: @mulberry-purple-90;

			&:hover {
				color: @mulberry-purple-new;
				opacity: 1;
			}

			&:active {
				color: @mulberry-purple-100;
			}
		}

		&_white,
		&_white:visited {
			color: @white;

			&:hover {
				color: @mulberry-purple-35;
			}

			&:active {
				color: @mulberry-purple-new;
			}
		}

		&_regular {
			font-weight: 400;
		}

		&_semibold {
			font-weight: 600;
		}

		&_bold {
			font-weight: 700;
		}

		&_10 {
			font-size: 10px;
			line-height: 14px;
		}

		&_12 {
			font-size: 12px;
			line-height: 16px;
		}

		&_14 {
			font-size: 14px;
			line-height: 20px;
		}

		&_16 {
			font-size: 16px;
			line-height: 24px;
		}

		&_solid {
			text-decoration: underline;
		}

		&_dashed {
			text-decoration: underline dashed;
		}

		&_none {
			text-decoration: none;
		}

		&.sc-link_disabled {
			color: @mulberry-purple-35;
			cursor: default;
			pointer-events: none;
		}
	}
</style>
