<template>
	<div
		ref="optionsList"
		class="sc-datepicker__calendar"
	>
		<div class="sc-datepicker__header">
			<ScButton
				:disabled="isDisabledLeft"
				icon="chevron-left"
				iconSize="10"
				size="28"
				view="flat"
				@click="setPrevMonth"
			/>
			<div class="sc-datepicker__header-select">
				<ScSelect
					:modelValue="month"
					:options="selectOptionsMonth"
					:search="false"
					class="sc-datepicker__options-select"
					size="28"
					view="flat"
					@update:modelValue="setMonth"
				/>
				<ScSelect
					:modelValue="year"
					:options="selectOptionsYears"
					:search="false"
					class="sc-datepicker__options-select"
					size="28"
					view="flat"
					@update:modelValue="setYear"
				/>
			</div>
			<ScButton
				:disabled="isDisabledRight"
				icon="chevron-right"
				iconSize="10"
				size="28"
				view="flat"
				@click="setNextMonth"
			/>
		</div>
		<div class="sc-datepicker__week">
			<ScText
				v-for="day in allDaysOfWeek"
				:key="day"
				class="sc-datepicker__week-day-name"
				weight="semibold"
			>
				{{ day }}
			</ScText>
		</div>
		<div class="sc-datepicker__days">
			<div
				v-for="(cal, i) in calendar"
				:key="i"
				:class="[
					'sc-datepicker__date',
					{'sc-datepicker__date--blur': cal.shadowed},
					{'sc-datepicker__date--disabled': cal.disabledClick},
					{[`active-${cal.active}`]: cal.active},
					{'current': cal.current},
					{'hide': cal.hide},
					{'interval': cal.interval},
					{'other-months': cal.otherMonth}
				]"
				@click="setDay(cal)"
			>
				<ScText>
					{{ cal.date }}
				</ScText>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
	import { defineComponent, ref, computed } from 'vue';
	import { SelectOption } from '../select/option';
	import ScText from '../text/text.vue';
	import ScSelect from '../select/select.vue';
	import ScButton from '../button/button.vue';
	import { cultureName } from '../../i18n';

	interface ICalendar {
		currentYear: any;
		date: number,
		shadowed: boolean,
		hide: boolean,
		current?: boolean
		currentMonth: number,
		active: string
		interval?: boolean,
		disabledClick: Boolean,
		value: Date,
		otherMonth: Boolean
	}

	interface ILocale extends Intl.Locale {
		hourCycles: string,
		weekInfo: IWeekInfo,
	}

	interface IWeekInfo {
		firstDay: number,
	}

	/** ScCalendar component */
	export default defineComponent({
		name: 'ScCalendar',
		components: {
			ScText,
			ScSelect,
			ScButton,
		},
		props: {
			/** Start date for range and single calendar*/
			startValue: {
				type: Date,
				default: null,
			},
			/** End dates for range calendar */
			endValue: {
				type: Date,
				default: null,
			},
			/** Set range for two dates */
			range: {
				type: Boolean,
				default: false,
			},
			/** Set minimal choose date*/
			minDate: {
				type: Date,
				default: null,
			},
			/** Set maximum choose date*/
			maxDate: {
				type: Date,
				default: null,
			},
		},
		setup(props, { emit }) {
			const locale = new Intl.Locale(cultureName.value) as ILocale;
			const currentDate = new Date();
			const year = ref<number>(props.startValue ? props.startValue.getFullYear() : currentDate.getFullYear());
			const month = ref<number>(props.startValue ? props.startValue.getMonth() : currentDate.getMonth());
			const day = ref(props.startValue ? props.startValue.getDate() : currentDate.getDate());

			const daysInMonth = computed(() => getDaysInMonth(year.value, month.value + 1, 0));
			const minHour = computed(() => props.minDate?.getHours());
			const maxHour = computed(() => props.maxDate?.getHours());
			const isMinYear = computed(() => props.minDate && props.minDate?.getFullYear() === year.value);
			const isMaxYear = computed(() => props.maxDate && props.maxDate?.getFullYear() === year.value);

			const selectOptionsYears = computed<SelectOption[]>(() => {
				const defaultSizeYears = 10;
				let arr = createArray(20).map((_, index) => {
					const optionYear = year.value + (index - defaultSizeYears);
					return {
						text: String(optionYear),
						value: optionYear,
					};
				});

				if (props.minDate) {
					arr = arr.filter(options => options.value >= props.minDate.getFullYear());
				}

				if (props.maxDate) {
					arr = arr.filter(options => options.value <= props.maxDate.getFullYear());
				}
				return arr;
			});

			const selectOptionsMonth = computed<SelectOption[]>(() => {
				let arr = createArray(12).map((_, index) => {
					let nameOfMonth = new Date(year.value, index).toLocaleString(cultureName.value, { month: 'short' });
					nameOfMonth = nameOfMonth[0].toUpperCase() + nameOfMonth.slice(1);
					return {
						text: nameOfMonth,
						value: index,
					};
				});

				if (isMinYear.value) {
					arr = arr.filter(month => month.value >= props.minDate.getMonth());
				}

				if (isMaxYear.value) {
					arr = arr.filter(month => month.value <= props.maxDate.getMonth());
				}
				return arr;
			});

			const isStartWeekSunday = computed(() => {
				return locale.baseName === 'en' || locale.baseName === 'ja' || locale.baseName === 'pt-BR' || locale.baseName === 'zh-Hans';
			});

			const allDaysOfWeek = computed(() => {
				const daysArr = [];

				const localeStartDay = isStartWeekSunday.value ? 7 : 1;

				for (let i = 0; i < 7; i++) {
					const currentDay = (localeStartDay + i) % 7 + 1;

					let nameOfDay = new Date(2021, 7, currentDay).toLocaleString(cultureName.value, { weekday: 'short' });
					nameOfDay = nameOfDay[0].toUpperCase() + nameOfDay.slice(1);
					daysArr.push(nameOfDay);
				}
				return daysArr;
			});

			const calendar = computed(() => {
				let dayOfWeek = new Date(year.value, month.value, 1).getDay();

				if (dayOfWeek === 0) {
					dayOfWeek = 7;
				}
				if (!isStartWeekSunday.value) {
					dayOfWeek--;
				}

				const allDaysOfPrevMonth = [];
				const totalDaysOfPrevMonth = getDaysInMonth(year.value, month.value, 0);
				for (let i = 1; i <= totalDaysOfPrevMonth; i++) {
					allDaysOfPrevMonth.push(i);
				}

				const lastDays = allDaysOfPrevMonth.slice(-dayOfWeek);

				const arr: ICalendar[] = [];

				for (let i = 0; i < lastDays.length; i++) {
					if (lastDays.length >= 7) continue;
					const el = {
						date: lastDays[i],
						shadowed: false,
						hide: false,
						active: colClass(new Date(Date.UTC(year.value, month.value - 1, lastDays[i], 0, 0))),
						interval: props.endValue && props.range ? isIntervalDate(new Date(Date.UTC(year.value, month.value - 1, lastDays[i]))) : null,
						currentYear: year.value,
						currentMonth: month.value - 1,
						disabledClick: checkMinDate(new Date(Date.UTC(year.value, month.value - 1, lastDays[i]))) ||
							checkMaxDate(new Date(Date.UTC(year.value, month.value - 1, lastDays[i]))),
						value: new Date(Date.UTC(year.value, month.value - 1, lastDays[i])),
						otherMonth: true,
					};
					arr.push(el);
				}
				for (let i = 1; i <= daysInMonth.value; i++) {
					const currentDate = new Date();
					const el = {
						date: i,
						shadowed: false,
						hide: false,
						current: new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).getTime()
							=== new Date(Date.UTC(year.value, month.value, i)).getTime(),
						currentYear: year.value,
						currentMonth: month.value,
						active: colClass(new Date(Date.UTC(year.value, month.value, i, 0, 0))),
						interval: props.endValue && props.range ? isIntervalDate(new Date(Date.UTC(year.value, month.value, i))) : null,
						disabledClick: checkMinDate(new Date(Date.UTC(year.value, month.value, i, 0, 0))) ||
							checkMaxDate(new Date(Date.UTC(year.value, month.value, i, 0, 0))),
						value: new Date(year.value, month.value, i),
						otherMonth: false,
					};
					arr.push(el);
				}
				for (let i = 1; arr.length < checkArrLength(arr.length); i++) {
					const el = {
						date: i,
						shadowed: false,
						hide: false,
						currentYear: year.value,
						currentMonth: month.value + 1,
						interval: props.endValue && props.range ? isIntervalDate(new Date(Date.UTC(year.value, month.value + 1, i))) : null,
						active: colClass(new Date(Date.UTC(year.value, month.value + 1, i, 0, 0))),
						disabledClick: checkMinDate(new Date(Date.UTC(year.value, month.value + 1, i, 0, 0))) ||
							checkMaxDate(new Date(Date.UTC(year.value, month.value + 1, i, 0, 0))),
						value: new Date(year.value, month.value + 1, i),
						otherMonth: true,
					};
					arr.push(el);
				}

				return arr;
			});

			const isDisabledLeft = computed(() => isMinYear.value && month.value <= props.minDate.getMonth());

			const isDisabledRight = computed(() => isMaxYear.value && month.value >= props.maxDate.getMonth());

			const getDaysInMonth = (year: number, month: number, day: number) => new Date(year, month, day).getDate();

			const createArray = (length: number) => {
				return Array.from({ length }, (_, index) => index + 1);
			};

			const checkMinDate = (date: Date) => {
				if (props.minDate == null) {
					return false;
				}

				const minDateToDisabled = new Date(Date.UTC(props.minDate?.getFullYear(), props.minDate?.getMonth(), props.minDate?.getDate()));
				return date.getTime() < minDateToDisabled.getTime();
			};

			const checkMaxDate = (date: Date) => {
				if (props.maxDate == null) {
					return false;
				}

				const maxDateToDisabled = new Date(Date.UTC(props.maxDate?.getFullYear(), props.maxDate?.getMonth(), props.maxDate?.getDate()));
				return date.getTime() > maxDateToDisabled.getTime();
			};
			const colClass = (date: Date) => {
				const startDate = props.startValue ? new Date(Date.UTC(props.startValue?.getFullYear(), props.startValue?.getMonth(), props.startValue?.getDate())) : null;
				const endDate = props.endValue ? new Date(Date.UTC(props.endValue.getFullYear(), props.endValue.getMonth(), props.endValue.getDate())) : null;
				const isOnlyStartDate = props.range && !endDate && date.getTime() === startDate?.getTime();
				const isStartDateSameEndDate = date.getTime() === startDate?.getTime() && date.getTime() === endDate?.getTime();
				const isSimpleDate = !props.range && date.getTime() === startDate?.getTime();

				if (isOnlyStartDate || isStartDateSameEndDate || isSimpleDate) {
					return 'single';
				}

				if (props.endValue && date.getTime() === startDate?.getTime() && props.startValue?.getTime() > props.endValue?.getTime()) {
					return 'end';
				} else if (props.range && date.getTime() === startDate?.getTime()) {
					return 'start';
				}

				if (props.endValue && date.getTime() === endDate?.getTime() && props.startValue?.getTime() > props.endValue?.getTime()) {
					return 'start';
				} else if (props.range && date.getTime() === endDate?.getTime()) {
					return 'end';
				}
				return '';
			};
			const checkArrLength = (arrLength: number): number => {
				if (arrLength === 28 && props.range) {
					return 28;
				}
				return 42;
			};

			const isIntervalDate = (date: Date) => {
				const startDate = props.startValue ? new Date(Date.UTC(props.startValue.getFullYear(), props.startValue.getMonth(), props.startValue.getDate())) : null;
				const endDate = props.endValue ? new Date(Date.UTC(props.endValue.getFullYear(), props.endValue.getMonth(), props.endValue.getDate())) : null;
				if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
					return endDate?.getTime() < date.getTime() && date.getTime() < startDate?.getTime();
				} else {
					return startDate?.getTime() < date.getTime() && date.getTime() < endDate?.getTime();
				}
			};

			const setYear = (value: number) => {
				year.value = value;

				if (isMaxYear.value && props.maxDate) {
					month.value = month.value < props.maxDate.getMonth() ? month.value : props.maxDate.getMonth();
				}
				if (isMinYear.value && props.minDate) {
					month.value = month.value > props.minDate.getMonth() ? month.value : props.minDate.getMonth();
				}
			};

			const setMonth = (value: number) => {
				month.value = value;
			};

			const setPrevMonth = () => {
				if (month.value === 0) {
					year.value--;
					month.value = 11;
				} else {
					month.value--;
				}
			};

			const setNextMonth = () => {
				if (month.value === 11) {
					year.value++;
					month.value = 0;
				} else {
					month.value++;
				}
			};

			const setDay = (cal: ICalendar) => {
				const currentDate = props.startValue ? new Date(props.startValue.getFullYear(), props.startValue.getMonth(), props.startValue.getDate()) : null;
				const newDate = new Date(cal.currentYear, cal.currentMonth, cal.date);

				if (props.range) {
					if (!props.startValue) {
						emit('update:startValue', cal.value);
						return;
					}

					if (props.startValue && newDate >= currentDate) {
						emit('update:endValue', cal.value);
					} else {
						emit('update:startValue', cal.value);
						emit('update:endValue', currentDate);
					}

					if (props.startValue && props.endValue) {
						emit('update:startValue', cal.value);
						emit('update:endValue', null);
					}
				} else {
					day.value = cal.date;
					emit('update:startValue', cal.value);
				}
				month.value = cal.value.getMonth();
				year.value = cal.value.getFullYear();
			};

			return {
				allDaysOfWeek,
				selectOptionsMonth,
				selectOptionsYears,
				calendar,
				isDisabledLeft,
				isDisabledRight,
				month,
				year,
				minHour,
				maxHour,
				day,
				setMonth,
				setYear,
				setNextMonth,
				setPrevMonth,
				setDay,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-datepicker__calendar {
		width: 252px;
	}

	.sc-datepicker__days {
		display: grid;
		grid-template-columns: repeat(7, 36px);
	}

	.sc-datepicker__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-select {
			display: flex;
			gap: 10px;
		}
	}

	.sc-datepicker__options-select {
		width: fit-content;

		.sc-select__activator {
			margin: 10px;

			.sc-select-label {
				border: none;
			}
		}
	}

	.sc-datepicker__week {
		display: flex;

		&-day-name {
			display: flex;
			align-items: center;
			justify-content: space-around;
			pointer-events: none;
			height: 36px;
			width: 36px;
		}
	}

	.sc-datepicker__date {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		padding: 8px 0;
		color: @mulberry-purple;
		border-radius: 6px;
		cursor: pointer;

		&.current {
			background: @mulberry-purple-20;

			&:hover {
				background: @mulberry-purple-30;
			}
		}

		&.other-months {
			--text-color: @mulberry-purple-40;

			&.sc-datepicker__date--disabled {
				--text-color: @mulberry-purple-35;
			}
		}

		&:hover {
			background: @mulberry-purple-20;
		}

		&.interval {
			border-radius: 0;
			background: @mulberry-purple-10;

			&:hover {
				background: @mulberry-purple-20;
			}

			&.current {
				background: @mulberry-purple-20;
			}
		}

		&--blur,
		&--disabled {
			pointer-events: none;
		}

		&--blur {
			--text-color: @mulberry-purple-40;
		}

		&--disabled {
			--text-color: @mulberry-purple-35;
		}

		&.active-start,
		&.active-end,
		&.active-single {
			background: @mulberry-purple-90;
			--text-color: @white;

			&.current {
				background: @mulberry-purple-90;
				--text-color: @white;
			}

			&:hover {
				background: @mulberry-purple-70;
			}
		}

		&.active-start {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		&.active-end {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}

		&.hide {
			opacity: 0;
			cursor: default;
		}
	}

	.sc-datepicker__date--disabled {
		pointer-events: none;
		--text-color: @mulberry-purple-35;
	}
</style>
