<template>
	<div class="sc-multiselect-radio">
		<ScDropdownLine
			:activated="activated"
			:disabled="option.disabled"
			:clickabled="true"
			:data-text="option.text"
			@click="isChecked = !isChecked"
		>
			<ScCheckbox
				class="sc-multiselect__checkbox"
				:modelValue="isChecked"
				:disabled="option.disabled"
				color="black"
				readonly
			>
				<ScText class="sc-multiselect__line-text">{{ option.text }}</ScText>
			</ScCheckbox>
		</ScDropdownLine>
		<ScDropdownLine
			v-for="radio in option.value"
			:key="radio.value"
			:clickabled="true"
			:disabled="!isChecked"
			:data-value="radio.value"
			class="sc-multiselect-radio__line"
			@click="selectedRadio = radio.value"
		>
			<ScRadio
				:modelValue="selectedRadio"
				:value="radio.value"
				:disabled="!isChecked"
			>
				{{ radio.text }}
			</ScRadio>
		</ScDropdownLine>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType } from 'vue';
	import { ScText } from '../text';
	import { ScCheckbox } from '../checkbox';
	import { ScDropdownLine } from '../dropdown';
	import { ScRadio } from '../radio';
	import { MultiselectOptionRendering, RadioMultiSelectOptionType } from './option';

	export default defineComponent({
		name: 'ScMultiselectLine',
		emits: [
			'update:modelValue',
		],
		components: {
			ScDropdownLine,
			ScCheckbox,
			ScText,
			ScRadio,
		},
		props: {
			option: {
				type: Object as PropType<MultiselectOptionRendering<RadioMultiSelectOptionType>>,
				required: true,
			},
			activated: {
				type: Boolean,
				required: true,
			},
			modelValue: {
				type: Array as PropType<Array<number | string>>,
				default: () => ([] as Array<number | string>),
			},
		},
		setup(props, { emit }) {
			const selectedRadio = computed<number | string>({
				get() {
					return props.option.value.find((radioOption) => props.modelValue.includes(radioOption.value))?.value;
				},
				set(newValue) {
					emit('update:modelValue', props.modelValue.map((selectedValue) => {
						return selectedValue === selectedRadio.value ? newValue : selectedValue;
					}));
				},
			});

			const isChecked = computed<boolean>({
				get() {
					return selectedRadio.value != null;
				},
				set(newValue) {
					emit('update:modelValue', newValue
						? [...props.modelValue, props.option.value[0].value]
						: props.modelValue.filter((selectedValue) => selectedValue !== selectedRadio.value),
					);
				},
			});

			return {
				selectedRadio,
				isChecked,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-multiselect-radio {
		&__line {
			padding-left: 40px;
		}
	}

</style>