<template>
	<Teleport to="body">
		<TransitionGroup
			name="slide-fade"
			tag="div"
			class="sc-notification-presenter"
		>
			<Component
				v-for="item of visibleNotifications"
				:key="item.id"
				:is="item.component"
				v-bind="item.props"
				@close="item.close()"
			/>
		</TransitionGroup>
	</Teleport>
</template>

<script lang="ts">
	import { computed, defineComponent, watch } from 'vue';
	import useNotification from '../../composables/use-notification';

	/**
	 * The ScNotificationPresenter component used as a background for the callable notification component.
	 * <br/>
	 * Maximum 3 notifications are displayed on the screen at the same time.
	 * <br/>
	 * **Attention! Please place this component in the root component of your application for it to work correctly.**
	 * */
	export default defineComponent({
		name: 'ScNotificationPresenter',
		setup() {
			const notification = useNotification();

			const visibleNotifications = computed(() => notification.state.value.slice(0, 3));

			watch(visibleNotifications, () => {
				visibleNotifications.value.forEach((item) => item.startTimeout());
			});

			return { visibleNotifications };
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";
	@import "../../styles/adaptive";

	.sc-notification-presenter {
		position: fixed;
		z-index: 9999;
		left: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 0 24px 16px;

		&:empty {
			padding: 0;
		}

		.screen-lt-480({
			padding: 0 10px 10px;
		});
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		max-height: 90vh;
		transition: all 200ms ease;
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		max-height: 0;
		opacity: 0;
		transform: translateY(8px);
	}
</style>
