<template>
	<svg
		v-html="allIcons[name]"
		viewBox="0 0 24 24"
		:class="`sc-icon sc-icon_${size}`"
		:style="{ fill: color && `var(--${color})` }"
		@click="$emit('click', $event)"
	/>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ColorNames } from '../../types/colors';
	import { icons, IconNames } from './icons';

	let allIcons = { ...icons }

	/**
	 * Renders the svg icon with specified name, size and color props
	 */
	export default defineComponent({
		name: 'ScIcon',
		props: {
			/** Set name */
			name: {
				type: String as PropType<IconNames>,
				required: true,
				default: null,
			},
			/** Set font size */
			size: {
				type: String as PropType<'10' | '12' | '14' | '16' | '20' | '24' | '32'>,
				default: '24',
				validator: (value: string) => ['10', '12', '14', '16', '20', '24', '32'].includes(value),
			},
			/** Set color */
			color: {
				type: String as PropType<ColorNames>,
				default: null,
			},
		},
		setCustomIconsSet(iconsSet: Record<string, string>) {
			allIcons = {...icons, ...iconsSet };
		},
		setup() {
			return { allIcons };
		},
	});
</script>

<style lang="less" scoped>
	.sc-icon {
		display: inline-block;
		fill: var(--icon-color);
		vertical-align: middle;
		image-rendering: auto;
		shape-rendering: geometricPrecision;

		&_10 {
			height: 10px;
			width: 10px;
		}

		&_12 {
			height: 12px;
			width: 12px;
		}

		&_14 {
			height: 14px;
			width: 14px;
		}

		&_16 {
			height: 16px;
			width: 16px;
		}

		&_20 {
			height: 20px;
			width: 20px;
		}

		&_24 {
			height: 24px;
			width: 24px;
		}

		&_32 {
			height: 32px;
			width: 32px;
		}
	}
</style>
