
<div
	ref="optionsList"
	class="sc-datepicker__calendar"
>
	<div class="sc-datepicker__header">
		<ScButton
			:disabled="isDisabledLeft"
			icon="chevron-left"
			iconSize="10"
			size="28"
			view="flat"
			@click="setPrevMonth"
		/>
		<div class="sc-datepicker__header-select">
			<ScSelect
				:modelValue="month"
				:options="selectOptionsMonth"
				:search="false"
				class="sc-datepicker__options-select"
				size="28"
				view="flat"
				@update:modelValue="setMonth"
			/>
			<ScSelect
				:modelValue="year"
				:options="selectOptionsYears"
				:search="false"
				class="sc-datepicker__options-select"
				size="28"
				view="flat"
				@update:modelValue="setYear"
			/>
		</div>
		<ScButton
			:disabled="isDisabledRight"
			icon="chevron-right"
			iconSize="10"
			size="28"
			view="flat"
			@click="setNextMonth"
		/>
	</div>
	<div class="sc-datepicker__week">
		<ScText
			v-for="day in allDaysOfWeek"
			:key="day"
			class="sc-datepicker__week-day-name"
			weight="semibold"
		>
			{{ day }}
		</ScText>
	</div>
	<div class="sc-datepicker__days">
		<div
			v-for="(cal, i) in calendar"
			:key="i"
			:class="[
				'sc-datepicker__date',
				{'sc-datepicker__date--blur': cal.shadowed},
				{'sc-datepicker__date--disabled': cal.disabledClick},
				{[`active-${cal.active}`]: cal.active},
				{'current': cal.current},
				{'hide': cal.hide},
				{'interval': cal.interval},
				{'other-months': cal.otherMonth}
			]"
			@click="setDay(cal)"
		>
			<ScText>
				{{ cal.date }}
			</ScText>
		</div>
	</div>
</div>
