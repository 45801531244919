<template>
	<button
		:class="`sc-button-circle sc-button-circle_${size} sc-button-circle_${view}`"
		:disabled="disabled"
		type="button"
		@click="$emit('click', $event)"
	>
		<ScIcon
			:name="icon"
			:size="size === '28' ? '16' : '20'"
		/>
	</button>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue';
	import { ScIcon, IconNames } from '../icon';

	/**
	 * Render circle button
	 */
	export default defineComponent({
		name: 'ScButtonCircle',
		components: { ScIcon },
		emits: [
			/** Click event */
			'click',
		],
		props: {
			/** Set the visual design */
			view: {
				type: String as PropType<'light' | 'black'>,
				default: 'black',
				validator: (value: string) => ['light', 'black'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'28' | '36'>,
				default: '28',
				validator: (value: string) => ['28', '36'].includes(value),
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				required: true,
				default: null,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button-circle {
		border-radius: 50%;
		box-sizing: border-box;
		cursor: pointer;
		font-family: Inter, sans-serif;
		font-weight: 600;
		outline: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: background 100ms ease, color 100ms;
		transform: translate3d(0, 0, 0);

		&:focus-visible {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3)
		}

		&:disabled {
			cursor: default;
			pointer-events: none;
			user-select: none;
		}

		&_28 {
			height: 28px;
			width: 28px;
		}

		&_36 {
			height: 36px;
			width: 36px;
		}

		&_light {
			background: @white;
			border: 1px solid @mulberry-purple-20;
			--icon-color: @mulberry-purple-45;

			&:hover {
				background: @mulberry-purple-20;
			}

			&:active {
				background: @mulberry-purple-30;
			}

			&:disabled {
				background-color: transparent;
				border-color: @mulberry-purple-10;
				--icon-color: @mulberry-purple-30;
			}
		}

		&_black {
			background: @mulberry-purple-90;
			border: 1px solid @mulberry-purple-90;
			--icon-color: @mulberry-purple-0;

			&:hover {
				background: @mulberry-purple-70;
			}

			&:active {
				background: @mulberry-purple-100;
			}

			&:disabled {
				background-color: @mulberry-purple-35;
				border-color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-10;
			}
		}
	}
</style>
