<template>
	<svg class="sc-loader-logo">
		<defs>
			<linearGradient id="a">
				<stop
					offset="0"
					stop-color="currentColor"
					stop-opacity="0"
				/>
				<stop
					offset="1"
					stop-color="currentColor"
					stop-opacity="0.5"
				/>
			</linearGradient>
			<linearGradient id="b">
				<stop
					offset="0"
					stop-color="currentColor"
					stop-opacity="1"
				/>
				<stop
					offset="1"
					stop-color="currentColor"
					stop-opacity="0.5"
				/>
			</linearGradient>
		</defs>
		<g
			ref="circle"
			class="sc-loader-logo__circle"
		>
			<path
				fill="url(#a)"
				d="M76 38h-4.5c0-18.5-15.1-33.6-33.6-33.6C19.4 4.5 4.4 19.5 4.4 38H-.1c0-21 17-38 38-38S76 17 76 38z"
			/>
			<path
				fill="url(#b)"
				d="M71.5 38c0 18.5-15.1 33.5-33.6 33.5S4.4 56.5 4.4 38c0-1.2-1-2.3-2.3-2.3S0 36.8 0 38c0 21 17 38 38 38s38-17 38-38h-4.5z"
			/>
		</g>
		<path
			fill="currentColor"
			d="m44 31 6.8-6.9-3.5-3.5-6.8 6.8L44 31zm-6.3 21.3.7-.8.5.5A10.9 10.9 0 0 0 56 49.8l-4.6-2.6c-.6.9-1.2 1.7-2.1 2.1a5.8 5.8 0 0 1-7-.9 6 6 0 0 1 0-8.2 5.8 5.8 0 0 1 7-.9c.9.5 1.7 1.2 2.1 2.1l4.6-2.6c-.9-1.7-2.3-3-3.9-3.9a9.7 9.7 0 0 0-5.5-1.5c-3 0-5.8 1.2-7.7 3.2l-1.1 1.3a13.6 13.6 0 0 0-5.7-3l-1.7-.6h-.1c-1.2-.3-2.5-.6-2.5-2.1 0-.5.2-.9.5-1.4s.9-.6 1.4-.6h7.1v-4.9h-7.1a9 9 0 0 0-5.3 2.1 6.7 6.7 0 0 0-2.1 5c0 1.7.6 3.2 1.5 4.4 1.4 1.8 2.9 2.2 4.8 2.8l2.3.6c2.6.8 4.4 2.1 4.4 5 0 1.2-.6 2.6-1.5 3.5a4.2 4.2 0 0 1-3.5 1.5c-1.2 0-2.6-.6-3.5-1.5a4.2 4.2 0 0 1-1.5-3.5V45H20v.2c0 5.6 4.6 10.2 10.2 10.2h.1c2.9 0 5.4-1.1 7.4-3.1z"
		/>
	</svg>
</template>

<script lang="ts">
	import { defineComponent, onMounted, ref } from 'vue';

	/** The loader for displaying loading state of any page. */
	export default defineComponent({
		name: 'ScLoaderLogo',
		setup() {
			const circle = ref<HTMLElement>();

			onMounted(() => {
				circle.value.style.animationDelay = (-Date.now() % 1300) + 'ms';
			});

			return {
				circle,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-loader-logo {
		display: block;
		width: 76px;
		height: 76px;
		color: @mulberry-purple-90;

		&__circle {
			animation: spin 1300ms linear 0ms infinite running;
			transform-origin: 50% 50%;
		}
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>
