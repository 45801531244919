<template>
	<Transition
		name="slide"
		mode="out-in"
	>
		<slot/>
	</Transition>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({ name: 'ScTransitionSlide' });
</script>

<style lang="less" scoped>
	.slide-enter-active,
	.slide-leave-active {
		transition: max-height 150ms ease-in-out, opacity 150ms ease-in-out;
	}

	.slide-enter-to,
	.slide-leave-from {
		max-height: 100vh;
		opacity: 1;
		overflow: hidden;
	}

	.slide-enter-from,
	.slide-leave-to {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
	}
</style>
