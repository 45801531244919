<template>
	<Transition
		name="fade"
		mode="out-in"
	>
		<slot/>
	</Transition>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({ name: 'ScTransitionFade' });
</script>

<style lang="less" scoped>
	.fade-enter-from {
		opacity: 0;
		transform: translateY(-4px);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 150ms linear, transform 150ms linear;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
