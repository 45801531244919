<template>
	<button
		:class="{
			'sc-button': true,
			[`sc-button_${size}`]: true,
			[`sc-button_${view}`]: true,
			'sc-button_icon': icon && !$slots.default,
			'sc-button_activated': activated,
			'sc-button_highlighted': highlighted,
			'sc-button_warning': warning,
			'sc-button_response': !icon && response,
		}"
		:disabled="disabled || response"
		type="button"
		@click="$emit('click', $event)"
		@mousedown="$emit('mousedown', $event)"
	>
		<template v-if="icon && iconPosition === 'left'">
			<ScLoaderSpin
				v-if="response"
				:size="loaderSize"
				class="sc-button__loader"
			/>
			<div
				v-else
				class="sc-button__icon"
			>
				<ScIcon
					:name="icon"
					:size="iconSize"
				/>
				<span
					v-if="notification && view === 'flat'"
					:class="{
						'sc-button_notification': true,
						'sc-button_notification-small': size === '28',
					}"
				/>
			</div>
		</template>
		<span
			v-if="$slots.default"
			class="sc-button__text"
		>
			<!-- @slot Use for any text -->
			<slot name="default"/>
		</span>
		<ScBadge
			v-if="$slots.badge != null"
			class="sc-button__badge"
		>
			<!-- @slot Use for the badge -->
			<slot name="badge"/>
		</ScBadge>
		<template v-if="!icon || iconPosition === 'right'">
			<ScLoaderSpin
				v-if="response"
				:size="loaderSize"
				class="sc-button__loader"
			/>
			<div
				v-else-if="icon"
				class="sc-button__icon"
			>
				<ScIcon
					:name="icon"
					:size="iconSize"
				/>
				<span
					v-if="notification && view === 'flat'"
					:class="{
						'sc-button_notification': true,
						'sc-button_notification-small': size === '28',
					}"
				/>
			</div>
		</template>
	</button>
</template>

<script lang="ts">
	import { computed, defineComponent, PropType } from 'vue';
	import { ScBadge } from '../badge';
	import { ScIcon, IconNames } from '../icon';
	import { ScLoaderSpin } from '../loader';

	type ButtonViews =
		'cta'
		| 'cta-black'
		| 'simple'
		| 'flat'
		| 'flat-active'
		| 'plate'
		| 'plate-active'
		| 'cta-gradient'
		| 'text'
		| 'transparent'

	/**
	 * Renders html tag button with the specified props options
	 */
	export default defineComponent({
		name: 'ScButton',
		components: { ScBadge, ScIcon, ScLoaderSpin },
		emits: [
			/** Click event */
			'click',
			/** Mousedown event */
			'mousedown',
		],
		props: {
			/** Set the visual design */
			view: {
				type: String as PropType<ButtonViews>,
				default: 'cta',
				validator: (value: string) => ['cta', 'cta-black', 'simple', 'flat', 'flat-active', 'plate', 'plate-active', 'cta-gradient', 'text', 'transparent'].includes(value),
			},
			/** Set size */
			size: {
				type: String as PropType<'20' | '28' | '36' | '44'>,
				default: '28',
				validator: (value: string) => ['20', '28', '36', '44'].includes(value),
			},
			/** Set the icon */
			icon: {
				type: String as PropType<IconNames>,
				default: null,
			},
			/** Set icon position */
			iconPosition: {
				type: String as PropType<'left' | 'right'>,
				default: 'left',
				validator: (value: string) => ['left', 'right'].includes(value),
			},
			/** Set activated state */
			activated: {
				type: Boolean,
				default: false,
			},
			/** Set highlighted state */
			highlighted: {
				type: Boolean,
				default: false,
			},
			/** Set disabled state */
			disabled: {
				type: Boolean,
				default: false,
			},
			/** Set warning state */
			warning: {
				type: Boolean,
				default: false,
			},
			/** Set response state */
			response: {
				type: Boolean,
				default: false,
			},
			/** Set notification state */
			notification: {
				type: Boolean,
				default: false,
			},
		},
		setup(props) {
			const iconSize = computed(() => props.size === '20' || props.size === '28' ? '16' : '20');

			const loaderSize = computed(() => iconSize.value);

			return {
				iconSize,
				loaderSize,
			};
		},
	});
</script>

<style lang="less" scoped>
	@import "../../styles/colors";

	.sc-button {
		width: fit-content;
		margin: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 6px;
		box-sizing: border-box;
		cursor: pointer;
		font-family: Inter, sans-serif;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		outline: none;
		white-space: nowrap;
		transition: background 100ms ease, color 100ms;
		transform: translate3d(0, 0, 0);

		&__icon {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&_notification {
			position: absolute;
			border: 2px solid @white;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: @lightish-red;
			z-index: 3;
			right: -2px;
			top: 0;
			transition: border 100ms ease, background 100ms ease;
			box-sizing: content-box;

			&-small {
				border: 1.5px solid @white;
				right: -1.5px;
				top: 1.5px;
			}
		}

		&_response {
			position: relative;

			.sc-button__text {
				visibility: hidden;
			}

			.sc-button__loader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.sc-button__badge {
				visibility: hidden;
			}
		}

		&_20 {
			height: 20px;
			padding: 0 6px;
			font-weight: 400;

			&.sc-button_icon {
				padding: 0 2px;
			}
		}

		&_28 {
			gap: 6px;
			height: 28px;
			padding: 4px 10px;

			&.sc-button_icon {
				padding: 4px 6px;
			}
		}

		&_36 {
			gap: 8px;
			height: 36px;
			padding: 8px 16px;

			&.sc-button_icon {
				padding: 7px 8px;
			}
		}

		&_44 {
			font-size: 16px;
			line-height: 24px;
			gap: 10px;
			height: 44px;
			padding: 10px 20px;

			&.sc-button_icon {
				padding: 11px 12px;
			}
		}

		&:focus-visible {
			box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(98, 57, 198, 0.3);
		}

		&_highlighted {
			box-shadow: 0 0 0 4px rgba(98, 57, 198, 0.1);
		}

		&:disabled {
			cursor: default;
			pointer-events: none;
		}

		&_cta {
			background: @fuchsia-blue;
			color: @white;
			--icon-color: @mulberry-purple-0;
			--loader-color: @white;

			&:hover,
			&.sc-button_activated {
				background: @fuchsia-blue-40;
			}

			&:active {
				background: @mulberry-purple-50;
			}

			&:disabled {
				background: @fuchsia-blue-30;
				color: @fuchsia-blue-10;
				--icon-color: @fuchsia-blue-10;
			}
		}

		&_cta-black {
			background: @mulberry-purple-90;
			color: @white;
			--loader-color: @white;
			--icon-color: @mulberry-purple-0;

			&:hover,
			&.sc-button_activated {
				background: @mulberry-purple-70;
			}

			&:active {
				background: @mulberry-purple-100;
			}

			&:disabled {
				background: @mulberry-purple-35;
				color: @mulberry-purple-10;
				--icon-color: @mulberry-purple-10;
			}
		}

		&_cta-gradient {
			background: @fuchsia-to-pink-60-horizontal;
			color: @white;
			--icon-color: @fuchsia-blue-0;
			--loader-color: @white;

			&:hover,
			&.sc-button_activated {
				background: @fuchsia-60-to-pink-60-horizontal;
			}

			&:active {
				background: @fuchsia-60-to-pink-70-horizontal;
			}

			&:disabled {
				background: @fuchsia-30-to-pink-30-horizontal;
				color: @pink-insanity-0;
				--icon-color: @fuchsia-blue-10;
			}
		}

		&_simple {
			background: transparent;
			border: 1px solid @mulberry-purple-20;
			color: @mulberry-purple;
			--icon-color: @mulberry-purple-45;
			--loader-color: @mulberry-purple;

			&:hover,
			&.sc-button_activated {
				background: @mulberry-purple-20;
				border-color: @mulberry-purple-30;
			}

			&:active {
				background: @mulberry-purple-30;
				border-color: @mulberry-purple-30;
			}

			&.sc-button_warning {
				border-color: @lightish-red-10;
				color: @lightish-red;
				--icon-color: @lightish-red-40;

				&:hover {
					background: @lightish-red-0;
				}

				&:active {
					background: @lightish-red-10;
					color: @lightish-red-70;
					--icon-color: @lightish-red-60;
				}
			}

			&:disabled {
				border-color: @mulberry-purple-10;
				color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-30;
			}
		}

		&_flat {
			background: transparent;
			color: @mulberry-purple-45;
			--icon-color: @mulberry-purple-40;
			--loader-color: @mulberry-purple-45;

			&:hover,
			&.sc-button_activated {
				background: @mulberry-purple-20;

				.sc-button__icon .sc-button_notification {
					border-color: @mulberry-purple-20;
				}
			}

			&:active {
				background: @mulberry-purple-30;

				.sc-button__icon .sc-button_notification {
					border-color: @mulberry-purple-30;
				}
			}

			&.sc-button_icon {
				--icon-color: @mulberry-purple-45;
			}

			&.sc-button_warning {
				&:hover {
					background: @lightish-red-10;
					color: @lightish-red-70;
					--icon-color: @lightish-red-60;

					.sc-button__icon .sc-button_notification {
						border-color: @lightish-red-10;
						background: @lightish-red-80;
					}
				}

				&:active {
					background: @lightish-red-20;
					color: @lightish-red-80;
					--icon-color: @lightish-red-70;

					.sc-button__icon .sc-button_notification {
						border-color: @lightish-red-20;
					}
				}
			}

			&:disabled {
				color: @mulberry-purple-35;
				--icon-color: @mulberry-purple-30;
			}
		}

		&_flat-active {
			background: transparent;
			color: @fuchsia-blue-60;
			--icon-color: @fuchsia-blue;
			--loader-color: @fuchsia-blue-60;

			.sc-button__badge {
				background: @fuchsia-blue-10;
				color: @fuchsia-blue-80;
			}

			&:hover,
			&.sc-button_activated {
				background: @fuchsia-blue-10;
				color: @fuchsia-blue-70;
				--icon-color: @fuchsia-blue-60;

				.sc-button__badge {
					background: @fuchsia-blue-20;
				}
			}

			&:active {
				background: @fuchsia-blue-20;
				color: @fuchsia-blue-70;
				--icon-color: @fuchsia-blue-60;

				.sc-button__badge {
					background: @fuchsia-blue-30;
				}
			}

			&.sc-button_warning {
				&:hover {
					background: @lightish-red-10;
					color: @lightish-red-70;
					--icon-color: @lightish-red-60;

					.sc-button__badge {
						background: @lightish-red-20;
						color: @lightish-red-80;
					}
				}

				&:active {
					background: @lightish-red-20;
					color: @lightish-red-80;
					--icon-color: @lightish-red-70;

					.sc-button__badge {
						background: @lightish-red-30;
						color: @lightish-red-80;
					}
				}
			}

			&:disabled {
				color: @fuchsia-blue-20;
				--icon-color: @fuchsia-blue-10;

				.sc-button__badge {
					background: @fuchsia-blue-10;
					color: @fuchsia-blue-40;
				}
			}
		}

		&_plate {
			background: @mulberry-purple-20;
			color: @mulberry-purple-45;
			--icon-color: @mulberry-purple-40;
			--loader-color: @mulberry-purple-45;

			&:hover,
			&.sc-button_activated {
				background: @mulberry-purple-30;
			}

			&:active {
				background: @mulberry-purple-35;
			}

			&.sc-button_icon {
				--icon-color: @mulberry-purple-45;
			}

			&.sc-button_warning {
				&:hover {
					background: @lightish-red-10;
					color: @lightish-red-70;
					--icon-color: @lightish-red-60;
				}

				&:active {
					background: @lightish-red-20;
					color: @lightish-red-80;
					--icon-color: @lightish-red-70;
				}
			}

			&:disabled {
				background: @mulberry-purple-10;
				color: @mulberry-purple-40;
				--icon-color: @mulberry-purple-35;
			}
		}

		&_plate-active {
			background: @fuchsia-blue-10;
			color: @fuchsia-blue-70;
			--icon-color: @fuchsia-blue-60;
			--loader-color: @fuchsia-blue-70;

			.sc-button__badge {
				background: @fuchsia-blue-20;
				color: @fuchsia-blue-80;
			}

			&:hover,
			&.sc-button_activated {
				background: @fuchsia-blue-20;
				color: @fuchsia-blue-80;
				--icon-color: @fuchsia-blue-70;

				.sc-button__badge {
					background: @fuchsia-blue-30;
				}
			}

			&:active {
				background: @fuchsia-blue-30;

				.sc-button__badge {
					background: @fuchsia-blue-40;
				}
			}

			&.sc-button_warning {
				&:hover {
					background: @lightish-red-10;
					color: @lightish-red-70;
					--icon-color: @lightish-red-60;

					.sc-button__badge {
						background: @lightish-red-20;
						color: @lightish-red-80;
					}
				}

				&:active {
					background: @lightish-red-20;
					color: @lightish-red-80;
					--icon-color: @lightish-red-70;

					.sc-button__badge {
						background: @lightish-red-30;
					}
				}
			}

			&:disabled {
				background: @fuchsia-blue-10;
				color: @fuchsia-blue-40;
				--icon-color: @fuchsia-blue-0;

				.sc-button__badge {
					background: @fuchsia-blue-20;
					color: @fuchsia-blue;
				}
			}
		}

		&_text {
			background: transparent;
			padding-left: 0;
			padding-right: 0;
			color: @mulberry-purple-45;

			&:hover {
				color: @mulberry-purple;
			}

			&:active {
				color: @mulberry-purple-90;
			}

			&:disabled {
				color: @mulberry-purple-35;
			}
		}

		&.sc-button_transparent {
			background: transparent;

			&.sc-button_icon {
				--icon-color: @mulberry-purple-40;

				&:hover {
					--icon-color: @mulberry-purple-new;
				}

				&:active {
					--icon-color: @mulberry-purple;
				}

				&:disabled {
					--icon-color: @mulberry-purple-30;
				}
			}
		}
	}
</style>
