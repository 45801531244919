
<div
	:class="{
		'sc-dropdown-group': true,
		'sc-dropdown-group_bordered': !title,
	}"
	:data-group-title="title"
>
	<ScText
		v-if="title"
		class="sc-dropdown-group__title"
		color="mulberry-purple-40"
		weight="semibold"
		size="12"
	>
		{{ title }}
	</ScText>
	<!-- @slot Use for content in dropdown -->
	<slot/>
</div>
